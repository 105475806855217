import { Reducer } from 'redux';
import {
  defaultOfflinePendingState,
  OfflineCheckpointValue,
  OfflineGridState,
  OfflinePendingCheckPoint,
  OfflinePendingExistingNonCompliance,
  OfflinePendingGrid,
  OfflinePendingGridCheckDataDocument,
  OfflinePendingGridCheckDocument,
  OfflinePendingGridCheckFileDocument,
  OfflinePendingGridConclusion,
  OfflinePendingInspection,
  OfflinePendingNewNonCompliance,
  OfflinePendingNonCompliance,
  OfflinePendingParcel,
  OfflinePendingParcelDataLineState,
  OfflinePendingParcels,
  OfflinePendingRiskAnalysis,
  OfflinePendingSection,
  OfflinePendingSectionMergedType,
  OfflinePendingSectionRow,
  OfflinePendingSimpleState,
  OfflinePendingState,
  OfflinePendingUnmatchedChecks,
  OfflineUnmatchedChecksState,
  PendingInspectionMap,
} from '../model';
import { persistReducer } from 'redux-persist';
import { v4 as uuid } from 'uuid';
import { OfflinePendingAction, OfflinePendingActionType } from '../actions/pending';

import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as R from 'fp-ts/Record';
import * as S from 'fp-ts/string';
import { identity, pipe, tuple } from 'fp-ts/function';
import {
  AppendixAttachedFile,
  Signature,
  SyncIncomingFulfillmentType,
  SyncIncomingGridCheckDataDocument,
  SyncIncomingGridCheckDocument,
  SyncIncomingGridCheckFileDocument,
  SyncIncomingInspection,
  SyncIncomingNonCompliance,
  SyncIncomingParcel,
  SyncIncomingUnFulfilledGrid,
  SyncIncomingUnmatchedChecks,
} from '../../../sync/model';
import { Profile } from '../../../auth/model';
import { InspectionProduction, ScheduleParams } from '../../../online/inspections/model';
import { sequenceT } from 'fp-ts/Apply';
import { getAvailableCheckScopes, mapSections, updateSectionsValuesFromCheckScopes } from '../utils/checkpoints';
import { PersistState, Storage } from 'redux-persist/es/types';
import { mapMetaSections } from '../utils/metas';
import * as Optics from 'optics-ts';
import {
  getAttachedFilePrism,
  getAttachedFilesPrism,
  getCheckDataDocumentPrism,
  getCheckFileDocumentPrism,
  getCoverPagePrism,
  getExistingNonCompliancePrism,
  getGridConclusionPrism,
  getGridPrism,
  getGridsPrism,
  getInspectionPrism,
  getNewNonCompliancePrism,
  getNonCompliancesPrism,
  getParcelPrism,
  getParcelRowsPrism,
  getParcelsPrism,
  getRiskAnalysisCriterionPrism,
  getRiskAnalysisPrism,
  getRowParcelPrism,
  getStateRowParcelPrism,
  getUnmatchedCheckPrim,
} from '../optics/pending';
import { isSyncIncomingGridCheckFileDocument, isUnfulfilledGrid } from '../utils/grids';
import { parcelTotal, parcelTotalByClass } from '../utils/parcels';
import { logSentryMessage } from '@shared/utils/sentry';
import { RiskAnalysisGridTemplate } from '../../risk-analysis/model';
import { CoverPage } from '../../cover-page/model';

function getConclusion(profile: Profile | null, schedule: ScheduleParams | null): OfflinePendingGridConclusion {
  const auditorSignature = pipe(
    O.fromNullable(profile),
    O.fold<Profile, Signature>(
      () => ({
        firstName: null,
        lastName: null,
        fileId: null,
      }),
      p => ({
        firstName: p.firstName,
        lastName: p.lastName,
        fileId: null,
      }),
    ),
  );

  const startTime = pipe(
    O.fromNullable(schedule),
    O.chain(schedule => sequenceT(O.Apply)(O.fromNullable(schedule.date), O.fromNullable(schedule.startTime))),
    O.map(([startDate, startHour]) => `${startDate} ${startHour}`),
    O.toNullable,
  );

  return {
    startTime,
    endTime: null,
    comment: null,
    metPeople: null,
    accompanyingPeople: null,
    auditorSignature,
    operatorSignature: {
      firstName: null,
      lastName: null,
      fileId: null,
    },
    operatorEmail: null,
    cci: null,
  };
}

function mapSyncIncomingNonCompliances(
  nonCompliances: Array<SyncIncomingNonCompliance>,
): Array<OfflinePendingNonCompliance> {
  return nonCompliances.map<OfflinePendingNonCompliance>(nonCompliance => ({
    type: 'existing',
    id: nonCompliance.id,
    code: nonCompliance.code,
    checkIds: nonCompliance.checkIds ?? [],
    action: null,
    complianceType: nonCompliance.type,
    note: null,
    nonCompliance: nonCompliance.nonCompliance,
    specification: nonCompliance.specification,
    operatorType: nonCompliance.operatorType,
    gravity: nonCompliance.gravity,
    firstReportDate: nonCompliance.firstReportDate,
    lastReportDate: nonCompliance.lastReportDate,
    implementationDate: nonCompliance.implementationDate,
    description: nonCompliance.description,
    impact: nonCompliance.impact,
    correctiveAction: nonCompliance.correctiveAction,
    treatment: nonCompliance.treatment,
    comment: nonCompliance.comment,
  }));
}

function mapSyncIncomingParcel(
  production: InspectionProduction,
  parcels: Array<SyncIncomingParcel>,
): OfflinePendingParcels {
  return {
    state: OfflinePendingSimpleState.NotStarted,
    production: production,
    parcels: parcels.map<OfflinePendingParcel>(parcel => ({
      id: uuid(),
      template: parcel.template,
      lines: parcel.lines.map(line => ({
        id: line.id,
        state: null,
        rows: line.rows,
      })),
      before: parcelTotalByClass(parcel.lines, parcel.template),
      beforeTot: parcelTotal(parcel.lines, parcel.template),
      isAnswered: null,
    })),
  };
}

function mapSyncIncomingRiskAnalysis(
  riskAnalysisGrids: Array<RiskAnalysisGridTemplate>,
): OfflinePendingRiskAnalysis | null {
  return pipe(
    NEA.fromArray(riskAnalysisGrids),
    O.map(grids => ({
      state: OfflinePendingSimpleState.NotStarted,
      riskAnalysisGrids: pipe(
        grids,
        NEA.map(({ code }) => tuple(code, [])),
        R.fromEntries,
      ),
    })),
    O.toNullable,
  );
}

function mapSyncIncomingCoverPage(code: CoverPage.Code | null): CoverPage.Pending | null {
  return pipe(
    O.fromNullable(code),
    O.chain(code => {
      switch (code) {
        case CoverPage.Code.Bio:
          return O.some({
            code,
            state: OfflinePendingSimpleState.NotStarted,
            controlType: null,
            controlLocation: null,
            website: null,
            pacageNumber: '',
            livestockNumber: null,
            sanitaryAccreditationNumber: null,
            CVINumber: null,
            controlPerimeter: null,
            operatorActivity: {
              plantProduction: {
                type: null,
                list: [],
              },
              animalProduction: {
                type: null,
                list: [],
              },
              preparation: {
                type: null,
                list: [],
              },
              distribution: {
                type: null,
                list: [],
              },
              retail: {
                type: null,
                list: [],
              },
              outsideEUImportation: {
                type: null,
                list: [],
              },
              outsideEUExportation: {
                type: null,
                list: [],
              },
            },
            uncheckedBIOOrMixedActivityJustification: null,
            subcontracting: [],
          });
        default:
          return O.none;
      }
    }),
    O.toNullable,
  );
}

function mapSyncIncomingGridCheckFileDocument(
  document: SyncIncomingGridCheckFileDocument,
): OfflinePendingGridCheckFileDocument {
  return {
    type: 'file',
    id: document.id,
    label: document.label,
    fileUrl: document.fileUrl,
    fileName: document.fileName,
    fileContentType: document.fileContentType,
    comment: null,
    fileOrReason: null,
  };
}

function mapSyncIncomingGridCheckDataDocument(
  document: SyncIncomingGridCheckDataDocument,
): OfflinePendingGridCheckDataDocument {
  return {
    type: 'data',
    id: document.id,
    settings: document.settings,
    label: document.label,
    comment: null,
    completedOrReason: null,
  };
}

function mapSyncIncomingDocument(document: SyncIncomingGridCheckDocument): OfflinePendingGridCheckDocument {
  if (isSyncIncomingGridCheckFileDocument(document)) {
    return mapSyncIncomingGridCheckFileDocument(document);
  } else {
    return mapSyncIncomingGridCheckDataDocument(document);
  }
}

function mapSyncIncomingDocuments(
  documents: Array<SyncIncomingGridCheckDocument>,
): Array<OfflinePendingGridCheckDocument> {
  return pipe(documents, A.map(mapSyncIncomingDocument));
}

function mapSyncIncomingGrid(
  grid: SyncIncomingUnFulfilledGrid,
  inspection: SyncIncomingInspection,
  profile: Profile | null,
): OfflinePendingGrid {
  const checkIds = pipe(
    inspection.checks,
    A.filter(check => check.gridId === grid.id),
    A.map(check => check.id),
  );

  const sections = mapSections(grid.checkpoints, grid.sections, grid.mergers, grid.aggregates);

  const metaSections = mapMetaSections(grid.metaSections, grid.metas);

  const availableCheckScope = getAvailableCheckScopes(sections).map(checkScope => checkScope.id);

  return {
    id: grid.id,
    state: OfflineGridState.NotStarted,
    checkIds,
    name: grid.name,
    title: grid.title,
    reference: grid.reference,
    checkScopes: availableCheckScope.length === 1 ? availableCheckScope : [],
    checkScopesComment: null,
    sections: sections,
    metaSections,
    attachedFiles: [],
    conclusion: getConclusion(profile, inspection.schedule),
    documents: mapSyncIncomingDocuments(grid.documents),
    gridType: grid.gridType,
    largeLabels: grid.template.largeLabels,
  };
}

function mapSyncIncomingInspectionToOfflinePendingInspection(
  inspection: SyncIncomingInspection,
  profile: Profile | null,
): OfflinePendingInspection {
  const unmatchedChecks = pipe(
    O.fromNullable(inspection.unmatchedChecks),
    O.filter(inspection => inspection.type === SyncIncomingFulfillmentType.Unfulfilled),
    O.map<SyncIncomingUnmatchedChecks, OfflinePendingUnmatchedChecks>(unmatchedChecks => ({
      state: OfflineGridState.NotStarted,
      checkIds: unmatchedChecks.checkIds,
    })),
    O.toNullable,
  );

  const coverPage = pipe(
    inspection.grids,
    A.filter(isUnfulfilledGrid),
    A.findFirstMap(({ template }) => O.fromNullable(template.coverPage)),
    O.toNullable,
  );

  return {
    id: inspection.id,
    operatorEmail: inspection.operator?.email ?? null,
    grids: pipe(
      inspection.grids,
      A.filter(isUnfulfilledGrid),
      A.map(grid => mapSyncIncomingGrid(grid, inspection, profile)),
    ),
    unmatchedChecks,
    nonCompliances: mapSyncIncomingNonCompliances(inspection.nonCompliances),
    parcels: mapSyncIncomingParcel(inspection.production, inspection.parcels),
    isUnannounced: inspection.isUnannounced,
    riskAnalysis: mapSyncIncomingRiskAnalysis(inspection.riskAnalysisGrids),
    coverPage: mapSyncIncomingCoverPage(coverPage),
    cci: null,
  };
}

function initPendingState(inspections: Array<SyncIncomingInspection>, profile: Profile | null): OfflinePendingState {
  return {
    inspections: inspections.reduce<PendingInspectionMap>((inspections, inspection) => {
      return {
        ...inspections,
        [inspection.id]: mapSyncIncomingInspectionToOfflinePendingInspection(inspection, profile),
      };
    }, {}),
  };
}

function updatePendingInspection(
  inspections: PendingInspectionMap,
  id: string,
  updater: (inspection: OfflinePendingInspection) => OfflinePendingInspection,
): PendingInspectionMap {
  return pipe(
    O.fromNullable(inspections[id]),
    O.map(updater),
    O.map(updated => ({ ...inspections, [id]: updated })),
    O.getOrElse(() => inspections),
  );
}

function updatePendingGrid(
  inspections: PendingInspectionMap,
  inspectionId: string,
  gridId: string,
  updater: (grid: OfflinePendingGrid) => OfflinePendingGrid,
  newState?: OfflineGridState,
): PendingInspectionMap {
  return updatePendingInspection(inspections, inspectionId, inspection => {
    const gridToUpdateIndex = pipe(
      inspection.grids,
      A.findIndex(grid => grid.id === gridId),
    );

    return pipe(
      gridToUpdateIndex,
      O.chain(index =>
        pipe(
          inspection.grids,
          A.modifyAt(index, grid => {
            if (newState && grid.state === OfflineGridState.Finished)
              logSentryMessage(`A grid state changed from ${grid.state} to ${newState}`, 'warning');
            return { ...updater(grid), state: newState ?? grid.state };
          }),
        ),
      ),
      O.map(grids => ({ ...inspection, grids })),
      O.getOrElse(() => inspection),
    );
  });
}

function updateGridState(
  gridState: OfflineGridState,
  inspectionId: string,
  gridId: string,
): (state: OfflinePendingState) => OfflinePendingState {
  return state =>
    pipe(
      state,
      Optics.modify(getGridPrism(inspectionId, gridId).prop('state'))(state => {
        if (state === OfflineGridState.Finished) {
          logSentryMessage(`A grid state changed from ${state} to ${gridState}`, 'warning');
        }

        return gridState;
      }),
    );
}

function updateGridsState(
  gridState: OfflineGridState,
  inspectionId: string,
  gridsId: Array<string>,
): (state: OfflinePendingState) => OfflinePendingState {
  return state =>
    pipe(
      gridsId,
      A.reduce(state, (state, gridId) => updateGridState(gridState, inspectionId, gridId)(state)),
    );
}

function updateSectionRow(
  inspections: PendingInspectionMap,
  inspectionId: string,
  gridId: string,
  rowId: string,
  updater: (row: OfflinePendingSectionRow) => OfflinePendingSectionRow,
): PendingInspectionMap {
  return updatePendingGrid(
    inspections,
    inspectionId,
    gridId,
    grid => ({
      ...grid,
      sections: grid.sections.map(section => ({
        ...section,
        rows: section.rows.map(row => (row.id === rowId ? updater(row) : row)),
      })),
    }),
    OfflineGridState.Pending,
  );
}

function updateSectionCheckpoints(
  inspections: PendingInspectionMap,
  inspectionId: string,
  gridId: string,
  rowId: string,
  updater: (checkpoint: OfflinePendingCheckPoint) => OfflinePendingCheckPoint,
): PendingInspectionMap {
  return updateSectionRow(inspections, inspectionId, gridId, rowId, row => {
    if (row.type === 'SIMPLE') {
      return {
        ...row,
        checkpoint: updater(row.checkpoint),
      };
    } else if (row.type === 'AGGREGATED') {
      return {
        ...row,
        checkpoints: pipe(
          row.checkpoints,
          A.map(checkpoint =>
            checkpoint.value !== OfflineCheckpointValue.Filtered ? updater(checkpoint) : checkpoint,
          ),
        ),
      };
    } else if (row.items.type === OfflinePendingSectionMergedType.Checkpoint) {
      return {
        ...row,
        items: {
          ...row.items,
          checkpoints: pipe(
            row.items.checkpoints,
            A.map(checkpoint =>
              checkpoint.value !== OfflineCheckpointValue.Filtered ? updater(checkpoint) : checkpoint,
            ),
          ),
        },
      };
    } else if (row.items.type === OfflinePendingSectionMergedType.Aggregate) {
      return {
        ...row,
        items: {
          ...row.items,
          aggregates: pipe(
            row.items.aggregates,
            A.map(aggregate => ({
              ...aggregate,
              checkpoints: pipe(
                aggregate.checkpoints,
                A.map(checkpoint =>
                  checkpoint.value !== OfflineCheckpointValue.Filtered ? updater(checkpoint) : checkpoint,
                ),
              ),
            })),
          ),
        },
      };
    } else return row;
  });
}

function setSectionInapplicable(
  inspections: PendingInspectionMap,
  inspectionId: string,
  gridId: string,
  sectionId: string,
  comment: string,
) {
  const updateRow = (row: OfflinePendingSectionRow): OfflinePendingSectionRow => {
    if (row.type === 'SIMPLE' && row.checkpoint.value !== OfflineCheckpointValue.Filtered) {
      return {
        ...row,
        checkpoint: {
          ...row.checkpoint,
          value: OfflineCheckpointValue.Inapplicable,
          comment,
        },
      };
    } else if (row.type === 'AGGREGATED') {
      return {
        ...row,
        checkpoints: row.checkpoints.map(checkpoint =>
          checkpoint.value !== OfflineCheckpointValue.Filtered
            ? {
                ...checkpoint,
                value: OfflineCheckpointValue.Inapplicable,
                comment,
              }
            : checkpoint,
        ),
      };
    } else if (row.type === 'MERGED') {
      if (row.items.type === OfflinePendingSectionMergedType.Checkpoint) {
        return {
          ...row,
          items: {
            ...row.items,
            checkpoints: row.items.checkpoints.map(checkpoint =>
              checkpoint.value !== OfflineCheckpointValue.Filtered
                ? {
                    ...checkpoint,
                    value: OfflineCheckpointValue.Inapplicable,
                    comment,
                  }
                : checkpoint,
            ),
          },
        };
      } else if (row.items.type === OfflinePendingSectionMergedType.Aggregate) {
        return {
          ...row,
          items: {
            ...row.items,
            aggregates: row.items.aggregates.map(ag => ({
              id: ag.id,
              type: 'AGGREGATED',
              checkpoints: ag.checkpoints.map(checkpoint =>
                checkpoint.value !== OfflineCheckpointValue.Filtered
                  ? {
                      ...checkpoint,
                      value: OfflineCheckpointValue.Inapplicable,
                      comment,
                    }
                  : checkpoint,
              ),
            })),
          },
        };
      } else {
        return row;
      }
    } else {
      return row;
    }
  };
  const updateSection = (section: OfflinePendingSection): OfflinePendingSection => ({
    ...section,
    rows: section.rows.map(updateRow),
  });

  return updatePendingGrid(
    inspections,
    inspectionId,
    gridId,
    grid => ({
      ...grid,
      sections: grid.sections.map(section => (section.id === sectionId ? updateSection(section) : section)),
    }),
    OfflineGridState.Pending,
  );
}

function reducer(state = defaultOfflinePendingState, action: OfflinePendingAction): OfflinePendingState {
  switch (action.type) {
    case OfflinePendingActionType.INIT_STATE:
      return initPendingState(action.payload.inspections, action.payload.profile);

    case OfflinePendingActionType.SUBMIT_OPERATOR_ATTENTIONS:
      return pipe(
        state,
        Optics.modify(getInspectionPrism(action.payload.inspectionId))(inspection => ({
          ...inspection,
          operatorEmail: action.payload.email,
          isUnannounced: action.payload.isUnannounced,
        })),
      );

    case OfflinePendingActionType.UPDATE_GRID_STATE:
      return pipe(state, updateGridState(action.payload.state, action.payload.inspectionId, action.payload.gridId));

    case OfflinePendingActionType.UPDATE_GRIDS_STATE: {
      const updateInspection = (inspection: OfflinePendingInspection): OfflinePendingInspection => ({
        ...inspection,
        operatorEmail: action.payload.operatorEmail,
        cci: action.payload.cci,
      });

      return pipe(
        state,
        updateGridsState(action.payload.state, action.payload.inspectionId, action.payload.gridsId),
        Optics.modify(getInspectionPrism(action.payload.inspectionId))(updateInspection),
      );
    }

    case OfflinePendingActionType.FINISH_UNMATCHED_CHECKS:
      return pipe(
        state,
        Optics.set(getUnmatchedCheckPrim(action.payload.inspectionId).prop('state'))(
          OfflineGridState.Finished as OfflineUnmatchedChecksState,
        ),
      );

    case OfflinePendingActionType.SELECT_CHECK_SCOPES:
      return pipe(
        state,
        Optics.modify(getGridPrism(action.payload.inspectionId, action.payload.gridId))(grid => ({
          ...grid,
          checkScopes: action.payload.checkScopes,
          checkScopesComment: action.payload.comment,
          sections: updateSectionsValuesFromCheckScopes(
            grid.sections,
            action.payload.checkScopes,
            action.payload.comment,
          ),
        })),
        updateGridState(OfflineGridState.Pending, action.payload.inspectionId, action.payload.gridId),
      );

    case OfflinePendingActionType.UPDATE_META:
      const metaPrism = getGridPrism(action.payload.inspectionId, action.payload.gridId)
        .prop('metaSections')
        .find(section => section.id === action.payload.sectionId)
        .prop('metas')
        .find(meta => meta.id === action.payload.meta.id);

      return pipe(
        state,
        // @ts-ignore
        Optics.set(metaPrism)(action.payload.meta),
        updateGridState(OfflineGridState.Pending, action.payload.inspectionId, action.payload.gridId),
      );

    case OfflinePendingActionType.CHECKPOINTS_SET_INAPPLICABLE_SECTION:
      return {
        inspections: setSectionInapplicable(
          state.inspections,
          action.payload.inspectionId,
          action.payload.gridId,
          action.payload.sectionId,
          action.payload.comment,
        ),
      };

    case OfflinePendingActionType.CHECKPOINTS_UPDATE_ROW_VALUE:
      return {
        inspections: updateSectionCheckpoints(
          state.inspections,
          action.payload.inspectionId,
          action.payload.gridId,
          action.payload.rowId,
          checkpoint => ({
            ...checkpoint,
            value: action.payload.value,
          }),
        ),
      };

    case OfflinePendingActionType.CHECKPOINTS_UPDATE_MERGED_ROW_VALUE:
      return {
        inspections: updateSectionRow(
          state.inspections,
          action.payload.inspectionId,
          action.payload.gridId,
          action.payload.rowId,
          row => {
            if (row.type === 'MERGED') {
              if (row.items.type === OfflinePendingSectionMergedType.Checkpoint) {
                return {
                  ...row,
                  items: {
                    ...row.items,
                    checkpoints: row.items.checkpoints.map(checkpoint =>
                      checkpoint.id === action.payload.checkpointId
                        ? { ...checkpoint, value: action.payload.value }
                        : checkpoint,
                    ),
                  },
                };
              } else {
                return {
                  ...row,
                  items: {
                    ...row.items,
                    aggregates: row.items.aggregates.map(aggregate => ({
                      id: aggregate.id,
                      type: 'AGGREGATED',
                      checkpoints: aggregate.checkpoints.map(checkpoint =>
                        checkpoint.id === action.payload.checkpointId
                          ? { ...checkpoint, value: action.payload.value }
                          : checkpoint,
                      ),
                    })),
                  },
                };
              }
            }

            return row;
          },
        ),
      };

    case OfflinePendingActionType.CHECKPOINTS_UPDATE_ROW_COMMENT:
      return {
        inspections: updateSectionCheckpoints(
          state.inspections,
          action.payload.inspectionId,
          action.payload.gridId,
          action.payload.rowId,
          checkpoint => ({
            ...checkpoint,
            comment: action.payload.comment,
          }),
        ),
      };

    case OfflinePendingActionType.UPLOAD_ATTACHED_FILES:
      const newAttachedFiles: Array<AppendixAttachedFile> = pipe(
        action.payload.files,
        A.map(file => ({
          type: 'appendix',
          fileId: file.id,
          name: file.content.name,
          comment: null,
        })),
      );

      return pipe(
        state,
        Optics.modify(getAttachedFilesPrism(action.payload.inspectionId, action.payload.gridId))(attachedFiles => [
          ...attachedFiles,
          ...newAttachedFiles,
        ]),
      );

    case OfflinePendingActionType.UPDATE_ATTACHED_FILE:
      return pipe(
        state,
        Optics.modify(getAttachedFilePrism(action.payload.inspectionId, action.payload.gridId, action.payload.fileId))(
          attachedFile => ({
            ...attachedFile,
            name: action.payload.name,
            comment: action.payload.comment,
          }),
        ),
      );

    case OfflinePendingActionType.DELETE_ATTACHED_FILE:
      return pipe(
        state,
        Optics.modify(getAttachedFilesPrism(action.payload.inspectionId, action.payload.gridId))(attachedFiles =>
          attachedFiles.filter(file => file.fileId !== action.payload.fileId),
        ),
      );

    case OfflinePendingActionType.UPDATE_EXISTING_NON_COMPLIANCE: {
      const update = (nc: OfflinePendingExistingNonCompliance): OfflinePendingExistingNonCompliance => ({
        ...nc,
        action: action.payload.params.action,
        note: action.payload.params.note,
      });

      return pipe(
        state,
        Optics.modify(getExistingNonCompliancePrism(action.payload.inspectionId, action.payload.id))(update),
        updateGridState(OfflineGridState.Pending, action.payload.inspectionId, action.payload.gridId),
      );
    }

    case OfflinePendingActionType.CREATE_NEW_NON_COMPLIANCE:
      const referential: Partial<OfflinePendingNewNonCompliance> | null = pipe(
        O.fromNullable(action.payload.referential),
        O.map(({ id, gravity, reference, description }) => ({
          codeId: id,
          requirement: reference,
          gravity,
          description,
        })),
        O.toNullable,
      );

      const newNonCompliance: OfflinePendingNewNonCompliance = {
        type: 'new',
        id: action.payload.id,
        gridId: action.payload.gridId,
        checkIds: [],
        codeId: null,
        recordType: action.payload.defaultRecordType,
        implementationDate: null,
        gravity: null,
        description: null,
        impact: null,
        correctiveAction: null,
        comment: null,
        requirement: null,
        ...referential,
      };

      return pipe(
        state,
        Optics.set(getNonCompliancesPrism(action.payload.inspectionId).appendTo())(newNonCompliance),
        updateGridState(OfflineGridState.Pending, action.payload.inspectionId, action.payload.gridId),
      );

    case OfflinePendingActionType.UPDATE_NEW_NON_COMPLIANCE:
      return pipe(
        state,
        Optics.set(getNewNonCompliancePrism(action.payload.inspectionId, action.payload.nonCompliance.id))(
          action.payload.nonCompliance,
        ),
        action.payload.updateState
          ? updateGridState(OfflineGridState.Pending, action.payload.inspectionId, action.payload.gridId)
          : identity,
      );

    case OfflinePendingActionType.DELETE_NEW_NON_COMPLIANCE:
      return pipe(
        state,
        Optics.modify(getNonCompliancesPrism(action.payload.inspectionId))(nonCompliances =>
          nonCompliances.filter(nc => nc.type !== 'new' || nc.id !== action.payload.id),
        ),
        updateGridState(OfflineGridState.Pending, action.payload.inspectionId, action.payload.gridId),
      );

    case OfflinePendingActionType.UPDATE_GRID_CONCLUSION:
      return pipe(
        state,
        Optics.modify(getGridConclusionPrism(action.payload.inspectionId, action.payload.gridId))(conclusion => ({
          ...conclusion,
          ...action.payload.conclusion,
        })),
        updateGridState(OfflineGridState.Pending, action.payload.inspectionId, action.payload.gridId),
      );

    case OfflinePendingActionType.UPDATE_SIGNATURE_FILES: {
      return pipe(
        state,
        Optics.modify(getGridsPrism(action.payload.inspectionId))(
          A.map(grid =>
            A.elem(S.Eq)(grid.id)(action.payload.gridsId)
              ? {
                  ...grid,
                  conclusion:
                    action.payload.type === 'operator'
                      ? {
                          ...grid.conclusion,
                          operatorSignature: { ...grid.conclusion.operatorSignature, fileId: action.payload.fileId },
                        }
                      : {
                          ...grid.conclusion,
                          auditorSignature: { ...grid.conclusion.auditorSignature, fileId: action.payload.fileId },
                        },
                }
              : grid,
          ),
        ),
      );
    }

    case OfflinePendingActionType.UPDATE_OPERATOR_SIGNATURES_NAME:
      return pipe(
        state,
        Optics.modify(getGridsPrism(action.payload.inspectionId))(
          A.map(grid =>
            A.elem(S.Eq)(grid.id)(action.payload.gridsId)
              ? {
                  ...grid,
                  conclusion: {
                    ...grid.conclusion,
                    operatorSignature: { ...grid.conclusion.operatorSignature, ...action.payload.params },
                  },
                }
              : grid,
          ),
        ),
      );

    case OfflinePendingActionType.UPDATE_CONCLUSIONS_OTHER_PEOPLE: {
      return pipe(
        state,
        Optics.modify(getGridsPrism(action.payload.inspectionId))(
          A.map(grid =>
            A.elem(S.Eq)(grid.id)(action.payload.gridsId)
              ? { ...grid, conclusion: { ...grid.conclusion, ...action.payload.params } }
              : grid,
          ),
        ),
      );
    }

    case OfflinePendingActionType.UPDATE_CHECK_FILE_DOCUMENT:
      return pipe(
        state,
        Optics.modify(
          getCheckFileDocumentPrism(action.payload.inspectionId, action.payload.gridId, action.payload.checkFileId),
        )(
          checkDocument =>
            ({
              ...checkDocument,
              comment: null,
              fileOrReason: action.payload.fileOrReason,
            }) as OfflinePendingGridCheckFileDocument,
        ),
      );

    case OfflinePendingActionType.UPDATE_CHECK_DATA_DOCUMENT:
      return pipe(
        state,
        Optics.modify(
          getCheckDataDocumentPrism(action.payload.inspectionId, action.payload.gridId, action.payload.checkDataId),
        )(
          checkDocument =>
            ({
              ...checkDocument,
              comment: null,
              completedOrReason: action.payload.completedOrReason,
            }) as OfflinePendingGridCheckDataDocument,
        ),
      );

    case OfflinePendingActionType.UPDATE_CHECK_FILE_DOCUMENT_COMMENT:
      return pipe(
        state,
        Optics.modify(
          getCheckFileDocumentPrism(action.payload.inspectionId, action.payload.gridId, action.payload.checkFileId),
        )(checkDocument => ({
          ...checkDocument,
          comment: action.payload.comment,
        })),
      );

    case OfflinePendingActionType.UPDATE_CHECK_DATA_DOCUMENT_COMMENT:
      return pipe(
        state,
        Optics.modify(
          getCheckDataDocumentPrism(action.payload.inspectionId, action.payload.gridId, action.payload.checkDataId),
        )(checkDocument => ({
          ...checkDocument,
          comment: action.payload.comment,
        })),
      );

    case OfflinePendingActionType.CREATE_PARCEL_LINE:
      return pipe(
        state,
        Optics.modify(getParcelPrism(action.payload.inspectionId, action.payload.parcelId))(parcel => ({
          ...parcel,
          lines: [
            ...parcel.lines,
            { id: uuid(), state: OfflinePendingParcelDataLineState.New, rows: action.payload.params },
          ],
        })),
      );

    case OfflinePendingActionType.UPDATE_PARCEL_LINE:
      const newLine = pipe(
        state,
        Optics.preview(
          getStateRowParcelPrism(action.payload.inspectionId, action.payload.parcelId, action.payload.rowId),
        ),
      );

      if (newLine) {
        return pipe(
          state,
          Optics.modify(getRowParcelPrism(action.payload.inspectionId, action.payload.parcelId, action.payload.rowId))(
            line => ({
              ...line,
              state: OfflinePendingParcelDataLineState.New,
              rows: action.payload.params,
            }),
          ),
        );
      } else {
        return pipe(
          state,
          Optics.modify(getRowParcelPrism(action.payload.inspectionId, action.payload.parcelId, action.payload.rowId))(
            line => ({
              ...line,
              state: OfflinePendingParcelDataLineState.Updated,
              rows: action.payload.params,
            }),
          ),
        );
      }

    case OfflinePendingActionType.DELETE_PARCEL_LINE:
      const line = pipe(
        state,
        Optics.preview(
          getStateRowParcelPrism(action.payload.inspectionId, action.payload.parcelId, action.payload.rowId),
        ),
      );

      if (line) {
        return pipe(
          state,
          Optics.modify(getParcelRowsPrism(action.payload.inspectionId, action.payload.parcelId))(lines =>
            lines.filter(line => line.id !== action.payload.rowId),
          ),
        );
      } else {
        return pipe(
          state,
          Optics.modify(getRowParcelPrism(action.payload.inspectionId, action.payload.parcelId, action.payload.rowId))(
            line => ({
              ...line,
              state: OfflinePendingParcelDataLineState.Deleted,
              rows: action.payload.params,
            }),
          ),
        );
      }

    case OfflinePendingActionType.UPDATE_PARCEL_STATE:
      return pipe(
        state,
        Optics.set(getParcelPrism(action.payload.inspectionId, action.payload.parcelId).prop('isAnswered'))(
          action.payload.isAnswered,
        ),
      );

    case OfflinePendingActionType.UPDATE_PARCELS_STATE:
      return pipe(state, Optics.set(getParcelsPrism(action.payload.inspectionId).prop('state'))(action.payload.state));

    case OfflinePendingActionType.UPDATE_PARCELS_PRODUCTION:
      return pipe(
        state,
        Optics.set(getParcelsPrism(action.payload.inspectionId).prop('production'))(action.payload.production),
      );

    case OfflinePendingActionType.UPDATE_RISK_ANALYSIS_STATE:
      return pipe(
        state,
        Optics.set(getRiskAnalysisPrism(action.payload.inspectionId).prop('state'))(action.payload.state),
      );

    case OfflinePendingActionType.INSERT_RISK_ANALYSIS_CRITERIA:
      return pipe(
        state,
        Optics.set(
          getRiskAnalysisPrism(action.payload.inspectionId)
            .prop('riskAnalysisGrids')
            .prop(action.payload.code)
            .appendTo(),
        )(action.payload.criterion),
        Optics.set(getRiskAnalysisPrism(action.payload.inspectionId).prop('state'))(OfflinePendingSimpleState.Pending),
      );

    case OfflinePendingActionType.UPDATE_RISK_ANALYSIS_CRITERIA:
      return pipe(
        state,
        Optics.modify(
          getRiskAnalysisCriterionPrism(action.payload.inspectionId, action.payload.riskCode, action.payload.code),
        )(criterion => ({
          ...criterion,
          value: action.payload.value,
        })),
        Optics.set(getRiskAnalysisPrism(action.payload.inspectionId).prop('state'))(OfflinePendingSimpleState.Pending),
      );

    case OfflinePendingActionType.REMOVE_RISK_ANALYSIS_CRITERIA:
      return pipe(
        state,
        Optics.remove(
          getRiskAnalysisCriterionPrism(action.payload.inspectionId, action.payload.riskCode, action.payload.code),
        ),
        Optics.set(getRiskAnalysisPrism(action.payload.inspectionId).prop('state'))(OfflinePendingSimpleState.Pending),
      );

    case OfflinePendingActionType.UPDATE_RISK_ANALYSIS_COMMENT:
      return pipe(
        state,
        Optics.modify(
          getRiskAnalysisCriterionPrism(action.payload.inspectionId, action.payload.riskCode, action.payload.code),
        )(criterion => ({
          ...criterion,
          comment: action.payload.comment,
        })),
        Optics.set(getRiskAnalysisPrism(action.payload.inspectionId).prop('state'))(OfflinePendingSimpleState.Pending),
      );

    case OfflinePendingActionType.UPDATE_COVER_PAGE:
      return pipe(
        state,
        Optics.set(getCoverPagePrism(action.payload.inspectionId))({
          ...action.payload.cover,
          state: OfflinePendingSimpleState.Pending,
        }),
      );

    case OfflinePendingActionType.UPDATE_COVER_PAGE_STATE:
      return pipe(
        state,
        Optics.set(getCoverPagePrism(action.payload.inspectionId).prop('state'))(action.payload.state),
      );

    case OfflinePendingActionType.RESET_STATE:
      return defaultOfflinePendingState;

    default:
      return state;
  }
}

function getOfflinePendingReducer(storage: Storage): Reducer<OfflinePendingState, any> {
  const migrate = (state: (OfflinePendingState & { _persist: PersistState }) | undefined) => {
    const getNewState = () => {
      if (state != null && state._persist.version === 1) {
        return {
          ...state,
          inspections: pipe(
            state.inspections,
            R.map(inspection => ({
              ...inspection,
              grids: pipe(
                inspection.grids,
                A.map(grid => ({
                  ...grid,
                  sections: pipe(
                    grid.sections,
                    A.map(section => ({
                      ...section,
                      rows: pipe(
                        section.rows,
                        A.map(row => {
                          if (row.type === 'MERGED' && row.checkpoints != null) {
                            return {
                              id: row.id,
                              type: 'MERGED',
                              name: row.name,
                              items: {
                                type: OfflinePendingSectionMergedType.Checkpoint,
                                checkpoints: row.checkpoints,
                              },
                            };
                          }

                          return row;
                        }),
                      ),
                    })),
                  ),
                })),
              ),
            })),
          ),
        };
      }

      return state;
    };

    return Promise.resolve(getNewState());
  };

  return persistReducer(
    {
      key: 'offline-pending',
      version: 2,
      storage,
      migrate: migrate as any,
    },
    reducer,
  ) as any;
}

export default getOfflinePendingReducer;
