import styled, { css } from 'styled-components';
import { backgroundColor, fontWeight, textColor } from '@root/styles';

import logoutIcon from '@assets/icons/logout.svg';
import { AppMode } from '@modules/app/store/model';

export const HeaderUserContainer = styled.button<{ mode: AppMode }>`
  display: flex;
  align-items: center;
  padding: 0 36px 0 22px;
  border: none;
  background: none;
  outline: none;
  font: inherit;
  text-align: left;
  ${props =>
    props.mode === AppMode.Online &&
    css`
      cursor: pointer;
    `};
`;

export const HeaderUserInformation = styled.div<{ $light?: boolean }>`
  padding-left: 8px;

  > p {
    line-height: 1.2;

    ${props =>
      props.$light &&
      css`
        color: ${textColor.inverted};
      `};

    &:last-child {
      opacity: 0.5;
    }
  }
`;

export const LogoutMenuContainer = styled.div<{ open: boolean }>`
  position: absolute;
  top: 60px;
  right: 30px;
  width: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 50;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  background: ${backgroundColor.default};

  ${props =>
    props.open &&
    css`
      width: 195px;
      padding: 5px 20px;
      opacity: 1;
      box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.15);
      border-radius: 5px;
    `};
`;

export const LogoutMenuButton = styled.button`
  display: block;
  width: 100%;
  padding: 10px 10px 10px 25px;
  background: url(${logoutIcon}) no-repeat left center;
  background-size: 14px;
  border: none;
  outline: none;
  text-align: left;
  font: inherit;
  color: ${props => props.theme.colors.alert};
  cursor: pointer;
  font-weight: ${fontWeight.medium};
`;
