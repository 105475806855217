import React, { FC } from 'react';

import * as Styled from './Header.styles';
import { Profile } from '../../modules/auth/model';

import logo from '../../assets/logos/logo.svg';
import Nav from './nav/Nav';
import HeaderUser from './user/HeaderUser';

interface HeaderProps {
  profile: Profile;
}

const Header: FC<HeaderProps> = ({ profile }) => (
  <Styled.HeaderContainer>
    <Styled.HeaderHome to="/">
      <img src={logo} alt="logo" width={54} height={52} />
    </Styled.HeaderHome>
    <Nav />
    <HeaderUser profile={profile} />
  </Styled.HeaderContainer>
);

export default Header;
