import React, { FC, useCallback, useEffect } from 'react';

import { Provider, useDispatch } from 'react-redux';
import { initializeStore, ThunkDispatch } from './store';
import persistStore from 'redux-persist/es/persistStore';
import { PersistGate } from 'redux-persist/integration/react';
import { AuthAction, fetchProfile } from '@modules/auth/store/actions';
import RootRoutes, { history } from './routes';
import { Router } from 'react-router-dom';
import Layout from './layout/Layout';
import ServiceWorker from '@shared/modules/pwa/ServiceWorker';
import LineLoader from './shared/components/loaders/line-loaders/LineLoader';
import { ThemeProvider } from 'styled-components';
import theme, { GlobalStyle } from './styles';
import { GlobalModalStyle } from '@shared/components/modal/Modal.styles';
import { identity, pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';

import * as T from 'fp-ts/Task';
import { syncConfig } from '@modules/config/store/actions';
import { changeNetworkAvailability } from '@modules/app/store/actions';
import { useDebouncedCallback } from 'use-debounce';
import { useAppMode } from '@modules/app/hooks';
import { AppMode } from '@modules/app/store/model';
import SentryProvider from '@modules/config/SentryProvider';
import { useBooleanState } from '@shared/hooks/state';

const store = initializeStore();
const persistor = persistStore(store);

const AppContent: FC = () => {
  const [loading, , unsetLoading] = useBooleanState(true);

  const dispatch = useDispatch<ThunkDispatch<AuthAction>>();

  const { mode } = useAppMode();

  useEffect(() => {
    pipe(
      A.of(T.fromIO(() => dispatch(syncConfig()))),
      mode === AppMode.Online ? A.append(() => dispatch(fetchProfile())) : identity,
      A.sequence(T.ApplicativePar),
      T.chainIOK(() => unsetLoading),
    )();
  }, [dispatch, mode, unsetLoading]);

  const appDispatch = useDispatch();

  const checkNetworkAvailability = useCallback(() => {
    appDispatch(changeNetworkAvailability(navigator.onLine));
  }, [appDispatch]);

  const debouncedCheckNetworkAvailability = useDebouncedCallback(checkNetworkAvailability, 2000);

  useEffect(() => {
    debouncedCheckNetworkAvailability();

    window.addEventListener('online', debouncedCheckNetworkAvailability);
    window.addEventListener('offline', debouncedCheckNetworkAvailability);

    return () => {
      window.removeEventListener('online', debouncedCheckNetworkAvailability);
      window.removeEventListener('offline', debouncedCheckNetworkAvailability);
    };
  }, [debouncedCheckNetworkAvailability]);

  return loading ? (
    <LineLoader />
  ) : (
    <SentryProvider>
      <Layout>
        <RootRoutes />
      </Layout>
    </SentryProvider>
  );
};

const App: FC = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <GlobalModalStyle />
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ServiceWorker />
        <Router history={history}>
          <AppContent />
        </Router>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

export default App;
