import { Action, ActionWithPayload, ThunkResult } from '@root/store';
import {
  DataDocumentCompleted,
  NonComplianceType,
  ParcelDataLineRows,
  Signature,
  SyncIncomingInspection,
} from '../../../sync/model';

import * as fileStorageService from '../../../sync/file-storage';
import * as signatureDataStorageService from '../../grids/signature-draw-storage';

import * as A from 'fp-ts/Array';
import * as T from 'fp-ts/Task';
import * as O from 'fp-ts/Option';
import * as TO from 'fp-ts/TaskOption';
import * as NEA from 'fp-ts/NonEmptyArray';
import { pipe } from 'fp-ts/function';
import { dataUrlToOfflineFile, mapFileToOfflineFile, OfflineFile } from '@shared/modules/files';
import {
  OfflineCheckpointValue,
  OfflineGridState,
  OfflinePendingExistingNonCompliance,
  OfflinePendingGridCheckFileDocumentFileOrReason,
  OfflinePendingGridConclusion,
  OfflinePendingMeta,
  OfflinePendingNewNonCompliance,
  OfflinePendingSimpleState,
} from '../model';
import { Profile } from '../../../auth/model';
import { SignatureData } from '../../grids/signature-draw-storage';
import { sequenceT } from 'fp-ts/Apply';
import { InspectionProduction } from '../../../online/inspections/model';
import { RiskAnalysisGridTemplate } from '../../risk-analysis/model';
import { CoverPage } from '../../cover-page/model';
import { NonComplianceReferential } from '../../../referential/model';

export enum OfflinePendingActionType {
  INIT_STATE = 'OFFLINE/PENDING/INIT',
  SUBMIT_OPERATOR_ATTENTIONS = 'OFFLINE/PENDING/SUBMIT_OPERATOR_ATTENTIONS',
  UPDATE_GRID_STATE = 'OFFLINE/PENDING/UPDATE_GRID_STATE',
  UPDATE_GRIDS_STATE = 'OFFLINE/PENDING/UPDATE_GRIDS_STATE',
  FINISH_UNMATCHED_CHECKS = 'OFFLINE/PENDING/FINISH_UNMATCHED_CHECKS',
  UPDATE_META = 'OFFLINE/PENDING/UPDATE_META',
  SELECT_CHECK_SCOPES = 'OFFLINE/PENDING/SELECT_CHECK_SCOPES',
  CHECKPOINTS_SET_INAPPLICABLE_SECTION = 'OFFLINE/PENDING/CHECKPOINTS_SET_INAPPLICABLE_SECTION',
  CHECKPOINTS_UPDATE_ROW_VALUE = 'OFFLINE/PENDING/CHECKPOINTS_UPDATE_ROW_VALUE',
  CHECKPOINTS_UPDATE_MERGED_ROW_VALUE = 'OFFLINE/PENDING/CHECKPOINTS_UPDATE_MERGED_ROW_VALUE',
  CHECKPOINTS_UPDATE_ROW_COMMENT = 'OFFLINE/PENDING/CHECKPOINTS_UPDATE_ROW_COMMENT',
  UPLOAD_ATTACHED_FILES = 'OFFLINE/PENDING/UPLOAD_ATTACHED_FILES',
  DELETE_ATTACHED_FILE = 'OFFLINE/PENDING/DELETE_ATTACHED_FILE',
  UPDATE_ATTACHED_FILE = 'OFFLINE/PENDING/UPDATE_ATTACHED_FILE',
  UPDATE_EXISTING_NON_COMPLIANCE = 'OFFLINE/PENDING/UPDATE_EXISTING_NON_COMPLIANCE',
  CREATE_NEW_NON_COMPLIANCE = 'OFFLINE/PENDING/CREATE_NEW_NON_COMPLIANCE',
  UPDATE_NEW_NON_COMPLIANCE = 'OFFLINE/PENDING/UPDATE_NEW_NON_COMPLIANCE',
  DELETE_NEW_NON_COMPLIANCE = 'OFFLINE/PENDING/DELETE_NEW_NON_COMPLIANCE',
  UPDATE_GRID_CONCLUSION = 'OFFLINE/PENDING/UPDATE_GRID_CONCLUSION',
  UPDATE_SIGNATURE_FILES = 'OFFLINE/PENDING/UPDATE_SIGNATURE_FILES',
  UPDATE_OPERATOR_SIGNATURES_NAME = 'OFFLINE/PENDING/UPDATE_SIGNATURES_NAME',
  UPDATE_CONCLUSIONS_OTHER_PEOPLE = 'OFFLINE/PENDING/UPDATE_CONCLUSIONS_OTHER_PEOPLE',
  UPDATE_CHECK_FILE_DOCUMENT = 'OFFLINE/PENDING/UPDATE_CHECK_FILE_DOCUMENT',
  UPDATE_CHECK_DATA_DOCUMENT = 'OFFLINE/PENDING/UPDATE_CHECK_DATA_DOCUMENT',
  UPDATE_CHECK_FILE_DOCUMENT_COMMENT = 'OFFLINE/PENDING/UPDATE_CHECK_FILE_DOCUMENT_COMMENT',
  UPDATE_CHECK_DATA_DOCUMENT_COMMENT = 'OFFLINE/PENDING/UPDATE_CHECK_DATA_DOCUMENT_COMMENT',
  UPDATE_PARCELS_STATE = 'OFFLINE/PENDING/UPDATE_PARCELS_STATE',
  UPDATE_PARCELS_PRODUCTION = 'OFFLINE/PENDING/UPDATE_PARCELS_PRODUCTION',
  CREATE_PARCEL_LINE = 'OFFLINE/PENDING/CREATE_PARCEL_LINE',
  UPDATE_PARCEL_LINE = 'OFFLINE/PENDING/UPDATE_PARCEL_LINE',
  DELETE_PARCEL_NEW_LINE = 'OFFLINE/PENDING/DELETE_PARCEL_NEW_LINE',
  DELETE_PARCEL_LINE = 'OFFLINE/PENDING/DELETE_PARCEL_LINE',
  UPDATE_PARCEL_STATE = 'OFFLINE/PENDING/UPDATE_PARCEL_STATE',
  UPDATE_RISK_ANALYSIS_STATE = 'OFFLINE/PENDING/UPDATE_RISK_ANALYSIS_STATE',
  UPDATE_RISK_ANALYSIS_COMMENT = 'OFFLINE/PENDING/UPDATE_RISK_ANALYSIS_COMMENT',
  INSERT_RISK_ANALYSIS_CRITERIA = 'OFFLINE/PENDING/INSERT_RISK_ANALYSIS_CRITERIA',
  UPDATE_RISK_ANALYSIS_CRITERIA = 'OFFLINE/PENDING/UPDATE_RISK_ANALYSIS_CRITERIA',
  REMOVE_RISK_ANALYSIS_CRITERIA = 'OFFLINE/PENDING/REMOVE_RISK_ANALYSIS_CRITERIA',
  UPDATE_COVER_PAGE = 'OFFLINE/PENDING/UPDATE_COVER_PAGE',
  UPDATE_COVER_PAGE_STATE = 'OFFLINE/PENDING/UPDATE_COVER_PAGE_STATE',
  RESET_STATE = 'OFFLINE/PENDING/RESET',
}

export type OfflinePendingAction =
  | ActionWithPayload<
      OfflinePendingActionType.INIT_STATE,
      { inspections: Array<SyncIncomingInspection>; profile: Profile | null }
    >
  | ActionWithPayload<
      OfflinePendingActionType.SUBMIT_OPERATOR_ATTENTIONS,
      { inspectionId: string; email: string | false; isUnannounced: boolean }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_GRID_STATE,
      { inspectionId: string; gridId: string; state: OfflineGridState }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_GRIDS_STATE,
      {
        inspectionId: string;
        gridsId: Array<string>;
        state: OfflineGridState;
        operatorEmail: string | null;
        cci: NEA.NonEmptyArray<string> | null;
      }
    >
  | ActionWithPayload<OfflinePendingActionType.FINISH_UNMATCHED_CHECKS, { inspectionId: string }>
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_META,
      { inspectionId: string; gridId: string; sectionId: string; meta: OfflinePendingMeta }
    >
  | ActionWithPayload<
      OfflinePendingActionType.SELECT_CHECK_SCOPES,
      { inspectionId: string; gridId: string; checkScopes: Array<string>; comment: string | null }
    >
  | ActionWithPayload<
      OfflinePendingActionType.CHECKPOINTS_SET_INAPPLICABLE_SECTION,
      { inspectionId: string; gridId: string; sectionId: string; comment: string }
    >
  | ActionWithPayload<
      OfflinePendingActionType.CHECKPOINTS_UPDATE_ROW_VALUE,
      { inspectionId: string; gridId: string; rowId: string; value: OfflineCheckpointValue }
    >
  | ActionWithPayload<
      OfflinePendingActionType.CHECKPOINTS_UPDATE_MERGED_ROW_VALUE,
      { inspectionId: string; gridId: string; rowId: string; checkpointId: string; value: OfflineCheckpointValue }
    >
  | ActionWithPayload<
      OfflinePendingActionType.CHECKPOINTS_UPDATE_ROW_COMMENT,
      { inspectionId: string; gridId: string; rowId: string; comment: string | null }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPLOAD_ATTACHED_FILES,
      { inspectionId: string; gridId: string; files: Array<OfflineFile> }
    >
  | ActionWithPayload<
      OfflinePendingActionType.DELETE_ATTACHED_FILE,
      { inspectionId: string; gridId: string; fileId: string }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_ATTACHED_FILE,
      { inspectionId: string; gridId: string; fileId: string; name: string; comment: string | null }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_EXISTING_NON_COMPLIANCE,
      { inspectionId: string; gridId: string; id: string; params: UpdateExistingNonComplianceParams }
    >
  | ActionWithPayload<
      OfflinePendingActionType.CREATE_NEW_NON_COMPLIANCE,
      {
        inspectionId: string;
        gridId: string;
        id: string;
        defaultRecordType: NonComplianceType | null;
        referential?: NonComplianceReferential;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_NEW_NON_COMPLIANCE,
      {
        inspectionId: string;
        gridId: string;
        nonCompliance: OfflinePendingNewNonCompliance;
        updateState: boolean;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.DELETE_NEW_NON_COMPLIANCE,
      { inspectionId: string; gridId: string; id: string }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_GRID_CONCLUSION,
      { inspectionId: string; gridId: string; conclusion: UpdateConclusionParams }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_SIGNATURE_FILES,
      { inspectionId: string; gridsId: Array<string>; type: SignatureType; fileId: string }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_OPERATOR_SIGNATURES_NAME,
      { inspectionId: string; gridsId: Array<string>; params: UpdateSignatureParams }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_CONCLUSIONS_OTHER_PEOPLE,
      { inspectionId: string; gridsId: Array<string>; params: UpdateConclusionOtherPeopleParams }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_CHECK_FILE_DOCUMENT,
      {
        inspectionId: string;
        gridId: string;
        checkFileId: string;
        fileOrReason: OfflinePendingGridCheckFileDocumentFileOrReason | null;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_CHECK_DATA_DOCUMENT,
      {
        inspectionId: string;
        gridId: string;
        checkDataId: string;
        completedOrReason: string | DataDocumentCompleted | null;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_CHECK_FILE_DOCUMENT_COMMENT,
      { inspectionId: string; gridId: string; checkFileId: string; comment: string | null }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_CHECK_DATA_DOCUMENT_COMMENT,
      { inspectionId: string; gridId: string; checkDataId: string; comment: string | null }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_PARCELS_STATE,
      {
        inspectionId: string;
        state: OfflinePendingSimpleState;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_PARCELS_PRODUCTION,
      {
        inspectionId: string;
        production: InspectionProduction;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.CREATE_PARCEL_LINE,
      {
        inspectionId: string;
        parcelId: string;
        params: ParcelDataLineRows;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_PARCEL_LINE,
      {
        inspectionId: string;
        parcelId: string;
        rowId: string;
        params: ParcelDataLineRows;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.DELETE_PARCEL_NEW_LINE,
      {
        inspectionId: string;
        parcelId: string;
        rowId: string;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.DELETE_PARCEL_LINE,
      {
        inspectionId: string;
        parcelId: string;
        rowId: string;
        params: ParcelDataLineRows;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_PARCEL_STATE,
      {
        inspectionId: string;
        parcelId: string;
        isAnswered: boolean;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_RISK_ANALYSIS_STATE,
      {
        inspectionId: string;
        state: OfflinePendingSimpleState;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.INSERT_RISK_ANALYSIS_CRITERIA,
      {
        inspectionId: string;
        code: RiskAnalysisGridTemplate.Code;
        criterion: RiskAnalysisGridTemplate.Criterion.OfflinePending;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_RISK_ANALYSIS_CRITERIA,
      {
        inspectionId: string;
        riskCode: RiskAnalysisGridTemplate.Code;
        code: number;
        value: RiskAnalysisGridTemplate.Criterion.OfflineValue;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.REMOVE_RISK_ANALYSIS_CRITERIA,
      {
        inspectionId: string;
        riskCode: RiskAnalysisGridTemplate.Code;
        code: number;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_RISK_ANALYSIS_COMMENT,
      {
        inspectionId: string;
        riskCode: RiskAnalysisGridTemplate.Code;
        code: number;
        comment: string | null;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_COVER_PAGE,
      {
        inspectionId: string;
        cover: CoverPage.Pending;
      }
    >
  | ActionWithPayload<
      OfflinePendingActionType.UPDATE_COVER_PAGE_STATE,
      {
        inspectionId: string;
        state: OfflinePendingSimpleState;
      }
    >
  | Action<OfflinePendingActionType.RESET_STATE>;

export function initOfflinePendingState(
  inspections: Array<SyncIncomingInspection>,
  profile: Profile | null,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.INIT_STATE,
    payload: { inspections, profile },
  };
}

export function submitOperatorAttentions(
  inspectionId: string,
  email: string | false,
  isUnannounced: boolean,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.SUBMIT_OPERATOR_ATTENTIONS,
    payload: { inspectionId, email, isUnannounced },
  };
}

export function updateGridState(inspectionId: string, gridId: string, state: OfflineGridState): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_GRID_STATE,
    payload: {
      inspectionId,
      gridId,
      state,
    },
  };
}

export function updateGridsState(
  inspectionId: string,
  gridsId: Array<string>,
  state: OfflineGridState,
  operatorEmail: string | null,
  cci: NEA.NonEmptyArray<string> | null,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_GRIDS_STATE,
    payload: {
      inspectionId,
      gridsId,
      state,
      operatorEmail,
      cci,
    },
  };
}

export function finishUnmatchedCheck(inspectionId: string) {
  return {
    type: OfflinePendingActionType.FINISH_UNMATCHED_CHECKS,
    payload: {
      inspectionId,
    },
  };
}

export function updateGridMeta(
  inspectionId: string,
  gridId: string,
  sectionId: string,
  meta: OfflinePendingMeta,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_META,
    payload: {
      inspectionId,
      gridId,
      sectionId,
      meta,
    },
  };
}

export function selectCheckScopes(
  inspectionId: string,
  gridId: string,
  checkScopes: Array<string>,
  comment: string | null,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.SELECT_CHECK_SCOPES,
    payload: {
      inspectionId,
      gridId,
      checkScopes,
      comment,
    },
  };
}

export function setCheckpointsSectionInapplicable(
  inspectionId: string,
  gridId: string,
  sectionId: string,
  comment: string,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.CHECKPOINTS_SET_INAPPLICABLE_SECTION,
    payload: {
      inspectionId,
      gridId,
      sectionId,
      comment,
    },
  };
}

export function updateCheckpointsRowValue(
  inspectionId: string,
  gridId: string,
  rowId: string,
  value: OfflineCheckpointValue,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.CHECKPOINTS_UPDATE_ROW_VALUE,
    payload: {
      inspectionId,
      gridId,
      rowId,
      value,
    },
  };
}

export function updateCheckpointsMergedRowValue(
  inspectionId: string,
  gridId: string,
  rowId: string,
  checkpointId: string,
  value: OfflineCheckpointValue,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.CHECKPOINTS_UPDATE_MERGED_ROW_VALUE,
    payload: {
      inspectionId,
      gridId,
      rowId,
      checkpointId,
      value,
    },
  };
}

export function updateCheckpointsRowComment(
  inspectionId: string,
  gridId: string,
  rowId: string,
  comment: string | null,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.CHECKPOINTS_UPDATE_ROW_COMMENT,
    payload: {
      inspectionId,
      gridId,
      rowId,
      comment,
    },
  };
}

export function uploadAttachedFiles(
  inspectionId: string,
  gridId: string,
  files: Array<File>,
): ThunkResult<Promise<void>, OfflinePendingAction> {
  return dispatch =>
    pipe(
      files,
      A.map(mapFileToOfflineFile),
      A.traverse(T.ApplicativeSeq)(fileStorageService.addFile),
      T.chainIOK(
        files => () =>
          dispatch({
            type: OfflinePendingActionType.UPLOAD_ATTACHED_FILES,
            payload: {
              inspectionId,
              gridId,
              files,
            },
          }),
      ),
      T.asUnit,
    )();
}

export function deleteAttachedFile(
  inspectionId: string,
  gridId: string,
  fileId: string,
): ThunkResult<Promise<void>, OfflinePendingAction> {
  return dispatch =>
    pipe(
      fileStorageService.deleteFile(fileId),
      T.chainIOK(
        () => () =>
          dispatch({
            type: OfflinePendingActionType.DELETE_ATTACHED_FILE,
            payload: {
              inspectionId,
              gridId,
              fileId,
            },
          }),
      ),
      T.asUnit,
    )();
}

export function updateAttachedFile(
  inspectionId: string,
  gridId: string,
  fileId: string,
  name: string,
  comment: string | null,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_ATTACHED_FILE,
    payload: {
      inspectionId,
      gridId,
      fileId,
      name,
      comment,
    },
  };
}

export type UpdateExistingNonComplianceParams = Pick<OfflinePendingExistingNonCompliance, 'action' | 'note'>;

export function updateExistingNonCompliance(
  inspectionId: string,
  gridId: string,
  id: string,
  params: UpdateExistingNonComplianceParams,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_EXISTING_NON_COMPLIANCE,
    payload: {
      inspectionId,
      gridId,
      id,
      params,
    },
  };
}

export function createNewNonCompliance(
  inspectionId: string,
  gridId: string,
  id: string,
  defaultRecordType: NonComplianceType | null,
  referential?: NonComplianceReferential,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.CREATE_NEW_NON_COMPLIANCE,
    payload: {
      inspectionId,
      gridId,
      id,
      defaultRecordType,
      referential,
    },
  };
}

export function updateNewNonCompliance(
  inspectionId: string,
  gridId: string,
  nonCompliance: OfflinePendingNewNonCompliance,
  updateState: boolean,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_NEW_NON_COMPLIANCE,
    payload: {
      inspectionId,
      gridId,
      nonCompliance,
      updateState,
    },
  };
}

export function deleteNewNonCompliance(inspectionId: string, gridId: string, id: string): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.DELETE_NEW_NON_COMPLIANCE,
    payload: {
      inspectionId,
      gridId,
      id,
    },
  };
}

export type UpdateConclusionParams = Omit<OfflinePendingGridConclusion, 'auditorSignature' | 'operatorSignature'>;

export function updateConclusionParams(
  inspectionId: string,
  gridId: string,
  conclusion: UpdateConclusionParams,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_GRID_CONCLUSION,
    payload: {
      inspectionId,
      gridId,
      conclusion,
    },
  };
}

export type SignatureType = 'auditor' | 'operator';

function updateSignature(oldSignature: string | null, [signature, dataUrl]: [SignatureData, string]): T.Task<string> {
  const signatureFile = dataUrlToOfflineFile(dataUrl, 'signature.png');

  const deleteOldSignatureTask = pipe(
    TO.fromOption(O.fromNullable(oldSignature)),
    TO.chainTaskK(signatureId =>
      sequenceT(T.ApplySeq)(
        fileStorageService.deleteFile(signatureId),
        signatureDataStorageService.deleteSignatureData(signatureId),
      ),
    ),
    T.as(true),
  );

  const saveNewSignatureTask = pipe(
    fileStorageService.addFile(signatureFile),
    T.chain(() => signatureDataStorageService.addSignatureData(signatureFile.id, signature)),
    T.map(() => signatureFile.id),
  );

  return pipe(
    deleteOldSignatureTask,
    T.chain(() => saveNewSignatureTask),
  );
}

export function updateSignatureFiles(
  inspectionId: string,
  gridsId: Array<string>,
  type: SignatureType,
  oldSignature: string | null,
  newSignature: [SignatureData, string],
): ThunkResult<Promise<void>, OfflinePendingAction> {
  return dispatch =>
    pipe(
      updateSignature(oldSignature, newSignature),
      T.chainIOK(
        fileId => () =>
          dispatch({
            type: OfflinePendingActionType.UPDATE_SIGNATURE_FILES,
            payload: {
              inspectionId,
              gridsId,
              type,
              fileId,
            },
          }),
      ),
      T.asUnit,
    )();
}

export type UpdateSignatureParams = Omit<Signature, 'fileId'>;

export function updateOperatorSignaturesName(
  inspectionId: string,
  gridsId: Array<string>,
  params: UpdateSignatureParams,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_OPERATOR_SIGNATURES_NAME,
    payload: {
      inspectionId,
      gridsId,
      params,
    },
  };
}

export type UpdateConclusionOtherPeopleParams = Pick<
  OfflinePendingGridConclusion,
  'metPeople' | 'accompanyingPeople' | 'operatorEmail' | 'cci'
>;

export function updateConclusionsOtherPeople(
  inspectionId: string,
  gridsId: Array<string>,
  params: UpdateConclusionOtherPeopleParams,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_CONCLUSIONS_OTHER_PEOPLE,
    payload: {
      inspectionId,
      gridsId,
      params,
    },
  };
}

export function uploadCheckFileDocument(
  inspectionId: string,
  gridId: string,
  checkFileId: string,
  file: File,
): ThunkResult<Promise<void>, OfflinePendingAction> {
  return dispatch => {
    const offlineFile = mapFileToOfflineFile(file);

    return pipe(
      fileStorageService.addFile(offlineFile),
      T.chainIOK(
        () => () =>
          dispatch({
            type: OfflinePendingActionType.UPDATE_CHECK_FILE_DOCUMENT,
            payload: {
              inspectionId,
              gridId,
              checkFileId,
              fileOrReason: {
                type: 'file',
                fileId: offlineFile.id,
              },
            },
          }),
      ),
      T.asUnit,
    )();
  };
}

export function uploadCheckDataDocument(
  inspectionId: string,
  gridId: string,
  checkDataId: string,
  data: DataDocumentCompleted,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_CHECK_DATA_DOCUMENT,
    payload: {
      inspectionId,
      gridId,
      checkDataId,
      completedOrReason: data,
    },
  };
}

export function addCheckFileDocumentReason(
  inspectionId: string,
  gridId: string,
  checkFileId: string,
  reason: string,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_CHECK_FILE_DOCUMENT,
    payload: {
      inspectionId,
      gridId,
      checkFileId,
      fileOrReason: {
        type: 'reason',
        reason,
      },
    },
  };
}

export function addCheckDataDocumentReason(
  inspectionId: string,
  gridId: string,
  checkDataId: string,
  reason: string,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_CHECK_DATA_DOCUMENT,
    payload: {
      inspectionId,
      gridId,
      checkDataId,
      completedOrReason: reason,
    },
  };
}

export function resetCheckFileDocument(
  inspectionId: string,
  gridId: string,
  checkFileId: string,
  oldFileId: string | null,
): ThunkResult<Promise<void>, OfflinePendingAction> {
  return dispatch =>
    pipe(
      TO.fromNullable(oldFileId),
      TO.chainTaskK(fileId => fileStorageService.deleteFile(fileId)),
      T.chainIOK(
        () => () =>
          dispatch({
            type: OfflinePendingActionType.UPDATE_CHECK_FILE_DOCUMENT,
            payload: {
              inspectionId,
              gridId,
              checkFileId,
              fileOrReason: null,
            },
          }),
      ),
      T.asUnit,
    )();
}

export function resetCheckDataDocument(
  inspectionId: string,
  gridId: string,
  checkDataId: string,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_CHECK_DATA_DOCUMENT,
    payload: {
      inspectionId,
      gridId,
      checkDataId,
      completedOrReason: null,
    },
  };
}

export function updateCheckFileDocumentComment(
  inspectionId: string,
  gridId: string,
  checkFileId: string,
  comment: string | null,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_CHECK_FILE_DOCUMENT_COMMENT,
    payload: {
      inspectionId,
      gridId,
      checkFileId,
      comment,
    },
  };
}

export function updateCheckDataDocumentComment(
  inspectionId: string,
  gridId: string,
  checkDataId: string,
  comment: string | null,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_CHECK_DATA_DOCUMENT_COMMENT,
    payload: {
      inspectionId,
      gridId,
      checkDataId,
      comment,
    },
  };
}

export function updateParcelsState(inspectionId: string, state: OfflinePendingSimpleState): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_PARCELS_STATE,
    payload: {
      inspectionId,
      state,
    },
  };
}

export function updateParcelsProduction(inspectionId: string, production: InspectionProduction): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_PARCELS_PRODUCTION,
    payload: {
      inspectionId,
      production,
    },
  };
}

export function createParcelLine(
  inspectionId: string,
  parcelId: string,
  params: ParcelDataLineRows,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.CREATE_PARCEL_LINE,
    payload: {
      inspectionId,
      parcelId,
      params,
    },
  };
}

export function updateParcelLine(
  inspectionId: string,
  parcelId: string,
  rowId: string,
  params: ParcelDataLineRows,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_PARCEL_LINE,
    payload: {
      inspectionId,
      parcelId,
      rowId,
      params,
    },
  };
}

export function deleteParcelLine(
  inspectionId: string,
  parcelId: string,
  rowId: string,
  params: ParcelDataLineRows,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.DELETE_PARCEL_LINE,
    payload: {
      inspectionId,
      parcelId,
      rowId,
      params,
    },
  };
}

export function updateParcelState(inspectionId: string, parcelId: string, isAnswered: boolean): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_PARCEL_STATE,
    payload: {
      inspectionId,
      parcelId,
      isAnswered,
    },
  };
}

export function updateRiskAnalysisState(inspectionId: string, state: OfflinePendingSimpleState): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_RISK_ANALYSIS_STATE,
    payload: {
      inspectionId,
      state,
    },
  };
}

export function insertRiskAnalysisCriteria(
  inspectionId: string,
  code: RiskAnalysisGridTemplate.Code,
  criterion: RiskAnalysisGridTemplate.Criterion.OfflinePending,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.INSERT_RISK_ANALYSIS_CRITERIA,
    payload: {
      inspectionId,
      code,
      criterion,
    },
  };
}

export function updateRiskAnalysisCriteria(
  inspectionId: string,
  riskCode: RiskAnalysisGridTemplate.Code,
  code: number,
  value: RiskAnalysisGridTemplate.Criterion.OfflineValue,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_RISK_ANALYSIS_CRITERIA,
    payload: {
      inspectionId,
      riskCode,
      code,
      value,
    },
  };
}

export function removeRiskAnalysisCriteria(
  inspectionId: string,
  riskCode: RiskAnalysisGridTemplate.Code,
  code: number,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.REMOVE_RISK_ANALYSIS_CRITERIA,
    payload: {
      inspectionId,
      riskCode,
      code,
    },
  };
}

export function updateRiskAnalysisComment(
  inspectionId: string,
  riskCode: RiskAnalysisGridTemplate.Code,
  code: number,
  comment: string | null,
): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_RISK_ANALYSIS_COMMENT,
    payload: {
      inspectionId,
      riskCode,
      code,
      comment,
    },
  };
}

export function updateCoverPage(inspectionId: string, cover: CoverPage.Pending): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_COVER_PAGE,
    payload: {
      inspectionId,
      cover,
    },
  };
}

export function updateCoverPageState(inspectionId: string, state: OfflinePendingSimpleState): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.UPDATE_COVER_PAGE_STATE,
    payload: {
      inspectionId,
      state,
    },
  };
}

export function resetOfflinePendingState(): OfflinePendingAction {
  return {
    type: OfflinePendingActionType.RESET_STATE,
  };
}
