import { Action, ActionWithPayload, ThunkResult } from '../../../store';
import { Profile } from '../model';
import { pipe } from 'fp-ts/function';
import * as EI from 'fp-ts/Either';
import { getProfile } from '../service';
import { HttpStatusCode } from '../../../core/http';

export enum AuthActionsType {
  UPDATE_PROFILE = 'AUTH/UPDATE_PROFILE',
  REMOVE_PROFILE = 'AUTH/REMOVE_PROFILE',
}

export type AuthAction =
  | ActionWithPayload<AuthActionsType.UPDATE_PROFILE, Profile>
  | Action<AuthActionsType.REMOVE_PROFILE>;

export function updateProfile(profile: Profile): AuthAction {
  return {
    type: AuthActionsType.UPDATE_PROFILE,
    payload: profile,
  };
}

export function removeProfile(): AuthAction {
  return {
    type: AuthActionsType.REMOVE_PROFILE,
  };
}

export function fetchProfile(): ThunkResult<Promise<void>, AuthAction> {
  return dispatch =>
    getProfile()().then(res =>
      pipe(
        res,
        EI.fold(
          err => {
            if (HttpStatusCode.UNAUTHORIZED === err.status) {
              dispatch(removeProfile());
            }
          },
          profile => {
            dispatch(updateProfile(profile));
          },
        ),
      ),
    );
}
