import { AppState, defaultAppState } from './model';
import { AppAction, AppActionType } from './actions';
import { Reducer } from 'redux';
import { persistReducer } from 'redux-persist';
import { Storage } from 'redux-persist/es/types';

function reducer(state = defaultAppState, action: AppAction): AppState {
  switch (action.type) {
    case AppActionType.CHANGE_MODE:
      return {
        ...state,
        mode: action.payload,
      };
    case AppActionType.CHANGE_NETWORK_AVAILABILITY:
      return {
        ...state,
        networkAvailable: action.payload,
      };
    default:
      return state;
  }
}

function getAppReducer(storage: Storage): Reducer<AppState, any> {
  return persistReducer(
    {
      key: 'app',
      version: 1,
      storage,
    },
    reducer,
  ) as any;
}

export default getAppReducer;
