import { Reducer } from 'redux';
import { defaultOfflineIncomingState, IncomingInspectionMap, OfflineIncomingState } from '../model';
import { persistReducer } from 'redux-persist';
import { OfflineIncomingAction, OfflineIncomingActionType } from '../actions/incoming';
import { Storage } from 'redux-persist/es/types';

import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { SyncIncomingInspection } from '../../../sync/model';
import { formatDefaultDate } from '../../../../shared/utils/date';

function reducer(state = defaultOfflineIncomingState, action: OfflineIncomingAction): OfflineIncomingState {
  switch (action.type) {
    case OfflineIncomingActionType.INIT_STATE:
      const inspections = pipe(
        action.payload.inspections,
        A.reduce<SyncIncomingInspection, IncomingInspectionMap>({}, (inspections, inspection) => ({
          ...inspections,
          [inspection.id]: inspection,
        })),
      );

      return {
        date: formatDefaultDate(action.payload.date),
        inspections,
      };

    case OfflineIncomingActionType.RESET_STATE:
      return defaultOfflineIncomingState;

    default:
      return state;
  }
}

function getOfflineIncomingReducer(storage: Storage): Reducer<OfflineIncomingState, any> {
  return persistReducer(
    {
      key: 'offline-incoming',
      version: 1,
      storage,
    },
    reducer,
  ) as any;
}

export default getOfflineIncomingReducer;
