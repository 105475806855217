import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as A from 'fp-ts/Array';
import * as TO from 'fp-ts/TaskOption';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import * as S from 'fp-ts/string';
import { logSentryMessage } from '@shared/utils/sentry';

const DOCUMENTS_CACHE_KEY = 'qualisud-file-cache';

function getCache(): TO.TaskOption<Cache> {
  return pipe(
    TO.fromNullable(window.caches),
    TO.chain(caches => TO.fromTask(() => caches.open(DOCUMENTS_CACHE_KEY))),
  );
}

function getCacheKeys(cache: Cache): T.Task<Array<string>> {
  return pipe(
    () => cache.keys(),
    T.map(requests => requests.map(request => request.url)),
  );
}

function cacheFile(cache: Cache, url: string): TE.TaskEither<Error, void> {
  return pipe(
    TE.tryCatch(
      () => cache.add(url),
      err => new Error((err as any).message),
    ),
    TE.orElse(err =>
      TE.leftIO(() => {
        logSentryMessage('Cannot cache file in cache storage', 'error', {
          url,
          message: err.message,
        });

        return err;
      }),
    ),
  );
}

function cacheFiles(cache: Cache, urls: Array<string>): T.Task<void> {
  const concurrentTask = (u: Array<string>) =>
    pipe(
      u,
      A.traverse(T.ApplicativePar)(key => cacheFile(cache, key)),
    );

  return pipe(
    urls,
    A.uniq(S.Eq),
    A.chunksOf(5),
    A.traverse(T.ApplicativePar)(d => concurrentTask(d)),
    T.asUnit,
  );
}

function removeFile(cache: Cache, url: string): T.Task<boolean> {
  return () => cache.delete(url);
}

function removeFiles(cache: Cache, urls: Array<string>): T.Task<void> {
  return pipe(
    urls,
    A.uniq(S.Eq),
    A.traverse(T.ApplicativeSeq)(d => removeFile(cache, d)),
    T.asUnit,
  );
}

export function syncFiles(urls: Array<string>): T.Task<void> {
  const task = pipe(
    TO.bindTo('cache')(getCache()),
    TO.bind('existingKeys', ({ cache }) => TO.fromTask(getCacheKeys(cache))),
    TO.tapTask(({ cache, existingKeys }) => removeFiles(cache, A.difference(S.Eq)(existingKeys, urls))),
    TO.tapTask(({ cache, existingKeys }) => cacheFiles(cache, A.difference(S.Eq)(urls, existingKeys))),
  );

  return T.asUnit(task);
}

export function getFile(url: string): TO.TaskOption<Response> {
  return pipe(
    getCache(),
    TO.chain(cache => pipe(() => cache.match(url), T.map(O.fromNullable))),
  );
}

export function getFileAsBlob(url: string): TO.TaskOption<Blob> {
  return pipe(
    getFile(url),
    TO.chain(response => TO.fromTask(() => response.blob())),
  );
}

export function getFileAsDataUrl(url: string): TO.TaskOption<string> {
  return pipe(
    getFileAsBlob(url),
    TO.map(blob => URL.createObjectURL(blob)),
  );
}
