import {
  PendingSyncInspection,
  PendingSyncPayload,
  PendingSyncConclusion,
  PendingSyncGrid,
  PendingSyncIgnoredGrid,
} from '../../model';

import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { OfflinePendingGridConclusion } from '../../../offline/store/model';
import { mapIgnoredGridAttachedFiles } from './index';

export function getDocumentsIdsToSync(payload: PendingSyncPayload): Array<string> {
  return pipe(payload.inspections, A.chain(getDocumentsIdsToSyncFromInspection)).concat(
    getDocumentsIdsToSyncFromIgnoredGrids(payload.ignoredGrids ?? []),
  );
}

export function getDocumentsIdsToSyncFromInspection(inspection: PendingSyncInspection): Array<string> {
  return pipe(inspection.grids, A.chain(getDocumentsIdsToSyncFromGrid));
}

export function getDocumentsIdsToSyncFromIgnoredGrids(grids: Array<PendingSyncIgnoredGrid>): Array<string> {
  return pipe(
    grids,
    A.chain(({ payload }) => getDocumentsIdsToSyncFromIgnoredGrid(payload)),
  );
}

export function getDocumentsIdsToSyncFromGrid(grid: PendingSyncGrid): Array<string> {
  const attachedFilesIds = pipe(
    grid.attachedFiles,
    A.filterMap(attachedFile =>
      O.fromNullable(attachedFile.type === 'data-document' ? attachedFile.id : attachedFile.fileId),
    ),
  );

  return [...attachedFilesIds, ...getSignaturesIds(grid.conclusion)];
}

export function getDocumentsIdsToSyncFromIgnoredGrid(grid: PendingSyncIgnoredGrid['payload']): Array<string> {
  const attachedFilesIds = pipe(
    mapIgnoredGridAttachedFiles(grid),
    A.filterMap(attachedFile =>
      O.fromNullable(attachedFile.type === 'data-document' ? attachedFile.id : attachedFile.fileId),
    ),
  );

  return [...attachedFilesIds, ...getSignaturesIds(grid.conclusion)];
}

export function getSignaturesIds(conclusion: PendingSyncConclusion | OfflinePendingGridConclusion): Array<string> {
  return pipe(
    [O.fromNullable(conclusion.operatorSignature.fileId), O.fromNullable(conclusion.auditorSignature.fileId)],
    A.compact,
  );
}
