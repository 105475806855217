import { AuthenticateParams, ForgotPasswordParams, PasswordParams, Profile, STORAGE_TOKEN_KEY, Token } from './model';

import * as TO from 'fp-ts/TaskOption';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { HttpError, httpService, HttpStatusCode, HttpTask } from '@core/http';
import { getStorageService } from '@core/storage';

const securityStorage = getStorageService('security');

export function saveAuthToken(token: Token): T.Task<Token> {
  return securityStorage.setItem(STORAGE_TOKEN_KEY, token);
}

export function getAuthToken(): TO.TaskOption<Token> {
  return securityStorage.getItem<Token>(STORAGE_TOKEN_KEY);
}

export function removeAuthToken(): T.Task<void> {
  return securityStorage.removeItem(STORAGE_TOKEN_KEY);
}

export function authenticate(params: AuthenticateParams): HttpTask<void> {
  return pipe(
    httpService.post<Token>('/api/pwa/authenticate', params),
    TE.chain(res => TE.rightTask(saveAuthToken(res))),
    TE.asUnit,
  );
}

export function refreshToken(): HttpTask<void> {
  return pipe(
    getAuthToken(),
    TO.chainNullableK(token => token.refreshToken),
    TO.fold(
      () => TE.left(HttpError.fromStatusCode(HttpStatusCode.UNAUTHORIZED)),
      refreshToken => httpService.post<Token>('/api/pwa/authenticate/refresh', { refreshToken }),
    ),
    TE.flatMapTask(saveAuthToken),
    TE.asUnit,
  );
}

export function getProfile(token?: string): HttpTask<Profile> {
  const requestHeaders = pipe(
    O.fromNullable(token),
    O.map(token => ({ Bearer: token })),
    O.toUndefined,
  );

  return httpService.get('/api/pwa/profile', { headers: requestHeaders });
}

export function forgotPassword(params: ForgotPasswordParams): HttpTask<void> {
  return httpService.post('/api/pwa/password-reset/forgot', params);
}

export function getResetPasswordDetail(token: string): HttpTask<{ email: string }> {
  return httpService.get(`/api/pwa/password-reset/${token}`);
}

export function resetPassword(token: string, params: PasswordParams): HttpTask<void> {
  return httpService.post(`/api/pwa/password-reset/${token}`, params);
}

export function getActivationDetail(token: string): HttpTask<{ email: string }> {
  return httpService.get(`/api/pwa/activation/${token}`);
}

export function activateAccount(token: string, params: PasswordParams): HttpTask<void> {
  return pipe(
    httpService.post<Token>(`/api/pwa/activation/${token}`, params),
    TE.chain(res => TE.rightTask(saveAuthToken(res))),
    TE.asUnit,
  );
}
