import React, { FC } from 'react';

import * as Styled from './Nav.styles';
import { NavLink } from 'react-router-dom';
import * as H from 'history';
import queryString from 'query-string';
import { getDefaultFilters } from '@modules/online/inspections/hooks';

interface NavEntry {
  title: string;
  to: H.LocationDescriptor;
}

const entries: ReadonlyArray<NavEntry> = [
  {
    title: 'Tournées',
    to: '/',
  },
  {
    title: 'Visites à planifier',
    to: {
      pathname: '/inspections/unscheduled',
      search: queryString.stringify(getDefaultFilters()),
    },
  },
  {
    title: 'Historique des synchronisations',
    to: '/pending',
  },
];

const Nav: FC = () => (
  <Styled.NavContainer>
    <Styled.NavList>
      {entries.map((entry, i) => (
        <Styled.NavItem key={i}>
          <NavLink to={entry.to} activeClassName="active" exact={entry.to === '/'}>
            {entry.title}
          </NavLink>
        </Styled.NavItem>
      ))}
    </Styled.NavList>
  </Styled.NavContainer>
);

export default Nav;
