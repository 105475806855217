import React, { FC, forwardRef, useMemo } from 'react';
import ReactSelect, {
  Props,
  StylesConfig,
  ValueType,
  components,
  MenuListComponentProps,
  createFilter,
} from 'react-select';

import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/function';
import { filterEmptyElements } from '@shared/utils/array';
import { OptionProps } from 'react-select/src/components/Option';

import { FixedSizeList as List } from 'react-window';
import { SelectComponents } from 'react-select/src/components';
import StateManager from 'react-select';

const OPTION_HEIGHT = 41;

const styles: StylesConfig<SelectOption, false> = {
  control: (base, { selectProps }) => {
    return {
      ...base,
      minHeight: '45px',
      borderRadius: '100px',
      border: `1px solid ${selectProps.error ? '#c4220d' : '#e2e2e2'}`,
      boxShadow: 'none',
      '&:hover': {
        borderColor: selectProps.error ? '#c4220d' : '#e2e2e2',
      },
    };
  },
  valueContainer: base => ({
    ...base,
    paddingLeft: '20px',
  }),
  menuPortal: base => ({
    ...base,
    zIndex: 100000,
  }),
  menu: base => ({
    ...base,
    zIndex: 3,
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  placeholder: base => ({
    ...base,
    color: 'rgba(57, 53, 50, 0.5)',
    fontStyle: 'italic',
    fontSize: '14px',
  }),
  option: (base, { isFocused, isSelected, selectProps }) => ({
    ...base,
    height: OPTION_HEIGHT,
    backgroundColor: isSelected ? '#e85b3f' : isFocused && !selectProps.virtualized ? '#ffe9d7' : '#ffffff',
    fontSize: '14px',
    '&:active': undefined,
  }),
  multiValue: base => ({
    ...base,
    borderRadius: '4px',
    border: '1px solid rgba(181, 181, 181, 0.42)',
  }),

  multiValueRemove: base => ({
    ...base,
    paddingLeft: 0,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'none',
    },
  }),
};

function getNoOptionsMessage(
  noOptionsMessage?: (obj: { inputValue: string }) => string | null,
): (obj: { inputValue: string }) => string | null {
  return noOptionsMessage ?? (() => 'Aucune correspondance');
}

export interface SelectOption {
  value: string;
  label: string;
}

export interface SelectProps extends Omit<Props, 'value' | 'isMulti'> {
  value?: string | null;
  options: Array<SelectOption>;
  onChange: (value: string | null) => void;
  error?: boolean;
  virtualized?: boolean;
}

const Option: FC<OptionProps<SelectOption, false>> = ({ children, ...props }) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = Object.assign(props, { innerProps: rest });

  const optionTitle = typeof (children as any) === 'string' ? children : undefined;

  return (
    <components.Option {...newProps}>
      <p
        title={optionTitle as string}
        style={{
          maxWidth: '100%',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </p>
    </components.Option>
  );
};

const MenuList: FC<MenuListComponentProps<SelectOption, false>> = ({ options, children, maxHeight, getValue }) => {
  const value = getValue();
  const initialOffset = options.indexOf(value as unknown as SelectOption) * OPTION_HEIGHT;

  return (
    <List
      width="auto"
      height={maxHeight}
      itemCount={(children as any).length}
      itemSize={OPTION_HEIGHT}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style as any}>{(children as any)[index]}</div>}
    </List>
  );
};

export const Select = forwardRef<StateManager<SelectOption>, SelectProps>(
  ({ value, options, onChange, isMulti, noOptionsMessage, error, virtualized, ...rest }, ref) => {
    const handleChange = (option: ValueType<SelectOption, false>) => {
      const value = pipe(
        O.fromNullable(option),
        O.chain(opt => (Array.isArray(opt) ? O.none : O.some(opt))),
        O.chainNullableK(opt => (opt as any)?.value),
        O.toNullable,
      );

      return onChange(value);
    };

    const selectValue = useMemo(
      () =>
        pipe(
          O.fromNullable(value),
          O.chain(value =>
            pipe(
              options,
              A.findFirst(opt => opt.value === value),
            ),
          ),
          O.toNullable,
        ),
      [value, options],
    );

    const filterOptions = virtualized ? createFilter({ ignoreAccents: false }) : undefined;

    const customComponents: Partial<SelectComponents<SelectOption, false>> | undefined = virtualized
      ? { MenuList, Option }
      : undefined;

    return (
      <ReactSelect
        ref={ref}
        menuPortalTarget={document.body}
        {...rest}
        value={selectValue}
        options={options}
        styles={styles}
        error={error}
        virtualized={virtualized}
        onChange={handleChange}
        noOptionsMessage={getNoOptionsMessage(noOptionsMessage)}
        components={customComponents}
        filterOptions={filterOptions}
      />
    );
  },
);

export interface MultiSelectProps extends Omit<Props, 'value'> {
  value?: Array<string> | null;
  options: Array<SelectOption>;
  onChange: (value: Array<string>) => void;
}

export const MultiSelect: FC<MultiSelectProps> = ({ value, options, onChange, isMulti, noOptionsMessage, ...rest }) => {
  const handleChange = (option: ValueType<SelectOption, false>) => {
    const value = pipe(
      O.fromNullable(option),
      O.chain(opt => (Array.isArray(opt) ? O.some(opt) : O.none)),
      O.map(opt => opt.map(o => o.value)),
      O.getOrElse<Array<string>>(() => []),
    );

    return onChange(value);
  };

  const selectValue = useMemo(
    () =>
      pipe(
        O.fromNullable(value),
        O.map(value => filterEmptyElements(value.map(v => options.find(opt => opt.value === v)))),
        O.toNullable,
      ),
    [value, options],
  );

  return (
    <ReactSelect
      {...rest}
      value={selectValue}
      options={options}
      styles={styles}
      onChange={handleChange as any}
      isMulti
      noOptionsMessage={getNoOptionsMessage(noOptionsMessage)}
      closeMenuOnSelect={false}
    />
  );
};

export default Select;
