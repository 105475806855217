import styled from 'styled-components';
import { backgroundColor, secondaryColor, tertiaryColor } from '../../utils';

export const Radio = styled.input.attrs(() => ({ type: 'radio' }))`
  position: relative;
  width: 22px;
  height: 22px;
  margin: 0;
  border-radius: 100%;
  background: ${tertiaryColor(300)};
  cursor: pointer;
  outline: none;

  &:before {
    display: block;
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: 16px;
    height: 16px;
    background: ${backgroundColor.default};
    border: 2px solid ${backgroundColor.default};
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    box-sizing: border-box;
    border-radius: 100%;
    transition: background-color 0.15s ease-in-out;
  }

  &:checked {
    &:before {
      background: ${secondaryColor()};
    }
  }
`;
