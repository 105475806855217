import React, { Component } from 'react';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

import { connect } from 'react-redux';
import { profileSelector } from '../auth/store/selectors';

import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';
import * as A from 'fp-ts/Array';
import * as TO from 'fp-ts/TaskOption';
import { Profile } from '../auth/model';
import { sequenceS } from 'fp-ts/Apply';
import { getStorageService } from '@core/storage';

import { v4 as uuid } from 'uuid';
import { networkAvailabilitySelector } from '../app/store/selectors';
import { ConfigState } from './store/model';
import { RootState } from '../../store';
import { configSelector } from './store/selectors';

import isEqual from 'lodash.isequal';
import Error from '../../shared/components/error/Error';
import { HttpStatusCode } from '@core/http';

const sentryEventsStorage = getStorageService('sentry-events');

function syncOfflineSentryEvent(id: string) {
  return pipe(
    sentryEventsStorage.getItem<Sentry.Event>(id),
    TO.chainIOK(event => () => Sentry.captureEvent(event)),
    TO.chainTaskK(() => sentryEventsStorage.removeItem(id)),
    T.asUnit,
  );
}

function syncOfflineSentryEvents() {
  return pipe(sentryEventsStorage.keys(), T.chain(A.traverse(T.ApplicativePar)(syncOfflineSentryEvent)), T.asUnit);
}

function getSentryUser(profile: O.Option<Profile>): Sentry.User {
  return pipe(
    profile,
    O.fold<Profile, Sentry.User>(
      () => ({ username: 'Not authenticated' }),
      p => ({ email: p.email, username: `${p.firstName} ${p.lastName}` }),
    ),
  );
}

interface SentryProviderProps {
  config: ConfigState;
  profile: O.Option<Profile>;
  networkAvailable: boolean;
  children: React.JSX.Element;
}

function mapStateToProps(state: RootState) {
  return {
    config: configSelector(state),
    profile: profileSelector(state),
    networkAvailable: networkAvailabilitySelector(state),
  };
}

class SentryProvider extends Component<SentryProviderProps> {
  componentDidMount() {
    const { config, profile } = this.props;

    if (process.env.NODE_ENV === 'production') {
      pipe(
        sequenceS(O.Apply)({
          dsn: O.fromNullable(config.sentryDsn),
          environment: O.some(config.environment),
          release: O.fromNullable(config.release),
        }),
        O.fold(
          () => console.warn('Missing sentry dns, environment or release'),
          options => {
            Sentry.init({
              ...options,
              integrations: [new BrowserTracing()],
              tracesSampleRate: 1.0,
              attachStacktrace: true,
            });

            Sentry.configureScope(scope => {
              scope.setUser(getSentryUser(profile));

              scope.addEventProcessor(event => {
                if (navigator.onLine) {
                  return event;
                } else {
                  return pipe(sentryEventsStorage.setItem(uuid(), event), T.as(null))();
                }
              });
            });
          },
        ),
      );
    }
  }

  componentDidUpdate(prevProps: Readonly<SentryProviderProps>) {
    if (this.props.networkAvailable && !prevProps.networkAvailable) {
      syncOfflineSentryEvents()();
    }

    if (!isEqual(this.props.profile, prevProps.profile)) {
      Sentry.configureScope(scope => {
        scope.setUser(getSentryUser(this.props.profile));
      });
    }
  }

  render() {
    return (
      <Sentry.ErrorBoundary fallback={<Error status={HttpStatusCode.INTERNAL_SERVER_ERROR} />}>
        {this.props.children}
      </Sentry.ErrorBoundary>
    );
  }
}

export default connect(mapStateToProps)(SentryProvider);
