import { persistReducer } from 'redux-persist';
import { Reducer } from 'redux';
import { defaultSyncState, SyncState } from './model';
import { SyncAction, SyncActionType } from './actions';
import { Storage } from 'redux-persist/es/types';

function reducer(state = defaultSyncState, action: SyncAction): SyncState {
  switch (action.type) {
    case SyncActionType.RESET_STATE:
      return { ...defaultSyncState, date: state.date };

    case SyncActionType.UPDATE_DATE:
      return {
        ...state,
        date: action.payload,
      };

    case SyncActionType.UPDATE_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };

    case SyncActionType.UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case SyncActionType.UPDATE_PENDING_ID:
      return {
        ...state,
        pendingId: action.payload,
      };
    default:
      return state;
  }
}

function getSyncReducer(storage: Storage): Reducer<SyncState, any> {
  return persistReducer(
    {
      key: 'sync',
      version: 1,
      storage,
    },
    reducer,
  ) as any;
}

export default getSyncReducer;
