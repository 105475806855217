import { useCallback, useEffect, useState } from 'react';
import { UnscheduledInspectionRangeFilter, UnscheduledInspectionRangeSort } from './model';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { addDays } from 'date-fns';
import { formatDefaultDate } from '@shared/utils/date';
import {
  getArrayFilterValue,
  getBooleanFilterValue,
  getDateFilterValue,
  getEnumFilterValue,
  getStringFilterValue,
  removeEmptyFilters,
} from '@shared/utils/queries';

import isEqual from 'lodash.isequal';
import { MetricCode } from '@modules/online/metrics/model';

export function getDefaultFilters(): UnscheduledInspectionRangeFilter {
  return {
    minDate: formatDefaultDate(addDays(new Date(), 60)),
  };
}

function getNewFilters(search: string): UnscheduledInspectionRangeFilter {
  const values = removeEmptyFilters(queryString.parse(search));

  return {
    minDate: getDateFilterValue(values['minDate']),
    district: getArrayFilterValue(values['district']),
    operatorType: getArrayFilterValue(values['operatorType']),
    isImperative: getBooleanFilterValue(values['isImperative']),
    isPartial: getBooleanFilterValue(values['isPartial']),
    hasAdditionalCheck: getBooleanFilterValue(values['hasAdditionalCheck']),
    hasNonCompliance: getBooleanFilterValue(values['hasNonCompliance']),
    product: getArrayFilterValue(values['product']),
    isBooked: getBooleanFilterValue(values['isBooked']),
    search: getStringFilterValue(values['search']),
    sort: getEnumFilterValue(UnscheduledInspectionRangeSort, values['sort']),
    dashboard_metric: getEnumFilterValue(MetricCode, values['dashboard_metric']),
  };
}

export function useUnscheduledInspectionsFilters(
  handleFilter?: (filters: UnscheduledInspectionRangeFilter) => void,
): [UnscheduledInspectionRangeFilter, (filters: Partial<UnscheduledInspectionRangeFilter>) => void, () => void] {
  const history = useHistory();
  const location = useLocation();

  const [filters, setFilters] = useState<UnscheduledInspectionRangeFilter>(getNewFilters(location.search));

  useEffect(() => {
    const newFilters = getNewFilters(location.search);

    setFilters(old => {
      if (!isEqual(old, newFilters)) {
        return newFilters;
      } else {
        return old;
      }
    });
  }, [location.search]);

  useEffect(() => {
    if (handleFilter) {
      handleFilter(filters);
    }
  }, [filters, handleFilter]);

  const applyNewFilters = useCallback(
    (newFilters: Partial<UnscheduledInspectionRangeFilter>) =>
      history.replace({
        pathname: location.pathname,
        search: queryString.stringify(removeEmptyFilters({ ...filters, ...newFilters })),
      }),
    [history, filters, location.pathname],
  );

  const resetFilters = useCallback(
    () =>
      history.replace({
        pathname: location.pathname,
        search: queryString.stringify(getDefaultFilters()),
      }),
    [history, location.pathname],
  );

  return [filters, applyNewFilters, resetFilters];
}
