import { InspectionProduction } from '../../../online/inspections/model';
import { filterEmptyStringToNullable } from '../../../../shared/utils/string';
import { pipe } from 'fp-ts/function';

import * as O from 'fp-ts/Option';

export function filterProduction(production: InspectionProduction): InspectionProduction {
  return {
    notifiedNumber: filterEmptyStringToNullable(production.notifiedNumber),
    pastureNumber: filterEmptyStringToNullable(production.pastureNumber),
    bioActivityCode: filterEmptyStringToNullable(production.bioActivityCode),
    diversity: production.diversity,
    risk: pipe(
      O.fromNullable(production.risk),
      O.map(risk => (typeof risk === 'string' ? parseInt(risk, 10) : risk)),
      O.filter(risk => !isNaN(risk) && risk >= 0),
      O.toNullable,
    ),
  };
}
