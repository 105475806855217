import { HttpError } from '../../../core/http';

export interface SyncState {
  date: string | null;
  error: HttpError | null;
  message: string | null;
  pendingId: string | null;
}

export const defaultSyncState: SyncState = {
  date: null,
  error: null,
  message: null,
  pendingId: null,
};
