import {
  InspectionCheckProcedure,
  InspectionCheckSpecification,
  InspectionDetail,
  InspectionProduction,
  OperatorType,
} from '../online/inspections/model';
import { NonComplianceReferential, SpecificationReferential } from '../referential/model';
import { EnumLabels } from '../../shared/model/enum';
import { GridType, OfflineGridState, OfflinePendingGrid } from '../offline/store/model';
import * as NEA from 'fp-ts/NonEmptyArray';
import { RiskAnalysisGridTemplate } from '../offline/risk-analysis/model';
import { CoverPage } from '../offline/cover-page/model';

export enum SyncIncomingInspectionType {
  Scheduled = 'scheduled',
  Booked = 'booked',
}

export interface SyncIncomingInspection extends Omit<InspectionDetail, 'nonCompliances'> {
  type: SyncIncomingInspectionType;
  grids: Array<SyncIncomingGrid>;
  unmatchedChecks: SyncIncomingUnmatchedChecks | null;
  nonCompliances: Array<SyncIncomingNonCompliance>;
  parcels: Array<SyncIncomingParcel>;
  riskAnalysisGrids: Array<RiskAnalysisGridTemplate>;
}

export interface SyncIncomingCommonGrid {
  type: SyncIncomingFulfillmentType;
  id: string;
  name: string;
  title: string;
  reference: string | null;
  gridType: GridType;
}

export interface SyncIncomingFulfilledGrid extends SyncIncomingCommonGrid {
  type: SyncIncomingFulfillmentType.Fulfilled;
}

export interface SyncIncomingUnFulfilledGrid extends SyncIncomingCommonGrid {
  type: SyncIncomingFulfillmentType.Unfulfilled;
  metas: Array<SyncIncomingGridMeta>;
  metaSections: Array<SyncIncomingGridMetaSection>;
  informationArea: GridInformationArea;
  template: SyncIncomingGridTemplate;
  documents: Array<SyncIncomingGridCheckDocument>;
  checkpoints: Array<SyncIncomingCheckpoint>;
  sections: Array<SyncIncomingSection>;
  mergers: Array<SyncIncomingMerger>;
  aggregates: Array<SyncIncomingAggregate>;
}

export type SyncIncomingGrid = SyncIncomingFulfilledGrid | SyncIncomingUnFulfilledGrid;

export interface SyncIncomingSection {
  id: string;
  name: string;
  order: number;
}

export interface SyncIncomingMerger {
  id: string;
  name: string;
  checkpoints: Array<string> | null;
  aggregates: Array<string> | null;
}

export interface SyncIncomingAggregate {
  id: string;
  selected: string;
  checkpoints: Array<string>;
}

export interface SyncIncomingCheckpoint {
  id: string;
  sectionId: string;
  number: string;
  name: string;
  order: number;
  procedure: InspectionCheckProcedure;
  specification: InspectionCheckSpecification;
  checkScopes: Array<CheckScope>;
  inspectionMethods: Array<InspectionMethod>;
  description: string | null;
  targetValue: string;
  link: SyncIncomingCheckpointLink | null;
  nonCompliances: Array<NonComplianceReferential>;
  inspectionReferenceText: string | null;
}

export interface SyncIncomingCheckpointLink {
  checkpointId: string;
  type: CheckpointLinkType;
}

export enum CheckpointLinkType {
  Complete = 'complete',
  Replace = 'replace',
}

export interface CheckScope {
  id: string;
  code: string;
  label: string;
  invariable: boolean;
}

export enum InspectionMethod {
  D = 'D',
  V = 'V',
  M = 'M',
  E = 'E',
  A = 'A',
  O = 'O',
  ESQS = 'ESQS',
}

export interface SyncIncomingGridMeta {
  type: GridMetaType;
  sectionId: string;
  order: number;
  label: string;
  isCommentable: boolean;
}

export interface SyncIncomingGridMetaSection {
  id: string;
  order: number;
  name: string;
  display: GridMetaDisplay;
}

export enum GridMetaDisplay {
  Header = 'header',
  Footer = 'footer',
}

export interface GridInformationArea {
  beforeMetadataHeader: string | null;
  beforeCheckpoints: string | null;
  beforeMetadataFooter: string | null;
}

export interface SyncIncomingGridTemplate {
  id: string;
  name: string;
  logo: string;
  activityType: string;
  nonComplianceRecordType: NonComplianceType;
  nonComplianceGravities: Array<NonComplianceGravity>;
  largeLabels: boolean;
  coverPage: CoverPage.Code | null;
}

export interface SyncIncomingGridCheckFileDocument {
  type: 'file';
  id: string;
  label: string;
  fileUrl: string;
  fileName: string;
  fileContentType: string;
}

export interface SyncIncomingGridCheckDataDocument {
  type: 'data';
  id: string;
  label: string;
  settings: GridCheckDataDocumentSettings;
}

export interface GridCheckDataDocumentSettings {
  fields: Array<GridCheckDataDocumentField>;
  layout: GridCheckDataDocumentLayout;
  firstValue: GridCheckDataDocumentFirstValue | null;
  maxColumnCount: number | null; // [1, 16]
}

export interface CommonGridCheckDataDocumentField<T extends GridCheckDataDocumentFieldType> {
  type: T;
  id: string;
  label: string;
  order: number;
  weight: number | null;
  isRequired: boolean;
}

export enum GridCheckDataDocumentLayout {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export interface GridCheckDataDocumentFirstValue {
  label: string;
  valueLabel: string;
  weight: number; // [1, 16]
}

export enum GridCheckDataDocumentFieldType {
  Text = 'text',
  Date = 'date',
  Numeric = 'numeric',
  Boolean = 'boolean',
  Enum = 'enum',
  Specification = 'specification',
  Embedded = 'embedded',
}

export type GridCheckDataDocumentTextField = CommonGridCheckDataDocumentField<GridCheckDataDocumentFieldType.Text>;
export type GridCheckDataDocumentDateField = CommonGridCheckDataDocumentField<GridCheckDataDocumentFieldType.Date>;
export type GridCheckDataDocumentNumericField =
  CommonGridCheckDataDocumentField<GridCheckDataDocumentFieldType.Numeric>;
export type GridCheckDataDocumentBooleanField =
  CommonGridCheckDataDocumentField<GridCheckDataDocumentFieldType.Boolean>;
export type GridCheckDataDocumentSpecificationField =
  CommonGridCheckDataDocumentField<GridCheckDataDocumentFieldType.Specification>;
export interface GridCheckDataDocumentEnumField
  extends CommonGridCheckDataDocumentField<GridCheckDataDocumentFieldType.Enum> {
  // Non empty
  values: NEA.NonEmptyArray<string>;
}

export type GridCheckDataDocumentValueField =
  | GridCheckDataDocumentTextField
  | GridCheckDataDocumentDateField
  | GridCheckDataDocumentNumericField
  | GridCheckDataDocumentBooleanField
  | GridCheckDataDocumentSpecificationField
  | GridCheckDataDocumentEnumField;

export interface GridCheckDataDocumentEmbedField {
  type: GridCheckDataDocumentFieldType.Embedded;
  label: string;
  field: GridCheckDataDocumentValueField;
}

export type GridCheckDataDocumentField = GridCheckDataDocumentValueField | GridCheckDataDocumentEmbedField;

export type SyncIncomingGridCheckDocument = SyncIncomingGridCheckFileDocument | SyncIncomingGridCheckDataDocument;

export interface SyncIncomingUnmatchedChecks {
  type: SyncIncomingFulfillmentType;
  checkIds: Array<string>;
}

export enum SyncIncomingFulfillmentType {
  Fulfilled = 'fulfilled',
  Unfulfilled = 'unfulfilled',
}

export interface PendingSyncIgnoredGrid {
  id: string;
  state: OfflineGridState;
  payload: Omit<OfflinePendingGrid, 'id' | 'state'>;
}

export interface PendingSyncPayload {
  inspections: Array<PendingSyncInspection>;
  ignoredGrids: NEA.NonEmptyArray<PendingSyncIgnoredGrid> | null;
}

export interface PendingSyncInspection {
  id: string;
  operatorEmail: string | null;
  grids: Array<PendingSyncGrid>;
  unmatchedChecks: PendingSyncUnmatchedChecks | null;
  nonCompliances: Array<PendingSyncNonCompliance>;
  production: InspectionProduction;
  parcels: Array<PendingSyncParcel>;
  isUnannounced: boolean;
  riskAnalysisGrids: Record<
    RiskAnalysisGridTemplate.Code,
    Array<RiskAnalysisGridTemplate.Criterion.PendingSync>
  > | null;
  cci: NEA.NonEmptyArray<string> | null;
}

export interface PendingSyncGrid {
  id: string;
  checkIds: Array<string>;
  checkScopes: Array<string>;
  headerMetas: Array<PendingSyncGridMeta>;
  checkpoints: Array<GridCheckpoint>;
  footerMetas: Array<PendingSyncGridMeta>;
  conclusion: PendingSyncConclusion;
  attachedFiles: Array<AttachedFile>;
  coverPage: CoverPage | null;
  gridType: GridType;
  largeLabels: boolean;
}

export interface PendingSyncUnmatchedChecks {
  checkIds: Array<string>;
}

export enum GridMetaType {
  Numeric = 'numeric',
  Text = 'text',
  Boolean = 'boolean',
  Date = 'date',
}

export interface PendingSyncBaseMeta {
  label: string;
  comment: string | null;
  section: string;
}

export interface PendingSyncNumericMeta extends PendingSyncBaseMeta {
  type: GridMetaType.Numeric;
  value: number;
}

export interface PendingSyncTextMeta extends PendingSyncBaseMeta {
  type: GridMetaType.Text;
  value: string;
}

export interface PendingSyncBooleanMeta extends PendingSyncBaseMeta {
  type: GridMetaType.Boolean;
  value: boolean;
}

export interface PendingSyncDateMeta extends PendingSyncBaseMeta {
  type: GridMetaType.Date;
  value: string;
}

export type PendingSyncGridMeta =
  | PendingSyncNumericMeta
  | PendingSyncTextMeta
  | PendingSyncBooleanMeta
  | PendingSyncDateMeta;

export enum GridCheckpointValue {
  Inapplicable = 'NA',
  Compliant = 'CF',
  Failure = 'MQT',
}

export interface GridCheckpoint {
  id: string;
  value: GridCheckpointValue;
  comment: string | null;
}

export interface AppendixAttachedFile {
  type: 'appendix';
  name: string;
  comment: string | null;
  fileId: string;
}

export interface DocumentAttachedFile {
  type: 'document';
  documentId: string;
  comment: string | null;
  fileId: string | null;
  reason: string | null;
}

export interface DataDocumentAttachedFile {
  type: 'data-document';
  id: string;
  comment: string | null;
  name: string;
  completed: DataDocumentCompleted | null;
  reason: string | null;
}

export interface DataDocumentCompleted {
  settings: GridCheckDataDocumentSettings;
  values: Array<DataDocumentCompletedValues>;
}

export interface DataDocumentCompletedValues {
  order: number;
  values: Record<string, DataDocumentCompletedValue>;
}

export type DataDocumentCompletedValue =
  | DataCompletedValueDate
  | DataCompletedValueNumeric
  | DataCompletedValueBoolean
  | DataCompletedValueText
  | DataCompletedValueEnum
  | DataCompletedValueSpecification;

export interface DataCompletedValueDate {
  type: GridCheckDataDocumentFieldType.Date;
  value: string;
}

export interface DataCompletedValueNumeric {
  type: GridCheckDataDocumentFieldType.Numeric;
  value: number;
}

export interface DataCompletedValueBoolean {
  type: GridCheckDataDocumentFieldType.Boolean;
  value: boolean;
}

export interface DataCompletedValueText {
  type: GridCheckDataDocumentFieldType.Text;
  value: string;
}

export interface DataCompletedValueEnum {
  type: GridCheckDataDocumentFieldType.Enum;
  value: string;
}

export interface DataCompletedValueSpecification {
  type: GridCheckDataDocumentFieldType.Specification;
  value: string;
}

export type AttachedFile = AppendixAttachedFile | DocumentAttachedFile | DataDocumentAttachedFile;

export interface SyncIncomingNonCompliance {
  id: string;
  checkIds: Array<string>;
  code: string;
  nonCompliance: NonComplianceReferential;
  specification: SpecificationReferential;
  operatorType: OperatorType;
  type: NonComplianceType;
  gravity: NonComplianceGravity;
  firstReportDate: string;
  lastReportDate: string;
  implementationDate: string | null;
  description: string;
  impact: string | null;
  correctiveAction: string | null;
  treatment: string | null;
  comment: string | null;
}

export enum NonComplianceType {
  ANO = 'ANO',
  MQT = 'MQT',
  NCF = 'NCF',
  ANUL = 'ANUL',
  NCI = 'NCI',
  NCFM = 'NCFM',
  PAE = 'PAE',
  RECLA = 'RECLA',
  POS = 'POS',
}

export const nonComplianceTypeLabel: Record<NonComplianceType, string> = {
  [NonComplianceType.ANO]: 'anomalie',
  [NonComplianceType.MQT]: 'manquement',
  [NonComplianceType.NCF]: 'non conformité',
  [NonComplianceType.ANUL]: 'non conformité annulée',
  [NonComplianceType.NCI]: 'non conformité interne',
  [NonComplianceType.NCFM]: 'non conformité mineure en organoleptique',
  [NonComplianceType.PAE]: 'point à éclaircir AB',
  [NonComplianceType.RECLA]: 'réclamation client',
  [NonComplianceType.POS]: "résultat d'analyse positive",
};

export enum NonComplianceGravity {
  Gravity0 = 0,
  Gravity1 = 1,
  Gravity2 = 2,
  Gravity3 = 3,
  Gravity4 = 4,
  Gravity5 = 5,
  Gravity6 = 6,
}

export const nonComplianceGravityLabel: Record<NonComplianceGravity, string> = {
  [NonComplianceGravity.Gravity0]: 'Non prévu dans le plan de controle',
  [NonComplianceGravity.Gravity1]: 'Mineur',
  [NonComplianceGravity.Gravity2]: 'Majeur',
  [NonComplianceGravity.Gravity3]: 'Grave',
  [NonComplianceGravity.Gravity4]: 'Mineur',
  [NonComplianceGravity.Gravity5]: 'Majeur',
  [NonComplianceGravity.Gravity6]: 'Critique',
};

export enum NonComplianceActionType {
  Closed = 'closed',
  Renewed = 'renewed',
  Unverifiable = 'unverifiable',
}

export const nonComplianceActionTypeLabel: EnumLabels<NonComplianceActionType> = {
  [NonComplianceActionType.Closed]: 'Corrigé(e)',
  [NonComplianceActionType.Renewed]: 'Reconduit(e)',
  [NonComplianceActionType.Unverifiable]: 'Non vérifié(e)',
};

export const nonComplianceActionTypeStateLabel: EnumLabels<NonComplianceActionType> = {
  [NonComplianceActionType.Closed]: 'Corrigé(e)',
  [NonComplianceActionType.Renewed]: 'Reconduit(e)',
  [NonComplianceActionType.Unverifiable]: 'Non vérifié(e)',
};

export interface PendingSyncNewNonCompliance {
  type: 'new';
  checkIds: Array<string>;
  codeId: string;
  recordType: NonComplianceType;
  gravity: NonComplianceGravity;
  description: string;
  implementationDate: string | null;
  impact: string | null;
  correctiveAction: string | null;
  comment: string | null;
  requirement: string | null;
}

export interface PendingSyncExistingNonCompliance {
  type: 'existing';
  id: string;
  recordType: NonComplianceActionType;
  note: string | null;
}

export type PendingSyncNonCompliance = PendingSyncNewNonCompliance | PendingSyncExistingNonCompliance;

export enum InspectionCheckResult {
  Compliant = 'compliant',
  NonCompliant = 'non-compliant',
}

export interface PendingSyncConclusion {
  startTime: string;
  endTime: string;
  result: InspectionCheckResult;
  comment: string;
  metPeople: string | null;
  accompanyingPeople: string | null;
  auditorSignature: Signature;
  operatorSignature: Signature;
  operatorEmail: string | null;
  cci: NEA.NonEmptyArray<string> | null;
}

export interface Signature {
  firstName: string | null;
  lastName: string | null;
  fileId: string | null;
}

export interface SyncIncomingParcel {
  template: ParcelTemplate;
  lines: Array<ParcelDataLine>;
}

export interface ParcelTemplate {
  code: string;
  label: string;
  fields: Array<ParcelTemplateField>;
  total: ParcelTemplateTotal | null;
}

export enum ParcelTemplateFieldType {
  Text = 'text',
  Number = 'number',
  Decimal = 'decimal',
  Boolean = 'boolean',
  Date = 'date',
  Enum = 'enum',
  BioProduct = 'bio-product',
}

export interface DefaultParcelTemplateField<T extends ParcelTemplateFieldType> {
  type: T;
  code: string;
  label: string;
  required: boolean;
  isComment: boolean;
}

export type ParcelTemplateFieldPrimitive = DefaultParcelTemplateField<
  Exclude<ParcelTemplateFieldType, ParcelTemplateFieldType.Enum>
>;

export interface ParcelTemplateFieldEnum extends DefaultParcelTemplateField<ParcelTemplateFieldType.Enum> {
  values: Array<ParcelTemplateFieldEnumEntry>;
}

export type ParcelTemplateField = ParcelTemplateFieldPrimitive | ParcelTemplateFieldEnum;

export interface ParcelTemplateFieldEnumEntry {
  key: string;
  label: string;
  default: boolean;
}

export interface ParcelTemplateTotal {
  unit: string; // -> ParcelTemplateField.code
  groupBy: string; // -> ParcelTemplateField.code
}

export interface ParcelDataLine {
  id: string;
  rows: ParcelDataLineRows;
}

export type ParcelDataLineValue = string | number | boolean | null | undefined;

export type ParcelDataLineRows = Record<string, ParcelDataLineRowsValue>;

export type ParcelDataLineRowsValue =
  | ParcelDataLineRowsText
  | ParcelDataLineRowsNumber
  | ParcelDataLineRowsDecimal
  | ParcelDataLineRowsBool
  | ParcelDataLineRowsDate
  | ParcelDataLineRowsEnum
  | ParcelDataLineRowsBioProduct;

export interface ParcelDataLineRowsText {
  type: ParcelTemplateFieldType.Text;
  value: string | null | undefined;
}

export interface ParcelDataLineRowsNumber {
  type: ParcelTemplateFieldType.Number;
  value: number | null | undefined;
}

export interface ParcelDataLineRowsDecimal {
  type: ParcelTemplateFieldType.Decimal;
  value: number | null | undefined;
}

export interface ParcelDataLineRowsBool {
  type: ParcelTemplateFieldType.Boolean;
  value: boolean;
}

export interface ParcelDataLineRowsDate {
  type: ParcelTemplateFieldType.Date;
  value: string | null | undefined;
}

export interface ParcelDataLineRowsEnum {
  type: ParcelTemplateFieldType.Enum;
  value: string | null | undefined;
}

export interface ParcelDataLineRowsBioProduct {
  type: ParcelTemplateFieldType.BioProduct;
  value: string | null | undefined;
}

export interface PendingSyncParcel {
  template: string;
  lines: Array<PendingSyncParcelLine>;
}

export enum PendingSyncParcelLineType {
  Existing = 'existing',
  New = 'new',
}

export interface PendingSyncNewParcelLine {
  type: PendingSyncParcelLineType.New;
  rows: ParcelDataLineRows;
}

export interface PendingSyncExistingParcelLine {
  id: string;
  type: PendingSyncParcelLineType.Existing;
  rows: ParcelDataLineRows;
  deleted: boolean;
  updated: boolean;
}

export type PendingSyncParcelLine = PendingSyncNewParcelLine | PendingSyncExistingParcelLine;

export enum SyncSuccessResultType {
  Complete = 'complete',
  Loss = 'loss',
}
