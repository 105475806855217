import * as Sentry from '@sentry/react';
import { HttpError, HttpStatusCode } from '@core/http';

export function logSentryMessage(
  message: string,
  severity: Sentry.SeverityLevel = 'error',
  extras?: Record<string, any>,
) {
  Sentry.captureMessage(message, {
    level: severity,
    extra: extras,
  });
}

export function logSentryHttpError(
  message: string,
  error: HttpError,
  severity: Sentry.SeverityLevel = 'error',
  extras?: Record<string, any>,
) {
  logSentryMessage(message, error.status === HttpStatusCode.NETWORK_NOT_REACHABLE ? 'warning' : severity, {
    ...error.toJson(),
    ...extras,
  });
}
