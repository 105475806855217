import { getStorageService } from '@core/storage';
import { OfflineFile } from '@shared/modules/files';

import * as T from 'fp-ts/Task';
import * as TO from 'fp-ts/TaskOption';
import { pipe } from 'fp-ts/function';

const documentStorage = getStorageService('documents');

export function addFile(file: OfflineFile): T.Task<OfflineFile> {
  return documentStorage.setItem(file.id, file);
}

export function getFile(id: string): TO.TaskOption<OfflineFile> {
  return documentStorage.getItem<OfflineFile>(id);
}

export function getFileAsDataUrl(id: string): TO.TaskOption<string> {
  return pipe(
    getFile(id),
    TO.map(file => URL.createObjectURL(file.content)),
  );
}

export function deleteFile(id: string): T.Task<void> {
  return documentStorage.removeItem(id);
}

export function clearFiles(): T.Task<void> {
  return documentStorage.clear();
}
