import { format, isValid, parse, set } from 'date-fns';
import fr from 'date-fns/locale/fr';

import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

export function formatDate(date: Date | number, formatStr = 'PP'): string {
  return format(date, formatStr, {
    locale: fr,
  });
}

export function removeTime(date: Date | number): Date {
  return set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
}

export function parseDate(date: string | null | undefined, format: string = 'dd/MM/yyyy'): O.Option<Date> {
  return pipe(
    O.fromNullable(date),
    O.filter(d => d !== ''),
    O.map(d => parse(d, format, new Date())),
    O.filter(d => isValid(d)),
  );
}

export function formatDefaultDate(date: Date | number): string {
  return formatDate(date, 'dd/MM/yyyy');
}
