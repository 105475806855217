import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Profile } from '@modules/auth/model';

import * as Styled from './HeaderUser.styles';

import userIcon from '@assets/icons/nav/user.svg';
import userDarkIcon from '@assets/icons/nav/user-dark.svg';
import { Paragraph } from '@root/styles';
import { useAppMode } from '@modules/app/hooks';
import { AppMode } from '@modules/app/store/model';
import { useAuthContext } from '@modules/auth/hooks';

interface HeaderUserProps {
  profile: Profile;
  light?: boolean;
}

const HeaderUser: FC<HeaderUserProps> = ({ profile, light }) => {
  const { mode } = useAppMode();

  const { logout } = useAuthContext();

  const menuRef = useRef<HTMLDivElement | null>(null);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const openMenu = useCallback(() => setMenuOpen(true), []);
  const closeMenu = useCallback(() => setMenuOpen(false), []);

  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (menuRef.current && e.target instanceof Element && !menuRef.current.contains(e.target)) {
        closeMenu();
      }
    },
    [menuRef, closeMenu],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeMenu, handleClickOutside]);

  return (
    <>
      <Styled.HeaderUserContainer onClick={openMenu} mode={mode}>
        <img width={32} src={light ? userDarkIcon : userIcon} alt="user" />
        <Styled.HeaderUserInformation $light={light}>
          <Paragraph $weight="semiBold">{profile.firstName}</Paragraph>
          <Paragraph size="small">{profile.lastName}</Paragraph>
        </Styled.HeaderUserInformation>
      </Styled.HeaderUserContainer>
      {AppMode.Online === mode && (
        <Styled.LogoutMenuContainer ref={menuRef} open={menuOpen}>
          <Styled.LogoutMenuButton onClick={logout}>Se déconnecter</Styled.LogoutMenuButton>
        </Styled.LogoutMenuContainer>
      )}
    </>
  );
};

export default HeaderUser;
