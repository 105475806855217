import { ActionWithPayload, ThunkResult } from '@root/store';
import { ConfigState } from './model';
import config from '@root/config';
import { Config } from '@config/schema';

export enum ConfigActionsType {
  UPDATE_CONFIG = 'CONFIG/UPDATE',
}

export type ConfigAction = ActionWithPayload<ConfigActionsType.UPDATE_CONFIG, ConfigState>;

function transformConfig(config: Config): ConfigState {
  return {
    environment: config.VITE_ENVIRONMENT,
    release: config.VITE_RELEASE,
    googleMapsKey: config.VITE_GOOGLE_MAPS_KEY,
    sentryDsn: config.VITE_SENTRY_DSN,
  };
}

export function syncConfig(): ThunkResult<void, ConfigAction> {
  return dispatch =>
    dispatch({
      type: ConfigActionsType.UPDATE_CONFIG,
      payload: transformConfig(config),
    });
}
