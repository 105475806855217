import { combineReducers, Reducer } from 'redux';
import { OfflineState } from '../model';
import getOfflineIncomingReducer from './incoming';
import getOfflinePendingReducer from './pending';
import getOfflineReferentialReducer from './referential';
import { Storage } from 'redux-persist/es/types';

function getOfflineReducer(storage: Storage): Reducer<OfflineState, any> {
  return combineReducers<OfflineState>({
    incoming: getOfflineIncomingReducer(storage),
    pending: getOfflinePendingReducer(storage),
    referential: getOfflineReferentialReducer(storage),
  });
}

export default getOfflineReducer;
