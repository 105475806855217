import {
  OfflinePendingExistingNonCompliance,
  OfflinePendingNewNonCompliance,
  OfflinePendingNonCompliance,
} from '../model';

import * as A from 'fp-ts/Array';
import * as S from 'fp-ts/string';
import { pipe } from 'fp-ts/function';

export function isNewNonCompliance(
  nonCompliance: OfflinePendingNonCompliance,
): nonCompliance is OfflinePendingNewNonCompliance {
  return nonCompliance.type === 'new';
}

export function isExistingNonCompliance(
  nonCompliance: OfflinePendingNonCompliance,
): nonCompliance is OfflinePendingExistingNonCompliance {
  return nonCompliance.type === 'existing';
}

export function getGridNewNonCompliances(
  nonCompliances: Array<OfflinePendingNonCompliance>,
  gridId: string,
): Array<OfflinePendingNewNonCompliance> {
  return pipe(
    nonCompliances,
    A.filter(isNewNonCompliance),
    A.filter(nonCompliance => nonCompliance.gridId === gridId),
  );
}

export function getGridExistingNonCompliances(
  nonCompliances: Array<OfflinePendingNonCompliance>,
  checkIds: Array<string>,
): Array<OfflinePendingExistingNonCompliance> {
  return pipe(
    nonCompliances,
    A.filter(isExistingNonCompliance),
    A.filter(nonCompliance => pipe(A.intersection(S.Eq)(nonCompliance.checkIds, checkIds), A.isNonEmpty)),
  );
}
