import {
  SyncIncomingFulfilledGrid,
  SyncIncomingFulfillmentType,
  SyncIncomingGrid,
  SyncIncomingGridCheckDocument,
  SyncIncomingGridCheckFileDocument,
  SyncIncomingUnFulfilledGrid,
} from '../../../sync/model';
import {
  OfflinePendingGridCheckDataDocument,
  OfflinePendingGridCheckDocument,
  OfflinePendingGridCheckFileDocument,
} from '../model';

export function isUnfulfilledGrid(grid: SyncIncomingGrid): grid is SyncIncomingUnFulfilledGrid {
  return SyncIncomingFulfillmentType.Unfulfilled === grid.type;
}

export function isFulfilledGrid(grid: SyncIncomingGrid): grid is SyncIncomingFulfilledGrid {
  return SyncIncomingFulfillmentType.Fulfilled === grid.type;
}

export function isSyncIncomingGridCheckFileDocument(
  document: SyncIncomingGridCheckDocument,
): document is SyncIncomingGridCheckFileDocument {
  return document.type === 'file';
}

export function isOfflinePendingGridCheckFileDocument(
  document: OfflinePendingGridCheckDocument,
): document is OfflinePendingGridCheckFileDocument {
  return document.type === 'file';
}

export function isOfflinePendingGridCheckDataDocument(
  document: OfflinePendingGridCheckDocument,
): document is OfflinePendingGridCheckDataDocument {
  return document.type === 'data';
}
