import styled, { css, FlattenInterpolation } from 'styled-components';
import { fontSize, fontWeight, textColor } from '../utils';

export type TitlesLevels = 'h1' | 'h2' | 'h3' | 'h4';

export const titlesStyles: { [key in TitlesLevels]: FlattenInterpolation } = {
  h1: css`
    font-size: ${fontSize.h1};
    font-weight: ${fontWeight.semiBold};
    line-height: 1.25;
  `,
  h2: css`
    font-size: ${fontSize.h2};
    font-weight: ${fontWeight.semiBold};
    line-height: 1.67;
  `,
  h3: css`
    font-size: ${fontSize.button};
    font-weight: ${fontWeight.semiBold};
    line-height: 1.67;
  `,
  h4: css`
    font-size: ${fontSize.medium};
    font-weight: ${fontWeight.bold};
    line-height: 1.43;
  `,
};

interface TitleProps {
  $level?: TitlesLevels;
  $inverted?: boolean;
}

export const Title = styled.h1.attrs<TitleProps, Required<TitleProps>>(props => ({
  $level: props.$level ?? 'h1',
  $inverted: !!props.$inverted,
}))`
  ${({ $level }) => $level && titlesStyles[$level]}
  ${({ $inverted }) =>
    $inverted &&
    css`
      color: ${textColor.inverted};
    `};
`;
