import { z } from 'zod';

export enum Environment {
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export const configSchema = z.object({
  VITE_GOOGLE_MAPS_KEY: z.string(),
  VITE_ENVIRONMENT: z.nativeEnum(Environment).catch(Environment.Development),
  VITE_RELEASE: z.string().nullable().catch(null),
  VITE_SENTRY_DSN: z.string().url().nullable().catch(null),
});

export type Config = z.infer<typeof configSchema>;
