import styled, { css } from 'styled-components';
import { alertColor, fontSize, secondaryColor, tertiaryColor, textColor } from '../../utils';

interface InputProps {
  $error?: boolean;
}

export const Input = styled.input.attrs(props => ({
  type: props.type ?? 'text',
}))<InputProps>`
  display: block;
  height: 45px;
  width: 100%;
  padding: 0 20px;
  border: 1px solid ${tertiaryColor(300)};
  border-radius: 100px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: ${fontSize.medium};
  color: ${textColor.default};
  transition: all 0.1s linear;

  &::placeholder {
    font-style: italic;
    color: rgba(57, 53, 50, 0.5);
  }

  &:focus {
    outline: none;
    border-color: ${secondaryColor()};
    background-color: rgba(31, 199, 45, 0.1);
  }

  ${props =>
    props.$error &&
    css`
      border-color: ${alertColor};
    `};
`;

export const TextArea = styled.textarea<InputProps>`
  display: block;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 140px;
  padding: 15px 20px;
  border: 1px solid ${tertiaryColor(300)};
  border-radius: 20px;
  box-sizing: border-box;
  font-family: inherit;
  font-size: ${fontSize.medium};
  color: ${textColor.default};
  transition: all 0.1s linear;

  &::placeholder {
    font-style: italic;
    color: rgba(57, 53, 50, 0.5);
  }

  &:focus {
    outline: none;
    border-color: ${secondaryColor()};
    background-color: rgba(31, 199, 45, 0.1);
  }

  ${props =>
    props.$error &&
    css`
      border-color: ${alertColor};
    `};
`;

export const TextAreaContainer = styled.div`
  border-radius: 20px;
  overflow: hidden;
`;
