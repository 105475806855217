import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

export const camelToSnake = (value: string): string => {
  return value.replace(/[\w]([A-Z])/g, m => m[0] + '_' + m[1]).toLowerCase();
};

export const filterEmptyStringToOption = (value: string | null | undefined): O.Option<string> => {
  return pipe(
    O.fromNullable(value),
    O.filter(v => v !== ''),
  );
};

export const filterEmptyStringToNullable = (value: string | null | undefined): string | null => {
  return O.toNullable(filterEmptyStringToOption(value));
};

export function removeEmptyString<T extends unknown>(value: T): T {
  if (value === '') {
    return null as any;
  } else if (Array.isArray(value)) {
    return value.map(item => removeEmptyString(item)) as any;
  } else if (value && typeof value === 'object') {
    return Object.keys(value as any).reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: removeEmptyString((value as any)[curr]),
      }),
      {},
    ) as any;
  }

  return value;
}
