export const STORAGE_TOKEN_KEY = 'tokens';

export interface Profile {
  email: string;
  firstName: string;
  lastName: string;
}

export interface AuthenticateParams {
  email: string;
  password: string;
}

export interface Token {
  token: string;
  refreshToken: string | null;
}

export interface ForgotPasswordParams {
  email: string;
}

export interface PasswordParams {
  password: string;
}

export interface PasswordFormBody {
  password: string;
  confirmPassword: string;
}
