import React, { FC, useMemo } from 'react';
import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { Helmet } from 'react-helmet';

const TITLE_PREFIX = 'Qualisud';

interface MetaTitleProps {
  title?: string;
}

const MetaTitle: FC<MetaTitleProps> = ({ title }) => {
  const metaTitle = useMemo(
    () =>
      pipe(
        O.fromNullable(title),
        O.fold(
          () => TITLE_PREFIX,
          t => `${TITLE_PREFIX} - ${t}`,
        ),
      ),
    [title],
  );

  return (
    <Helmet>
      <title>{metaTitle}</title>
    </Helmet>
  );
};

export default MetaTitle;
