import { ActionWithPayload } from '../../../../store';

export enum RichTextActionsType {
  UPDATE_CLIPBOARD_DATA = 'RICH_TEXT/UPDATE_CLIPBOARD_DATA',
}

export type RichTextActions = ActionWithPayload<
  RichTextActionsType.UPDATE_CLIPBOARD_DATA,
  string | Array<string | null> | null
>;

export function updateRichTextClipboardData(data: string | null): RichTextActions {
  return {
    type: RichTextActionsType.UPDATE_CLIPBOARD_DATA,
    payload: data,
  };
}

export function updateMultipleRichTextClipboardData(data: Array<string | null>): RichTextActions {
  return {
    type: RichTextActionsType.UPDATE_CLIPBOARD_DATA,
    payload: data.map(d => d),
  };
}
