import { Action, ActionWithPayload, ThunkResult } from '@root/store';
import { HttpError, HttpStatusCode, HttpTask } from '@core/http';
import { PendingSyncPayload, SyncIncomingFulfillmentType, SyncIncomingInspection } from '../model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';
import * as EI from 'fp-ts/Either';
import * as S from 'fp-ts/string';
import * as TE from 'fp-ts/TaskEither';
import * as IOE from 'fp-ts/IOEither';
import * as IO from 'fp-ts/IO';
import { minDuration } from '@shared/utils/fp';
import * as SyncService from '../service';
import * as ReferentialService from '../../referential/service';
import * as FileCacheService from '../file-cache';
import { clearOfflineStorage, syncIncomingSuccess } from '../../offline/store/actions';
import { changeAppMode } from '../../app/store/actions';
import { AppMode } from '../../app/store/model';
import { mapToPendingSyncPayload } from '../mapping/outgoing';
import { getDocumentsIdsToSync } from '../mapping/outgoing/documents';
import { history } from '@root/routes';
import { logSentryHttpError, logSentryMessage } from '@shared/utils/sentry';
import { formatDate } from '@shared/utils/date';
import { sequenceS } from 'fp-ts/Apply';

export enum SyncActionType {
  RESET_STATE = 'SYNC/RESET_STATE',
  UPDATE_DATE = 'SYNC/UPDATE_DATE',
  UPDATE_MESSAGE = 'SYNC/UPDATE_MESSAGE',
  UPDATE_ERROR = 'SYNC/UPDATE_ERROR',
  UPDATE_PENDING_ID = 'SYNC/UPDATE_PENDING_ID',
}

export type SyncAction =
  | Action<SyncActionType.RESET_STATE>
  | ActionWithPayload<SyncActionType.UPDATE_DATE, string | null>
  | ActionWithPayload<SyncActionType.UPDATE_MESSAGE, string | null>
  | ActionWithPayload<SyncActionType.UPDATE_ERROR, HttpError | null>
  | ActionWithPayload<SyncActionType.UPDATE_PENDING_ID, string | null>;

function resetState(): SyncAction {
  return {
    type: SyncActionType.RESET_STATE,
  };
}

function updateDate(date: string | null): SyncAction {
  return {
    type: SyncActionType.UPDATE_DATE,
    payload: date,
  };
}

function updateMessage(message: string | null): SyncAction {
  return {
    type: SyncActionType.UPDATE_MESSAGE,
    payload: message,
  };
}

function updateError(error: HttpError | null): SyncAction {
  return {
    type: SyncActionType.UPDATE_ERROR,
    payload: error,
  };
}

export function updatePendingId(pendingId: string | null): SyncAction {
  return {
    type: SyncActionType.UPDATE_PENDING_ID,
    payload: pendingId,
  };
}

function cacheDocuments(inspections: Array<SyncIncomingInspection>): HttpTask<void> {
  const inspectionsDocuments = pipe(
    inspections,
    A.chain(inspection => inspection.documents),
    A.filterMap(document => O.fromNullable(document.url)),
  );

  const unFulfilledGrids = pipe(
    inspections,
    A.chain(inspection => inspection.grids),
    A.filterMap(grid => (grid.type === SyncIncomingFulfillmentType.Unfulfilled ? O.some(grid) : O.none)),
  );

  const gridDocuments = pipe(
    unFulfilledGrids,
    A.chain(grid => grid.documents),
    A.filterMap(document => (document.type === 'file' ? O.some(document) : O.none)),
    A.map(document => document.fileUrl),
  );

  const documentsToCache = pipe([...inspectionsDocuments, ...gridDocuments], A.uniq(S.Eq));

  return TE.rightTask(FileCacheService.syncFiles(documentsToCache));
}

export function startIncomingSync(date: Date): ThunkResult<Promise<void>, any> {
  return dispatch => {
    dispatch(resetState());

    const dispatchIO = <T>(action: T) => () => dispatch(action);
    const rightDispatch = <L, R>(action: R) => TE.rightIO<L, R>(dispatchIO(action));

    const syncTask = pipe(
      TE.fromIO(rightDispatch(updateDate(formatDate(date, 'dd-MM-yyyy')))),
      TE.tapIO(() => rightDispatch(updateMessage('Synchronisation des données'))),
      TE.bind('inspections', () => minDuration(SyncService.syncIncomingInspections(date), 500)),
      TE.bind('referential', () => minDuration(ReferentialService.getOfflineReferential(), 500)),
      TE.tapIO(() => rightDispatch(updateMessage('Synchronisation des documents'))),
      TE.tap(({ inspections }) => minDuration(cacheDocuments(inspections), 1000)),
      TE.tapIO(({ inspections, referential }) => rightDispatch(syncIncomingSuccess(date, inspections, referential))),
      TE.tapIO(() => rightDispatch(changeAppMode(AppMode.Offline))),
      TE.tapIO(() => rightDispatch(resetState())),
    );

    return pipe(
      syncTask,
      TE.orElseFirstIOK(err => () => {
        logSentryHttpError('Error during incoming sync', err);
        dispatch(updateError(err));
      }),
      T.asUnit,
    )();
  };
}

export function startOutgoingSync(isReSync: boolean): ThunkResult<Promise<void>, any> {
  return (dispatch, getState) => {
    dispatch(updateError(null));
    dispatch(updateMessage('Synchronisation des données'));

    const dispatchIO = <T>(action: T) => () => dispatch(action);
    const rightDispatch = <L, R>(action: R) => TE.rightIO<L, R>(dispatchIO(action));
    const leftDispatch = <L, R>(action: L) => TE.leftIO<L, R>(dispatchIO(action));

    const sendDataTask = (oldId: string | null, payload: PendingSyncPayload): HttpTask<EI.Either<string, string>> =>
      pipe(
        O.fromNullable(oldId),
        O.fold(
          () =>
            pipe(
              minDuration(SyncService.syncOutgoing(payload), 1000),
              TE.map(({ id }) => EI.right(id)),
              TE.orElse(err =>
                pipe(
                  err,
                  O.fromPredicate(err => HttpStatusCode.UNPROCESSABLE_ENTITY === err.status),
                  O.chain(err => err.data),
                  O.chain(data => (data && typeof data.code === 'string' ? O.some(data.code) : O.none)),
                  O.fold(
                    () => TE.left(err),
                    code => {
                      logSentryMessage('Outgoing sync error unprocessable error', 'error', {
                        code,
                        ...err.toJson(),
                      });

                      return TE.right(EI.left(code));
                    },
                  ),
                ),
              ),
            ),
          id => TE.right(EI.right(id)),
        ),
      );

    const sendDocumentTask = (id: string, index: number, total: number) =>
      pipe(
        TE.rightIO(rightDispatch(updateMessage(`Envoi du document ${index + 1}/${total}`))),
        TE.chain(() => SyncService.sendOutgoingFileFromId(id)),
      );

    const syncTask = pipe(
      sequenceS(IO.Apply)({
        oldId: () => getState().sync.pendingId,
        payload: () => mapToPendingSyncPayload(getState().offline.pending.inspections),
      }),
      TE.fromIO,
      TE.bind('syncResult', ({ oldId, payload }) => sendDataTask(oldId, payload)),
      TE.tapIO(({ syncResult }) =>
        pipe(
          IOE.fromEither(syncResult),
          IOE.chainIOK(pendingId => rightDispatch(updatePendingId(pendingId))),
        ),
      ),
      TE.tap(({ payload }) =>
        A.sequence(TE.ApplicativeSeq)(
          getDocumentsIdsToSync(payload).map((id, index, list) => sendDocumentTask(id, index, list.length)),
        ),
      ),
      TE.tap(({ syncResult }) =>
        pipe(
          syncResult,
          EI.fold(
            errorCode => TE.rightIO(() => history.replace(`/sync/outgoing/error/${errorCode}`)),
            pendingId => SyncService.finishOutgoingSync(pendingId, !isReSync),
          ),
        ),
      ),
      TE.tapIO(() => rightDispatch(clearOfflineStorage())),
      TE.tapIO(() => rightDispatch(changeAppMode(AppMode.Online))),
      TE.tapIO(() => rightDispatch(resetState())),
    );

    return pipe(
      syncTask,
      TE.orElseFirstIOK(err => () => logSentryHttpError('Error during outgoing sync', err)),
      TE.orElse(err => leftDispatch(updateError(err))),
      T.asUnit,
    )();
  };
}
