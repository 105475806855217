import styled from 'styled-components';
import { backgroundColor, contentSize, tertiaryColor } from '../../styles/utils';

import illustration from '../../assets/images/lock/illustration.png';

export const ScreenLockContainer = styled.div`
  display: none;
  height: 100%;
  background-color: #feeada;
  overflow-y: auto;

  @media screen and (max-width: ${props => contentSize(props) - 1}px) {
    display: block;
  }
`;

export const ScreenLockContent = styled.div`
  position: relative;
  padding: 160px 35px 500px;
  background: url(${illustration}) no-repeat right bottom;
  background-size: 585px 1194px;

  @media screen and (max-width: 700px) {
    padding: 80px 35px 500px;
  }
`;

export const ScreenLockCard = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 670px;
  padding: 60px 85px;
  background-color: ${backgroundColor.default};
  border-radius: 10px;
  z-index: 1;
  text-align: center;

  > h1 {
    padding-top: 35px;
  }

  > p {
    padding-top: 15px;
    color: ${tertiaryColor(700)};
  }

  @media screen and (max-width: 700px) {
    padding: 40px 30px;
  }
`;

export const ScreenLockFooter = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  text-align: center;

  img {
    position: relative;
    top: 7px;
    left: 3px;
  }
`;
