import { BIOCoverPage } from './bio/model';

export namespace CoverPage {
  export type Pending = BIOCoverPage.Pending;
  export enum Code {
    Bio = 'BIO',
  }

  export namespace Code {
    export const label: Record<Code, string> = {
      [Code.Bio]: 'BIO',
    };
  }
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type CoverPage = BIOCoverPage;
