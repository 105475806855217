import styled from 'styled-components';
import { backgroundColor, tertiaryColor } from '../../styles/utils';
import { Link } from 'react-router-dom';

export const HeaderContainer = styled.header`
  display: flex;
  flex: 0 0 70px;
  background: ${backgroundColor.default};
  border-bottom: 1px solid ${tertiaryColor(300)};
`;

export const HeaderHome = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 125px;
  border-right: 1px solid ${tertiaryColor(300)};

  > img {
    width: 54px;
  }
`;
