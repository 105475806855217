import React, { FC, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';
import { alertColor, fontSize, fontWeight } from '../../utils';

const FormFieldContainer = styled.div<{ $error: boolean }>`
  &:not(:last-child) {
    padding-bottom: 10px;
  }

  > label {
    display: block;
    padding-bottom: 5px;
    font-size: ${fontSize.medium};
    font-weight: ${fontWeight.medium};

    &[for] {
      cursor: pointer;
    }
  }

  ${props =>
    props.$error &&
    css`
      > label {
        color: ${alertColor};
      }
    `};
`;

const FormFieldError = styled.p`
  margin-top: 5px;
  font-size: ${fontSize.small};
  color: ${alertColor};
`;

interface FormFieldProps {
  label: string;
  id?: string;
  error?: boolean | string;
  required?: boolean;
  className?: string;
}

export const FormField: FC<PropsWithChildren<FormFieldProps>> = ({
  label,
  id,
  error,
  required,
  className,
  children,
}) => (
  <FormFieldContainer $error={!!error} className={className}>
    <label htmlFor={id}>
      {label} {required ? ' *' : null}
    </label>
    {children}
    {typeof error === 'string' && <FormFieldError>{error}</FormFieldError>}
  </FormFieldContainer>
);
