import React, { FC } from 'react';

import * as Styled from './ScreenLock.styles';

import { Title, Paragraph } from '../../styles';

import logo from '@assets/logos/logo.svg';
import logoInnovantic from '@assets/logos/innovantic.svg';

export const ScreenLock: FC = () => (
  <Styled.ScreenLockContainer>
    <Styled.ScreenLockContent>
      <Styled.ScreenLockCard>
        <img src={logo} alt="logo" />
        <Title $level="h2">On dirait que vous êtes sur un appareil mobile.</Title>
        <Paragraph>
          Pour une meilleure expérience utilisateur, notre plateforme Auditeur n‘est pas disponible si votre résolution
          d’écran est inférieure à 1280px. Nous vous invitons à vous connecter depuis un ordinateur portable.
        </Paragraph>
      </Styled.ScreenLockCard>
      <Styled.ScreenLockFooter>
        <Paragraph size="small">
          Une solution{' '}
          <a
            href="https://www.innovantic.fr"
            target="_blank"
            rel="noopener noreferrer"
            title="Innovantic, créateur d'expérience numérique"
          >
            <img src={logoInnovantic} alt="Innovantic" />
          </a>{' '}
        </Paragraph>
      </Styled.ScreenLockFooter>
    </Styled.ScreenLockContent>
  </Styled.ScreenLockContainer>
);

export default ScreenLock;
