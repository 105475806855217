import { defaultMultipleRichTextState, defaultRichTextState, RichTextState } from './model';
import { RichTextActions, RichTextActionsType } from './actions';

export function richTextReducer(state = defaultRichTextState, action: RichTextActions): RichTextState {
  switch (action.type) {
    case RichTextActionsType.UPDATE_CLIPBOARD_DATA:
      return {
        ...state,
        clipboardData: action.payload,
      };
    default:
      return state;
  }
}

export function multipleRichTextReducer(
  state = defaultMultipleRichTextState,
  action: RichTextActions,
): Array<RichTextState> {
  switch (action.type) {
    case RichTextActionsType.UPDATE_CLIPBOARD_DATA:
      return state.map(s => ({
        ...s,
        clipboardData: action.payload,
      }));

    default:
      return state;
  }
}
