import styled from 'styled-components';

export const ErrorContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;

  > p {
    padding: 20px 0;
  }
`;
