import { AuthState, defaultAuthState } from './model';
import { AuthAction, AuthActionsType } from './actions';
import { persistReducer } from 'redux-persist';
import { Reducer } from 'redux';
import { Storage } from 'redux-persist/es/types';

function reducer(state = defaultAuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionsType.UPDATE_PROFILE:
      return {
        profile: action.payload,
      };
    case AuthActionsType.REMOVE_PROFILE:
      return {
        profile: null,
      };
    default:
      return state;
  }
}

function getAuthReducer(storage: Storage): Reducer<AuthState, any> {
  return persistReducer(
    {
      key: 'auth',
      version: 1,
      storage,
    },
    reducer,
  ) as any;
}

export default getAuthReducer;
