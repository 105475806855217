import styled from 'styled-components';
import { fontSize } from '../../../../styles/utils';

export const UpdateNotificationModalContainer = styled.div`
  text-align: center;

  > p {
    font-size: ${fontSize.large};
    padding-bottom: 30px;
  }
`;
