import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { HttpError, HttpRange, HttpStatusCode, HttpTask } from './model';

import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { Range } from '@shared/modules/range';
import { identity, Lazy } from 'fp-ts/function';
import { refreshToken } from '@modules/auth/service';
import { history } from '../../routes';
import { axiosInstance } from './config';
import { logSentryHttpError } from '@shared/utils/sentry';
import { removeEmptyString } from '@shared/utils/string';
import { StringifiableRecord } from 'query-string';

const WHITE_LIST_REDIRECT = ['/authenticate', '/profile'];

const isWhiteList = (url: string) => WHITE_LIST_REDIRECT.filter(whiteUrl => url.includes(whiteUrl)).length > 0;

function checkNetworkAvailability<E>(): HttpTask<void, E> {
  return pipe(
    navigator.onLine,
    TE.fromPredicate(identity, () => HttpError.offline as HttpError<E>),
    TE.asUnit,
  );
}

function transformRequest<R, E>(request: Lazy<Promise<AxiosResponse<R>>>): HttpTask<R, E> {
  return pipe(
    checkNetworkAvailability<E>(),
    TE.chain(() => TE.tryCatch(request, err => HttpError.fromAxiosError<E>(err as AxiosError<E>))),
    TE.map(res => res.data),
  );
}

function logHttpError(err: HttpError) {
  err.log();

  if (
    err.status >= 400 &&
    ![
      HttpStatusCode.UNAUTHORIZED,
      HttpStatusCode.FORBIDDEN,
      HttpStatusCode.NOT_FOUND,
      HttpStatusCode.CONFLICT,
    ].includes(err.status)
  ) {
    logSentryHttpError(`[http] error ${err.status} on ${O.getOrElse(() => 'unknown')(err.url)} path`, err);
  }
}

function httpErrorHandler<R, E>(
  originalRequest: Lazy<Promise<AxiosResponse<R>>>,
): (err: HttpError<E>) => HttpTask<R, E> {
  return err => {
    logHttpError(err);

    const urlRefreshAllow = pipe(
      err.url,
      O.exists(u => !u.includes('/authenticate')),
    );

    if (HttpStatusCode.UNAUTHORIZED === err.status && urlRefreshAllow) {
      return pipe(
        refreshToken(),
        TE.chain(() => transformRequest(originalRequest)),
        TE.mapLeft(() => {
          const redirectToLogin = pipe(
            err.url,
            O.exists(url => err.status === HttpStatusCode.UNAUTHORIZED && !isWhiteList(url)),
          );

          if (redirectToLogin) {
            history.push('/login', {
              referrer: history.location.pathname,
            });
          }

          return err;
        }),
      );
    } else if (err.status >= 500) {
      return T.delay(500)(transformRequest(originalRequest));
    } else {
      return TE.left(err);
    }
  };
}

function handleRequest<R, E>(request: Lazy<Promise<AxiosResponse<R>>>): HttpTask<R, E> {
  return pipe(transformRequest<R, E>(request), TE.orElse(httpErrorHandler(request)));
}

function get<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E> {
  return handleRequest(() => axiosInstance.get(url, config));
}

function getRange<R = unknown, F extends StringifiableRecord = {}, E = unknown>(
  url: string,
  cursor: Range.Cursor,
  filter?: F,
  config?: AxiosRequestConfig,
): HttpRange<R, F, E> {
  return pipe(
    get<Range.Result<R, F>, E>(url, {
      ...config,
      params: {
        ...config?.params,
        ...cursor,
        ...filter,
      },
    }),
    TE.map(Range.fromRangeResult),
  );
}

function post<R = unknown, E = unknown>(url: string, data?: any, config?: AxiosRequestConfig): HttpTask<R, E> {
  const filterBody = pipe(
    O.fromNullable(data),
    O.filter(data => !(data instanceof FormData)),
    O.fold(() => data, removeEmptyString),
  );

  return handleRequest(() => axiosInstance.post(url, filterBody, config));
}

function del<R = unknown, E = unknown>(url: string, config?: AxiosRequestConfig): HttpTask<R, E> {
  return handleRequest(() => axiosInstance.delete(url, config));
}

export const httpService = {
  get,
  getRange,
  post,
  delete: del,
};
