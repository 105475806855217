import * as Optics from 'optics-ts';
import { OfflinePendingState } from '../model';
import { isExistingNonCompliance, isNewNonCompliance } from '../utils/nonCompliances';
import { isDefined, isNotNull } from '../../../../shared/utils/fp';
import { isOfflinePendingGridCheckDataDocument, isOfflinePendingGridCheckFileDocument } from '../utils/grids';
import { RiskAnalysisGridTemplate } from '../../risk-analysis/model';

export function getInspectionPrism(inspectionId: string) {
  return Optics.optic<OfflinePendingState>()
    .prop('inspections')
    .prop(inspectionId)
    .when(a => typeof a === 'object');
}

export function getGridPrism(inspectionId: string, gridId: string) {
  return getInspectionPrism(inspectionId)
    .prop('grids')
    .find(grid => grid.id === gridId);
}

export function getGridsPrism(inspectionId: string) {
  return getInspectionPrism(inspectionId).prop('grids');
}

export function getUnmatchedCheckPrim(inspectionId: string) {
  return getInspectionPrism(inspectionId).prop('unmatchedChecks').guard(isNotNull);
}

export function getAttachedFilesPrism(inspectionId: string, gridId: string) {
  return getGridPrism(inspectionId, gridId).prop('attachedFiles');
}

export function getAttachedFilePrism(inspectionId: string, gridId: string, attachedFileId: string) {
  return getAttachedFilesPrism(inspectionId, gridId).find(attachedFile => attachedFile.fileId === attachedFileId);
}

export function getNonCompliancesPrism(inspectionId: string) {
  return getInspectionPrism(inspectionId).prop('nonCompliances');
}

export function getExistingNonCompliancePrism(inspectionId: string, nonComplianceId: string) {
  return getNonCompliancesPrism(inspectionId)
    .find(nonCompliance => nonCompliance.id === nonComplianceId)
    .guard(isExistingNonCompliance);
}

export function getNewNonCompliancePrism(inspectionId: string, nonComplianceId: string) {
  return getNonCompliancesPrism(inspectionId)
    .find(nonCompliance => nonCompliance.id === nonComplianceId)
    .guard(isNewNonCompliance);
}

export function getGridConclusionPrism(inspectionId: string, gridId: string) {
  return getGridPrism(inspectionId, gridId).prop('conclusion');
}

export function getCheckFileDocumentPrism(inspectionId: string, gridId: string, checkFileId: string) {
  return getGridPrism(inspectionId, gridId)
    .prop('documents')
    .find(document => document.id === checkFileId)
    .guard(isOfflinePendingGridCheckFileDocument);
}

export function getCheckDataDocumentPrism(inspectionId: string, gridId: string, checkDataId: string) {
  return getGridPrism(inspectionId, gridId)
    .prop('documents')
    .find(document => document.id === checkDataId)
    .guard(isOfflinePendingGridCheckDataDocument);
}

export function getParcelsPrism(inspectionId: string) {
  return getInspectionPrism(inspectionId).prop('parcels');
}

export function getParcelPrism(inspectionId: string, parcelsId: string) {
  return getParcelsPrism(inspectionId)
    .prop('parcels')
    .find(parcel => parcel.id === parcelsId);
}

export function getParcelRowsPrism(inspectionId: string, parcelsId: string) {
  return getParcelsPrism(inspectionId)
    .prop('parcels')
    .find(parcel => parcel.id === parcelsId)
    .prop('lines');
}

export function getRowParcelPrism(inspectionId: string, parcelsId: string, rowId: string) {
  return getParcelsPrism(inspectionId)
    .prop('parcels')
    .find(parcel => parcel.id === parcelsId)
    .prop('lines')
    .find(line => line.id === rowId);
}

export function getStateRowParcelPrism(inspectionId: string, parcelsId: string, rowId: string) {
  return getParcelsPrism(inspectionId)
    .prop('parcels')
    .find(parcel => parcel.id === parcelsId)
    .prop('lines')
    .find(line => line.id === rowId && line.state === 'new');
}

export function getRiskAnalysisPrism(inspectionId: string) {
  return getInspectionPrism(inspectionId).prop('riskAnalysis').guard(isDefined);
}

export function getRiskAnalysisCriterionPrism(
  inspectionId: string,
  riskCode: RiskAnalysisGridTemplate.Code,
  code: number,
) {
  return getRiskAnalysisPrism(inspectionId)
    .prop('riskAnalysisGrids')
    .prop(riskCode)
    .find(risk => risk.code === code);
}

export function getCoverPagePrism(inspectionId: string) {
  return getInspectionPrism(inspectionId).prop('coverPage').guard(isDefined);
}
