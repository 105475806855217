import React, { FC, PropsWithChildren, useEffect } from 'react';

import ReactModal from 'react-modal';

import * as Styled from './Modal.styles';
import { Button, Paragraph, Title } from '@root/styles';

ReactModal.setAppElement('#root');

export interface ModalProps extends Omit<ReactModal.Props, 'className'> {
  width?: number;
  transparent?: boolean;
  hideCloseButton?: boolean;
}

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  width,
  transparent,
  hideCloseButton,
  style,
  onRequestClose,
  ...rest
}) => {
  const modalStyle: ReactModal.Styles = {
    ...style,
    overlay: {
      background: transparent ? 'rgba(255, 255, 255, 0.9)' : '#ffffff',
      ...style?.overlay,
    },
    content: {
      ...style?.content,
      width: width ?? style?.content?.width ?? 500,
      borderRadius: '10px',
    },
  };

  return (
    <ReactModal {...rest} onRequestClose={onRequestClose} className="qualisud-modal" style={modalStyle}>
      {!hideCloseButton && <Styled.ModalCloseIcon onClick={onRequestClose} />}
      {children}
    </ReactModal>
  );
};

interface ConfirmModalProps extends ModalProps {
  title: string;
  description: string;
  onApprove: () => void;
  onRequestClose?: () => void;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({
  isOpen,
  title,
  description,
  onApprove,
  onRequestClose,
  ...modalProps
}) => {
  const handleApprove = () => {
    onApprove();

    if (onRequestClose) {
      onRequestClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === 'Enter') {
          onApprove();

          if (onRequestClose) {
            onRequestClose();
          }
        }
      };

      document.addEventListener('keydown', handleKeyDown, { passive: true });

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [onRequestClose, onApprove, isOpen]);

  const modalStyle: ReactModal.Styles = {
    overlay: {
      background: 'rgba(255, 255, 255, 0.75)',
    },
  };

  return (
    <Modal {...modalProps} isOpen={isOpen} style={modalStyle} onRequestClose={onRequestClose}>
      <Styled.ConfirmModalContainer>
        <Title as="h2" $level="h2">
          {title}
        </Title>

        <Paragraph>{description}</Paragraph>

        <div>
          <Button $level="secondary" onClick={onRequestClose}>
            Annuler
          </Button>
          <Button $level="primary" onClick={handleApprove}>
            Confirmer
          </Button>
        </div>
      </Styled.ConfirmModalContainer>
    </Modal>
  );
};

export default Modal;
