import React, { FC } from 'react';
import Modal from '../../../components/modal/Modal';
import { Button } from '../../../../styles/shared';

import * as Styled from './UpdateNotificationModal.styles';

interface UpdateNotificationModalProps {
  onUpdate: () => void;
}

const UpdateNotificationModal: FC<UpdateNotificationModalProps> = ({ onUpdate }) => {
  const styles: ReactModal.Styles = {
    content: {
      border: '1px solid #e2e2e2',
    },
  };

  return (
    <Modal isOpen={true} transparent={true} width={600} hideCloseButton style={styles}>
      <Styled.UpdateNotificationModalContainer>
        <p>Une nouvelle version de l'application est disponible.</p>

        <Button onClick={onUpdate}>Installer</Button>
      </Styled.UpdateNotificationModalContainer>
    </Modal>
  );
};

export default UpdateNotificationModal;
