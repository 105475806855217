import { useDispatch, useSelector } from 'react-redux';
import { profileSelector } from './store/selectors';

import * as O from 'fp-ts/Option';
import * as T from 'fp-ts/Task';
import { Profile } from './model';
import { useCallback } from 'react';
import { removeProfile, updateProfile } from './store/actions';
import { pipe } from 'fp-ts/function';

import * as AuthService from './service';

export interface UseAuthContextValue {
  profile: O.Option<Profile>;
  updateProfile: (profile: Profile) => void;
  logout: () => void;
}

export function useAuthContext(): UseAuthContextValue {
  const profile = useSelector(profileSelector);

  const dispatch = useDispatch();

  const handleUpdateProfile = useCallback((profile: Profile) => dispatch(updateProfile(profile)), [dispatch]);

  const handleLogout = useCallback(() => {
    return pipe(
      AuthService.removeAuthToken(),
      T.chainIOK(() => () => dispatch(removeProfile())),
    )();
  }, [dispatch]);

  return {
    profile,
    updateProfile: handleUpdateProfile,
    logout: handleLogout,
  };
}
