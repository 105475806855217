import { SyncIncomingInspection } from '../../../sync/model';
import { OfflineGridState } from './pending';

export type IncomingInspectionMap = Record<string, SyncIncomingInspection>;

export interface OfflineIncomingState {
  date: string | null;
  inspections: IncomingInspectionMap;
}

export const defaultOfflineIncomingState: OfflineIncomingState = {
  date: null,
  inspections: {},
};

export type InspectionDisplay = SyncIncomingInspection & { state: OfflineGridState };
