import { combineReducers } from 'redux';

import thunk from 'redux-thunk';

import { initialState, RootState } from './state';

import getAppReducer from '../modules/app/store/reducer';
import getConfigReducer from '../modules/config/store/reducer';
import getAuthReducer from '../modules/auth/store/reducer';
import getOfflineReducer from '../modules/offline/store/reducer';
import getSyncReducer from '../modules/sync/store/reducer';
import { richTextReducer, multipleRichTextReducer } from '@shared/modules/rich-text/store/reducer';
import { reduxIDBStorage } from '@core/storage';
import { configureStore } from '@reduxjs/toolkit';

const reducer = combineReducers<RootState>({
  app: getAppReducer(reduxIDBStorage),
  auth: getAuthReducer(reduxIDBStorage),
  config: getConfigReducer(reduxIDBStorage),
  sync: getSyncReducer(reduxIDBStorage),
  offline: getOfflineReducer(reduxIDBStorage),
  richText: richTextReducer,
  multipleRichText: multipleRichTextReducer,
});

export const initializeStore = (preloadedState = initialState) =>
  configureStore({
    reducer,
    preloadedState,
    middleware: [thunk],
    devTools: true,
  });

export * from './hooks';
export * from './state';
export * from './types';
