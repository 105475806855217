import styled, { css } from 'styled-components';
import { contentSize } from '../styles';
import { Environment } from '@config/schema';

export const LayoutContainer = styled.div<{ $environment: Environment }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  ${({ $environment }) =>
    $environment !== Environment.Development &&
    css`
      @media screen and (max-width: ${props => contentSize(props) - 1}px) {
        display: none;
      }
    `}
`;

export const LayoutContent = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
`;
