import { getStorageService } from '@core/storage';
import * as T from 'fp-ts/Task';
import * as TO from 'fp-ts/TaskOption';

import SignaturePad from 'signature_pad';

const signatureDataStorage = getStorageService('signatures-data');

export type SignatureMode = 'draw' | 'keyboarding';

export interface SignatureDraw {
  mode: 'draw';
  value: Array<Array<SignaturePad.Point>>;
}

export interface SignatureKeyboarding {
  mode: 'keyboarding';
  value: string;
}

export type SignatureData = SignatureDraw | SignatureKeyboarding;

export function addSignatureData(id: string, signature: SignatureData): T.Task<SignatureData> {
  return signatureDataStorage.setItem(id, signature);
}

export function getSignatureData(id: string): TO.TaskOption<SignatureData> {
  return signatureDataStorage.getItem<SignatureData>(id);
}

export function deleteSignatureData(id: string): T.Task<void> {
  return signatureDataStorage.removeItem(id);
}

export function clearSignatureData(): T.Task<void> {
  return signatureDataStorage.clear();
}
