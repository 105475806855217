import React, { FC, Suspense } from 'react';
import { createBrowserHistory } from 'history';
import { useAppMode } from './modules/app/hooks';
import { AppMode } from './modules/app/store/model';

import { safeLazy } from './core/router';
import LineLoader from './shared/components/loaders/line-loaders/LineLoader';

const OnlineRoutes = safeLazy(() => import('./modules/online/routes'));
const OfflineRoutes = safeLazy(() => import('./modules/offline/routes'));

const RootRoutes: FC = () => {
  const { mode } = useAppMode();

  return (
    <Suspense fallback={<LineLoader />}>{AppMode.Online === mode ? <OnlineRoutes /> : <OfflineRoutes />}</Suspense>
  );
};

export const history = createBrowserHistory();

export default RootRoutes;
