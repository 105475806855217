import styled, { css } from 'styled-components';
import { alertColor, backgroundColor, secondaryColor, tertiaryColor } from '../../utils';

import checkIcon from '../../../assets/icons/check-box-active.svg';
import negativeIcon from '../../../assets/icons/check-box-negative.svg';

export const checkboxStyles = css<{ $grey?: boolean }>`
  position: relative;
  width: 24px;
  height: 24px;
  margin: 0;
  border-radius: 2px;
  border: 2px solid ${tertiaryColor(300)};
  background: ${backgroundColor.default};
  cursor: pointer;
  transition: background-color 0.15s linear;
  outline: none;

  &:checked {
    background: url(${checkIcon}) no-repeat center center
      ${props => (props.$grey ? tertiaryColor(400) : secondaryColor())};
    background-size: 22px 22px;
    border-color: ${props => (props.$grey ? tertiaryColor(400) : secondaryColor())};
  }
`;

export const CheckBox = styled.input.attrs(() => ({ type: 'checkbox' }))<{ $grey?: boolean }>`
  ${checkboxStyles};
`;

export const NegativeCheckBox = styled(CheckBox)`
  &:checked {
    background: url(${negativeIcon}) no-repeat center center ${alertColor};
    background-size: 20px 20px;
    border-color: ${alertColor};
  }
`;
