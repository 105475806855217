import { BIOCoverPage } from './model';
import { pipe } from 'fp-ts/function';
import { sequenceS } from 'fp-ts/Apply';
import * as O from 'fp-ts/Option';
import * as NEA from 'fp-ts/NonEmptyArray';
import { filterEmptyStringToOption } from '../../../../shared/utils/string';

function mapValueWrapperToNullable({
  type,
  list,
}: BIOCoverPage.Perimeter.ValueWrapper): BIOCoverPage.Perimeter.Type | null {
  return pipe(
    NEA.fromArray(list),
    O.chain(() => O.fromNullable(type)),
    O.toNullable,
  );
}

function mapPreparationPendingToNullable({
  type,
  list,
}: BIOCoverPage.Preparation.Pending): BIOCoverPage.Preparation | null {
  return pipe(
    sequenceS(O.Apply)({
      type: O.fromNullable(type),
      activities: NEA.fromArray(list),
    }),
    O.toNullable,
  );
}

function mapDistributionPendingToNullable({
  type,
  list,
}: BIOCoverPage.Distribution.Pending): BIOCoverPage.Distribution | null {
  return pipe(
    sequenceS(O.Apply)({
      type: O.fromNullable(type),
      activities: NEA.fromArray(list),
    }),
    O.toNullable,
  );
}

function mapRetailPendingToNullable({ type, list }: BIOCoverPage.Retail.Pending): BIOCoverPage.Retail | null {
  return pipe(
    sequenceS(O.Apply)({
      type: O.fromNullable(type),
      locations: NEA.fromArray(list),
    }),
    O.toNullable,
  );
}

function mapPendingPerimeterToPerimeter(perimeter: BIOCoverPage.Perimeter.Pending): BIOCoverPage.Perimeter {
  return {
    preparation: mapPreparationPendingToNullable(perimeter.preparation),
    distribution: mapDistributionPendingToNullable(perimeter.distribution),
    retail: mapRetailPendingToNullable(perimeter.retail),
    animalProduction: mapValueWrapperToNullable(perimeter.animalProduction),
    plantProduction: mapValueWrapperToNullable(perimeter.plantProduction),
    outsideEUExportation: mapValueWrapperToNullable(perimeter.outsideEUExportation),
    outsideEUImportation: mapValueWrapperToNullable(perimeter.outsideEUImportation),
  };
}

export function mapPendingBioCoverPageToBioCoverPage(cover: BIOCoverPage.Pending): O.Option<BIOCoverPage> {
  return pipe(
    filterEmptyStringToOption(cover.pacageNumber),
    O.map(pacageNumber => ({
      ...cover,
      pacageNumber,
      operatorActivity: mapPendingPerimeterToPerimeter(cover.operatorActivity),
    })),
  );
}
