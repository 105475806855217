import styled, { createGlobalStyle } from 'styled-components';
import { backgroundColor, tertiaryColor } from '../../../styles';
import closeIcon from '../../../assets/icons/drawer/close.svg';

export const GlobalModalStyle = createGlobalStyle`
  .ReactModalPortal {
    .ReactModal__Overlay {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;

      z-index: 1500;
      
      .qualisud-modal {
        position: relative;
        padding: 50px 40px 40px;
        max-width: 100%;
        max-height: 100%;
        background: ${backgroundColor.default};
        overflow-y: auto;
        outline: none;
      }
    }
  }
`;

export const ModalCloseIcon = styled.button`
  position: absolute;
  z-index: 10;
  display: block;
  top: 20px;
  right: 20px;
  width: 36px;
  height: 36px;
  border: 2px solid ${tertiaryColor(300)};
  border-radius: 100%;
  outline: none;
  background: url(${closeIcon}) no-repeat center;
  cursor: pointer;
`;

export const ConfirmModalContainer = styled.div`
  text-align: center;

  > p {
    padding: 15px 0 25px;
  }

  > div {
    button {
      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }
`;
