import { Environment } from '@config/schema';

export interface ConfigState {
  environment: Environment;
  release: string | null;
  googleMapsKey: string;
  sentryDsn: string | null;
}

export const defaultConfigState: ConfigState = {
  environment: Environment.Development,
  googleMapsKey: '',
  release: null,
  sentryDsn: null,
};
