import * as TO from 'fp-ts/TaskOption';
import * as T from 'fp-ts/Task';
import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';

import { IDBPDatabase, openDB } from 'idb';

const DB_NAME = 'qualisud-data';

const DB_VERSION = 3;

// signatures-draw is deprecated
const DB_STORES = ['redux', 'security', 'documents', 'sentry-events', 'signatures-data'] as const;

type DbStoreKeys = (typeof DB_STORES)[number];

function getDb(): T.Task<IDBPDatabase> {
  return () =>
    openDB(DB_NAME, DB_VERSION, {
      upgrade(db: IDBPDatabase, oldVersion) {
        // Delete all v1 store
        if (oldVersion === 1) {
          DB_STORES.forEach(store => {
            db.deleteObjectStore(store);
          });
        }

        if (oldVersion === 2) {
          db.deleteObjectStore('signatures-draw');
        }

        const existingStoreNames = db.objectStoreNames;

        DB_STORES.forEach(store => {
          if (!existingStoreNames.contains(store)) {
            db.createObjectStore(store);
          }
        });
      },
    });
}

function startDbOperation<R>(fn: (db: IDBPDatabase) => Promise<R>): T.Task<R> {
  return pipe(
    T.bindTo('db')(getDb()),
    T.bind(
      'result',
      ({ db }) =>
        () =>
          fn(db),
    ),
    T.tapIO(
      ({ db }) =>
        () =>
          db.close(),
    ),
    T.map(({ result }) => result),
  );
}

function getItem(store: DbStoreKeys): <T>(key: string) => TO.TaskOption<T> {
  return (key: string) =>
    pipe(
      startDbOperation(db => db.get(store, key)),
      T.map(O.fromNullable),
    );
}

function setItem(store: DbStoreKeys): <T>(key: string, value: T) => T.Task<T> {
  return <T>(key: string, value: T) =>
    pipe(
      startDbOperation(db => db.put(store, value, key)),
      T.map(() => value),
    );
}

function removeItem(store: DbStoreKeys): (key: string) => T.Task<void> {
  return key => startDbOperation(db => db.delete(store, key));
}

function clear(storage: DbStoreKeys): () => T.Task<void> {
  return () => startDbOperation(db => db.clear(storage));
}

function keys(storage: DbStoreKeys): () => T.Task<Array<string>> {
  return () => startDbOperation(db => db.getAllKeys(storage)) as any;
}

function length(storage: DbStoreKeys): () => T.Task<number> {
  return () =>
    pipe(
      keys(storage)(),
      T.map(keys => keys.length),
    );
}

export function getStorageService(storage: DbStoreKeys) {
  return {
    getItem: getItem(storage),
    setItem: setItem(storage),
    removeItem: removeItem(storage),
    clear: clear(storage),
    length: length(storage),
    keys: keys(storage),
  };
}

export const reduxIDBStorage = {
  getItem: (key: string) => pipe(getItem('redux')(key), T.map(O.toUndefined))(),
  setItem: (key: string, value: any) => setItem('redux')(key, value)(),
  removeItem: (key: string) => removeItem('redux')(key)(),
};
