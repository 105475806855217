import React, { FC } from 'react';
import UpdateNotificationModal from './notification/UpdateNotificationModal';
import { useRegisterSW } from 'virtual:pwa-register/react';

const ServiceWorker: FC = () => {
  const {
    updateServiceWorker,
    needRefresh: [isRefreshNeeded],
  } = useRegisterSW();

  const handleReload = () => updateServiceWorker(true);

  return isRefreshNeeded ? <UpdateNotificationModal onUpdate={handleReload} /> : null;
};

export default ServiceWorker;
