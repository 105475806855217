import React, { FC, PropsWithChildren, ReactNode } from 'react';

import * as Styled from './Page.styles';
import MetaTitle from './meta/MetaTitle';

import * as O from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { LineLoader } from '@shared/components/loaders/line-loaders/LineLoader';

export const PAGE_SCROLLABLE_ID = 'page-scrollable';

interface PageProps {
  title?: string;
  top?: ReactNode;
  bottom?: ReactNode;
  isLeft?: boolean;
}

const Page: FC<PropsWithChildren<PageProps>> = ({ title, top, bottom, isLeft = false, children }) => {
  return (
    <Styled.PageContainer $isLeft={isLeft}>
      <MetaTitle title={title} />
      {top && <Styled.PageTop>{top}</Styled.PageTop>}
      <Styled.PageContent id={PAGE_SCROLLABLE_ID}>
        {pipe(
          O.fromNullable(children),
          O.getOrElse<ReactNode>(() => <LineLoader />),
        )}
      </Styled.PageContent>
      {bottom && <div>{bottom}</div>}
    </Styled.PageContainer>
  );
};

export default Page;
