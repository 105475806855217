export enum AppMode {
  Online = 'online',
  Offline = 'offline',
}

export interface AppState {
  mode: AppMode;
  networkAvailable: boolean;
}

export const defaultAppState: AppState = {
  mode: AppMode.Online,
  networkAvailable: true,
};
