import * as NEA from 'fp-ts/NonEmptyArray';

export namespace RiskAnalysisGridTemplate {
  export enum Code {
    Bio = 'BIO',
  }
  export namespace Code {
    export const label: Record<Code, string> = {
      [Code.Bio]: 'BIO',
    };
  }

  export namespace Criterion {
    export enum OfflineValue {
      Inapplicable = 'NA',
      Yes = 'OK',
      No = 'KO',
    }
    export interface OfflinePending extends Criterion {
      value: OfflineValue;
      comment: string | null;
    }
    export interface PendingSync extends Criterion {
      comment: string | null;
    }
  }

  export interface Criterion {
    code: number;
    order: number;
    label: string;
    coefficient: number;
  }

  export interface SubSection {
    order: number;
    label: string;
    criteria: NEA.NonEmptyArray<Criterion>;
  }

  export interface Section {
    order: number;
    label: string;
    subSections: NEA.NonEmptyArray<SubSection>;
  }
}

export interface RiskAnalysisGridTemplate {
  code: RiskAnalysisGridTemplate.Code;
  title: string;
  sections: NEA.NonEmptyArray<RiskAnalysisGridTemplate.Section>;
}
