import styled from 'styled-components';
import { backgroundColor } from '../../styles';

export const PageContainer = styled.div<{ $isLeft: boolean }>`
  padding-left: ${props => (props.$isLeft ? '340px' : '0')};
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 1580px) {
    padding-left: 0;
  }
`;

export const PageTop = styled.div`
  background: ${backgroundColor.default};
`;

export const PageContent = styled.div`
  flex: 1 1 auto;
  overflow-y: auto;
  background: ${backgroundColor['100']};
`;
