import React, { FC, PropsWithChildren, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import Header from './header/Header';

import * as Styled from './Layout.styles';
import { useAuthContext } from '@modules/auth/hooks';

import * as O from 'fp-ts/Option';
import ScreenLock from './screen-lock/ScreenLock';
import { useAppMode } from '@modules/app/hooks';
import { AppMode } from '@modules/app/store/model';
import { useConfig } from '@modules/config/hooks';
import { Environment } from '@config/schema';
import { renderOptional } from '@shared/utils/render';

function shouldHideHeader(pathname: string): boolean {
  return (
    pathname.includes('login') ||
    pathname.includes('password-reset') ||
    pathname.includes('activation') ||
    pathname.includes('sync') ||
    pathname.includes('non-compliance')
  );
}

const Layout: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  const { profile } = useAuthContext();

  const { mode } = useAppMode();

  const hideHeader = useMemo(() => shouldHideHeader(location.pathname), [location.pathname]);

  const { environment } = useConfig();

  return (
    <>
      <Styled.LayoutContainer $environment={environment}>
        {renderOptional(O.filter(() => !hideHeader && AppMode.Online === mode)(profile), profile => (
          <Header profile={profile} />
        ))}
        <Styled.LayoutContent>{children}</Styled.LayoutContent>
      </Styled.LayoutContainer>
      {environment !== Environment.Development && <ScreenLock />}
    </>
  );
};

export default Layout;
