import { useDispatch, useSelector } from 'react-redux';
import { appModeSelector } from './store/selectors';
import { useCallback, useMemo } from 'react';
import { changeAppMode } from './store/actions';
import { AppMode } from './store/model';

export function useAppMode() {
  const mode = useSelector(appModeSelector);
  const dispatch = useDispatch();

  const changeMode = useCallback((mode: AppMode) => dispatch(changeAppMode(mode)), [dispatch]);

  return useMemo(() => ({ mode, changeMode }), [mode, changeMode]);
}
