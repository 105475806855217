import { AppState, defaultAppState } from '../modules/app/store/model';
import { AuthState, defaultAuthState } from '../modules/auth/store/model';
import { ConfigState, defaultConfigState } from '../modules/config/store/model';
import { defaultOfflineState, OfflineState } from '../modules/offline/store/model';
import { defaultSyncState, SyncState } from '../modules/sync/store/model';
import { defaultRichTextState, RichTextState } from '../shared/modules/rich-text/store/model';

export interface RootState {
  app: AppState;
  auth: AuthState;
  config: ConfigState;
  sync: SyncState;
  offline: OfflineState;
  richText: RichTextState;
  multipleRichText: Array<RichTextState>;
}

export const initialState: RootState = {
  app: defaultAppState,
  auth: defaultAuthState,
  config: defaultConfigState,
  sync: defaultSyncState,
  offline: defaultOfflineState,
  richText: defaultRichTextState,
  multipleRichText: [],
};
