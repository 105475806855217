import {
  CheckpointLinkType,
  CheckScope,
  SyncIncomingAggregate,
  SyncIncomingCheckpoint,
  SyncIncomingMerger,
  SyncIncomingSection,
} from '../../../sync/model';
import {
  OfflineCheckpointValue,
  OfflinePendingCheckPoint,
  OfflinePendingSection,
  OfflinePendingSectionMergedType,
  OfflinePendingSectionRow,
} from '../model';

import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as Ord from 'fp-ts/Ord';
import * as S from 'fp-ts/string';
import * as N from 'fp-ts/number';
import { pipe } from 'fp-ts/function';

export function getEqOrder<T extends { order: number }>(): Ord.Ord<T> {
  return pipe(
    N.Ord,
    Ord.contramap((value: T) => value.order),
  );
}

export function getEqId<T extends { id: string }>(): Ord.Ord<T> {
  return pipe(
    S.Ord,
    Ord.contramap((value: T) => value.id),
  );
}

function mapSimpleRows(
  rows: Array<OfflinePendingSectionRow>,
  offlineCheckpoint: OfflinePendingCheckPoint,
): Array<OfflinePendingSectionRow> {
  return [
    ...rows,
    {
      id: offlineCheckpoint.id,
      type: 'SIMPLE',
      checkpoint: offlineCheckpoint,
    },
  ];
}

function mapMergedAggregateRows(
  rows: Array<OfflinePendingSectionRow>,
  merger: SyncIncomingMerger,
  aggregates: Array<SyncIncomingAggregate>,
  offlineCheckpoint: OfflinePendingCheckPoint,
): Array<OfflinePendingSectionRow> {
  return pipe(
    rows,
    A.findFirst(row => row.id === merger.id),
    O.chain(row => (row.type === 'MERGED' ? O.some(row) : O.none)),
    O.fold(
      () => {
        const aggregateId = aggregates.filter(ag => ag.checkpoints.includes(offlineCheckpoint.id));

        return [
          ...rows,
          {
            id: merger.id,
            type: 'MERGED',
            name: merger.name,
            items: {
              type: OfflinePendingSectionMergedType.Aggregate,
              aggregates:
                aggregateId.length === 0
                  ? []
                  : [
                      {
                        id: aggregateId[0].id,
                        type: 'AGGREGATED',
                        checkpoints: [offlineCheckpoint],
                      },
                    ],
            },
          },
        ];
      },
      mergedRow => {
        const aggregateId = aggregates.filter(ag => ag.checkpoints.includes(offlineCheckpoint.id));

        return rows.map(row =>
          row.id === mergedRow.id
            ? {
                ...mergedRow,
                items:
                  mergedRow.items.type === OfflinePendingSectionMergedType.Checkpoint
                    ? {
                        type: OfflinePendingSectionMergedType.Checkpoint,
                        checkpoints: [...mergedRow.items.checkpoints, offlineCheckpoint],
                      }
                    : {
                        type: OfflinePendingSectionMergedType.Aggregate,
                        aggregates:
                          aggregateId.length === 0
                            ? mergedRow.items.aggregates
                            : [
                                ...mergedRow.items.aggregates,
                                {
                                  id: aggregateId[0].id,
                                  type: 'AGGREGATED',
                                  checkpoints: [offlineCheckpoint],
                                },
                              ],
                      },
              }
            : row,
        );
      },
    ),
  );
}

function mapMergedRows(
  rows: Array<OfflinePendingSectionRow>,
  merger: SyncIncomingMerger,
  offlineCheckpoint: OfflinePendingCheckPoint,
): Array<OfflinePendingSectionRow> {
  return pipe(
    rows,
    A.findFirst(row => row.id === merger.id),
    O.chain(row => (row.type === 'MERGED' ? O.some(row) : O.none)),
    O.fold(
      () => [
        ...rows,
        {
          id: merger.id,
          type: 'MERGED',
          name: merger.name,
          items: { type: OfflinePendingSectionMergedType.Checkpoint, checkpoints: [offlineCheckpoint] },
        },
      ],
      mergedRow =>
        rows.map(row =>
          row.id === mergedRow.id
            ? {
                ...mergedRow,
                items:
                  mergedRow.items.type === OfflinePendingSectionMergedType.Checkpoint
                    ? {
                        type: OfflinePendingSectionMergedType.Checkpoint,
                        checkpoints: [...mergedRow.items.checkpoints, offlineCheckpoint],
                      }
                    : {
                        type: OfflinePendingSectionMergedType.Checkpoint,
                        checkpoints: [offlineCheckpoint],
                      },
              }
            : row,
        ),
    ),
  );
}

function mapAggregatedRows(
  rows: Array<OfflinePendingSectionRow>,
  aggregate: SyncIncomingAggregate,
  offlineCheckpoint: OfflinePendingCheckPoint,
  checkpoints: Array<SyncIncomingCheckpoint>,
): Array<OfflinePendingSectionRow> {
  const selectedAggregate = checkpoints.filter(checkpoint => checkpoint.id === aggregate.selected);

  return pipe(
    rows,
    A.findFirst(row => row.id === aggregate.id),
    O.chain(row => (row.type === 'AGGREGATED' ? O.some(row) : O.none)),
    O.fold(
      () => [
        ...rows,
        { id: aggregate.id, type: 'AGGREGATED', name: selectedAggregate[0].name, checkpoints: [offlineCheckpoint] },
      ],
      aggregatedRow =>
        rows.map(row =>
          row.id === aggregatedRow.id
            ? {
                ...aggregatedRow,
                checkpoints: [...aggregatedRow.checkpoints, offlineCheckpoint],
              }
            : row,
        ),
    ),
  );
}

function mapCheckpoint(
  checkpoint: SyncIncomingCheckpoint,
  replacedCheckpoints: Array<SyncIncomingCheckpoint>,
  completedCheckpoints: Array<SyncIncomingCheckpoint>,
): OfflinePendingCheckPoint {
  const link = pipe(
    O.bindTo('link')(O.fromNullable(checkpoint.link)),
    O.bind('linked', ({ link }) =>
      pipe(
        CheckpointLinkType.Replace === link.type ? replacedCheckpoints : completedCheckpoints,
        A.findFirst(checkpoint => checkpoint.id === link.checkpointId),
      ),
    ),
    O.map(({ link, linked }) => ({
      type: link.type,
      description: linked.description,
      targetValue: linked.targetValue,
    })),
  );

  return {
    id: checkpoint.id,
    code: checkpoint.number,
    name: checkpoint.name,
    procedure: checkpoint.procedure,
    specification: checkpoint.specification,
    checkScopes: checkpoint.checkScopes,
    inspectionMethods: checkpoint.inspectionMethods,
    description: checkpoint.description,
    targetValue: checkpoint.targetValue,
    link: O.toNullable(link),
    value: null,
    comment: null,
    nonCompliances: checkpoint.nonCompliances,
    inspectionReferenceText: checkpoint.inspectionReferenceText,
  };
}

function getMergedAggregates(
  merger: Array<string>,
  aggregate: Array<SyncIncomingAggregate>,
  checkpointId: string,
): boolean {
  return pipe(
    pipe(
      merger,
      A.map(m => aggregate.filter(ag => ag.id === m)),
    ).flat(),
    A.findFirst(m => m.checkpoints.includes(checkpointId)),
    O.fold(
      () => false,
      _ => true,
    ),
  );
}

function getSectionRows(
  sectionId: string,
  checkpoints: Array<SyncIncomingCheckpoint>,
  mergers: Array<SyncIncomingMerger>,
  aggregates: Array<SyncIncomingAggregate>,
  replacedCheckpoints: Array<SyncIncomingCheckpoint>,
  completedCheckpoints: Array<SyncIncomingCheckpoint>,
): Array<OfflinePendingSectionRow> {
  const sectionCheckpoints = pipe(
    checkpoints,
    A.filter(checkpoint => checkpoint.sectionId === sectionId),
    A.sort(getEqOrder()),
  );

  return sectionCheckpoints.reduce<Array<OfflinePendingSectionRow>>((rows, checkpoint) => {
    const offlineCheckpoint = mapCheckpoint(checkpoint, replacedCheckpoints, completedCheckpoints);

    const merger = pipe(
      mergers,
      A.findFirst(merger => (merger.checkpoints !== null ? merger.checkpoints.includes(checkpoint.id) : false)),
      O.toNullable,
    );

    if (merger) {
      return mapMergedRows(rows, merger, offlineCheckpoint);
    }

    const mergerAggregates = pipe(
      mergers,
      A.findFirst(merger =>
        merger.aggregates !== null ? getMergedAggregates(merger.aggregates, aggregates, checkpoint.id) : false,
      ),
      O.toNullable,
    );

    if (mergerAggregates) {
      return mapMergedAggregateRows(rows, mergerAggregates, aggregates, offlineCheckpoint);
    }

    const mergerAggregatesIds = mergers
      .filter(m => m.aggregates !== null)
      .map(m => m.aggregates)
      .flat();

    const aggregate = pipe(
      aggregates,
      A.filter(ag => !mergerAggregatesIds.includes(ag.id)),
      A.findFirst(aggregate => aggregate.checkpoints.includes(checkpoint.id)),
      O.toNullable,
    );

    if (aggregate) {
      return mapAggregatedRows(rows, aggregate, offlineCheckpoint, checkpoints);
    }

    return mapSimpleRows(rows, offlineCheckpoint);
  }, []);
}

function mapSection(
  section: SyncIncomingSection,
  checkpoints: Array<SyncIncomingCheckpoint>,
  mergers: Array<SyncIncomingMerger>,
  aggregates: Array<SyncIncomingAggregate>,
  replacedCheckpoints: Array<SyncIncomingCheckpoint>,
  completedCheckpoints: Array<SyncIncomingCheckpoint>,
): OfflinePendingSection {
  return {
    id: section.id,
    name: section.name,
    rows: getSectionRows(section.id, checkpoints, mergers, aggregates, replacedCheckpoints, completedCheckpoints),
  };
}

function getLinkedCheckpoints(
  checkpoints: Array<SyncIncomingCheckpoint>,
  type: CheckpointLinkType,
): Array<SyncIncomingCheckpoint> {
  return pipe(
    checkpoints,
    A.filterMap(checkpoint =>
      pipe(
        O.fromNullable(checkpoint.link),
        O.filter(link => link.type === type),
        O.chain(link =>
          pipe(
            checkpoints,
            A.findFirst(checkpoint => checkpoint.id === link.checkpointId),
          ),
        ),
      ),
    ),
  );
}

export function mapSections(
  checkpoints: Array<SyncIncomingCheckpoint>,
  sections: Array<SyncIncomingSection>,
  mergers: Array<SyncIncomingMerger>,
  aggregates: Array<SyncIncomingAggregate>,
): Array<OfflinePendingSection> {
  const replacedCheckpoints = getLinkedCheckpoints(checkpoints, CheckpointLinkType.Replace);
  const completedCheckpoints = getLinkedCheckpoints(checkpoints, CheckpointLinkType.Complete);

  const othersCheckpoints = A.difference(getEqId<SyncIncomingCheckpoint>())(checkpoints, replacedCheckpoints);

  return pipe(
    sections,
    A.sort(getEqOrder<SyncIncomingSection>()),
    A.map(section =>
      mapSection(section, othersCheckpoints, mergers, aggregates, replacedCheckpoints, completedCheckpoints),
    ),
  );
}

export function getFlattenCheckpoints(sections: Array<OfflinePendingSection>): Array<OfflinePendingCheckPoint> {
  return pipe(
    sections,
    A.chain(section => section.rows),
    A.chain(row => {
      switch (row.type) {
        case 'SIMPLE':
          return [row.checkpoint];
        case 'AGGREGATED':
          return row.checkpoints;
        case 'MERGED': {
          if (row.items.type === OfflinePendingSectionMergedType.Checkpoint) {
            return row.items.checkpoints;
          } else {
            return pipe(
              row.items.aggregates,
              A.chain(aggregate => aggregate.checkpoints),
            );
          }
        }
      }
    }),
  );
}

export function getAvailableCheckScopes(sections: Array<OfflinePendingSection>): Array<CheckScope> {
  return pipe(
    getFlattenCheckpoints(sections),
    A.chain(checkpoint => checkpoint.checkScopes),
    A.uniq(getEqId()),
  );
}

function filterCheckpoint(checkpoint: OfflinePendingCheckPoint): O.Option<OfflinePendingCheckPoint> {
  return pipe(
    checkpoint,
    O.fromPredicate(c => c.value !== OfflineCheckpointValue.Filtered),
  );
}

function filterRow(row: OfflinePendingSectionRow): O.Option<OfflinePendingSectionRow> {
  if (row.type === 'SIMPLE') {
    return pipe(
      row,
      O.fromPredicate(row => O.isSome(filterCheckpoint(row.checkpoint))),
    );
  } else if (row.type === 'AGGREGATED') {
    return pipe(
      row.checkpoints,
      A.filterMap(filterCheckpoint),
      NEA.fromArray,
      O.map(checkpoints => ({
        ...row,
        checkpoints,
      })),
    );
  } else if (row.items.type === OfflinePendingSectionMergedType.Checkpoint) {
    return pipe(
      row.items.checkpoints,
      A.filterMap(filterCheckpoint),
      NEA.fromArray,
      O.map(checkpoints => ({
        ...row,
        items: {
          ...row.items,
          checkpoints,
        },
      })),
    );
  } else if (row.items.type === OfflinePendingSectionMergedType.Aggregate) {
    return pipe(
      row.items.aggregates,
      A.chain(aggregate => aggregate.checkpoints),
      A.filterMap(filterCheckpoint),
      NEA.fromArray,
      O.map(checkpoints => ({
        ...row,
        items: {
          ...row.items,
          aggregates: checkpoints.map((checkpoint, i) => ({
            id:
              row.items.type === OfflinePendingSectionMergedType.Aggregate
                ? row.items.aggregates[i].id
                : row.items.checkpoints[i].id,
            type: 'AGGREGATED',
            checkpoints: [checkpoint],
          })),
        },
      })),
    );
  } else return O.none;
}

export function filterSections(sections: Array<OfflinePendingSection>): Array<OfflinePendingSection> {
  return pipe(
    sections,
    A.filterMap(section =>
      pipe(
        section.rows,
        A.filterMap(filterRow),
        NEA.fromArray,
        O.map(rows => ({
          ...section,
          rows,
        })),
      ),
    ),
  );
}

function updateCheckpointValueFromCheckScopes(
  checkpoint: OfflinePendingCheckPoint,
  checkScopes: Array<string>,
  comment: string | null,
): OfflinePendingCheckPoint {
  const hasInvariableChecksScopes = () =>
    pipe(
      checkpoint.checkScopes,
      A.some(checkScope => checkScope.invariable),
    );

  const hasSelectedCheckScopes = () =>
    pipe(
      checkpoint.checkScopes,
      A.some(checkScope => checkScopes.includes(checkScope.id)),
    );

  const shouldShow = hasInvariableChecksScopes() || hasSelectedCheckScopes();

  if (shouldShow) {
    if (checkpoint.value === OfflineCheckpointValue.Filtered) {
      return {
        ...checkpoint,
        value: null,
        comment: null,
      };
    } else {
      return checkpoint;
    }
  } else {
    return {
      ...checkpoint,
      value: OfflineCheckpointValue.Filtered,
      comment,
    };
  }
}

function updateRowValuesFromCheckScopes(
  row: OfflinePendingSectionRow,
  checkScopes: Array<string>,
  comment: string | null,
): OfflinePendingSectionRow {
  if (row.type === 'SIMPLE') {
    return {
      ...row,
      checkpoint: updateCheckpointValueFromCheckScopes(row.checkpoint, checkScopes, comment),
    };
  } else if (row.type === 'AGGREGATED') {
    return {
      ...row,
      checkpoints: row.checkpoints.map(checkpoint =>
        updateCheckpointValueFromCheckScopes(checkpoint, checkScopes, comment),
      ),
    };
  } else if (row.items.type === OfflinePendingSectionMergedType.Checkpoint) {
    return {
      ...row,
      items: {
        ...row.items,
        checkpoints: row.items.checkpoints.map(checkpoint =>
          updateCheckpointValueFromCheckScopes(checkpoint, checkScopes, comment),
        ),
      },
    };
  } else {
    return {
      ...row,
      items: {
        ...row.items,
        aggregates: row.items.aggregates.map(aggregate => ({
          id: aggregate.id,
          type: 'AGGREGATED',
          checkpoints: aggregate.checkpoints.map(c => updateCheckpointValueFromCheckScopes(c, checkScopes, comment)),
        })),
      },
    };
  }
}

export function updateSectionsValuesFromCheckScopes(
  sections: Array<OfflinePendingSection>,
  checkScopes: Array<string>,
  comment: string | null,
): Array<OfflinePendingSection> {
  return pipe(
    sections,
    A.map(section => ({
      ...section,
      rows: section.rows.map(row => updateRowValuesFromCheckScopes(row, checkScopes, comment)),
    })),
  );
}
