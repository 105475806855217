import { Dispatch, SetStateAction, useCallback, useState } from 'react';

export function useBooleanState(
  defaultState: boolean = false,
): [boolean, () => void, () => void, Dispatch<SetStateAction<boolean>>] {
  const [value, setValue] = useState(defaultState);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);

  return [value, setTrue, setFalse, setValue];
}
