import React, { FC } from 'react';
import Page from '../../../layout/page/Page';

import * as Styled from './Error.styles';
import { Button, LinkButton, Paragraph, Title } from '../../../styles';

import errorIcon from '../../../assets/icons/error/error.svg';
import notInternetIcon from '../../../assets/icons/error/no-internet.svg';
import { HttpStatusCode } from '../../../core/http';

function getIcon(status: HttpStatusCode): string {
  if (HttpStatusCode.NETWORK_NOT_REACHABLE === status) {
    return notInternetIcon;
  } else {
    return errorIcon;
  }
}

function getTitle(status: HttpStatusCode): string {
  switch (status) {
    case HttpStatusCode.NOT_FOUND:
      return 'Introuvable';
    case HttpStatusCode.NETWORK_NOT_REACHABLE:
      return 'Connexion interrompue';
    default:
      return `Erreur ${status}`;
  }
}

function getDescription(status: HttpStatusCode): string {
  switch (status) {
    case HttpStatusCode.NOT_FOUND:
      return 'Nous ne trouvons pas la page que vous recherchez.';
    case HttpStatusCode.NETWORK_NOT_REACHABLE:
      return 'Votre connexion a été interrompue. Un changement de réseau a été détecté.';
    default:
      return 'Une erreur technique est survenue';
  }
}

function getAction(status: HttpStatusCode, handleClick: () => void) {
  if (HttpStatusCode.NOT_FOUND === status) {
    return <LinkButton to="/">Accueil</LinkButton>;
  } else {
    return <Button onClick={handleClick}>Rafraîchir la page</Button>;
  }
}

interface ErrorProps {
  status?: HttpStatusCode;
}

const Error: FC<ErrorProps> = ({ status }) => {
  const computedStatus = status ? status : HttpStatusCode.NOT_FOUND;

  const handleReload = () => window.location.reload();

  const title = getTitle(computedStatus);

  return (
    <Page title={title}>
      <Styled.ErrorContainer>
        <img src={getIcon(computedStatus)} alt="error-icon" />
        <Title>{title}</Title>
        <Paragraph>{getDescription(computedStatus)}</Paragraph>
        {getAction(computedStatus, handleReload)}
      </Styled.ErrorContainer>
    </Page>
  );
};

export default Error;
