import styled from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { fontSize, primaryColor } from '../utils';
import { identity } from 'fp-ts/function';

export const SimpleLink = styled(Link).attrs<Partial<LinkProps>, LinkProps>(identity)`
  font-size: ${fontSize.navigation};
  color: ${primaryColor()};
  text-decoration: underline;
  transition: color 0.15s linear;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: inherit;

  &:hover {
    color: ${primaryColor(700)};
  }
`;
