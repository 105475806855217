import { SyncIncomingInspection } from '../../../sync/model';
import { ThunkResult } from '@root/store';
import { initOfflineIncomingState, OfflineIncomingAction, resetOfflineIncomingState } from './incoming';
import { initOfflinePendingState, OfflinePendingAction, resetOfflinePendingState } from './pending';

import * as fileStorageService from '../../../sync/file-storage';
import * as signatureDataStorageService from '../../grids/signature-draw-storage';

import * as T from 'fp-ts/Task';
import * as IO from 'fp-ts/IO';
import { pipe } from 'fp-ts/function';
import { initOfflineReferentialState, OfflineReferentialAction, resetOfflineReferentialState } from './referential';
import { sequenceT } from 'fp-ts/Apply';
import { OfflineReferentialState } from '../model';

export function syncIncomingSuccess(
  date: Date,
  inspections: Array<SyncIncomingInspection>,
  referential: OfflineReferentialState,
): ThunkResult<void, OfflineIncomingAction | OfflinePendingAction | OfflineReferentialAction> {
  return (dispatch, getState) => {
    pipe(
      () => getState().auth.profile,
      IO.chain(profile =>
        sequenceT(IO.Apply)(
          () => dispatch(initOfflineIncomingState(date, inspections)),
          () => dispatch(initOfflinePendingState(inspections, profile)),
          () => dispatch(initOfflineReferentialState(referential)),
        ),
      ),
    )();
  };
}

export function clearOfflineStorage(): ThunkResult<
  Promise<void>,
  OfflineIncomingAction | OfflinePendingAction | OfflineReferentialAction
> {
  return dispatch =>
    pipe(
      fileStorageService.clearFiles(),
      T.chain(signatureDataStorageService.clearSignatureData),
      T.chainIOK(() =>
        sequenceT(IO.Apply)(
          () => dispatch(resetOfflineIncomingState()),
          () => dispatch(resetOfflinePendingState()),
          () => dispatch(resetOfflineReferentialState()),
        ),
      ),
      T.asUnit,
    )();
}
