import { Action, ActionWithPayload } from '../../../../store';
import { OfflineReferentialState } from '../model';

export enum OfflineReferentialActionType {
  INIT_STATE = 'OFFLINE/REFERENTIAL/INIT',
  RESET_STATE = 'OFFLINE/REFERENTIAL/RESET',
}
export type OfflineReferentialAction =
  | ActionWithPayload<OfflineReferentialActionType.INIT_STATE, OfflineReferentialState>
  | Action<OfflineReferentialActionType.RESET_STATE>;

export function initOfflineReferentialState(referential: OfflineReferentialState): OfflineReferentialAction {
  return {
    type: OfflineReferentialActionType.INIT_STATE,
    payload: referential,
  };
}

export function resetOfflineReferentialState(): OfflineReferentialAction {
  return {
    type: OfflineReferentialActionType.RESET_STATE,
  };
}
