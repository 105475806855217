import { ExecutionContext } from 'styled-components';
import theme from '../theme';

export function fontFamily(props: ExecutionContext): string {
  return props.theme.font.family;
}

export const fontWeight = (Object.keys(theme.font.weights) as (keyof typeof theme.font.weights)[]).reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: (props: ExecutionContext) => props.theme.font.weights[curr],
  }),
  {} as { [key in keyof typeof theme.font.weights]: (props: ExecutionContext) => number },
);

export const fontSize = (Object.keys(theme.font.sizes) as (keyof typeof theme.font.sizes)[]).reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: (props: ExecutionContext) => props.theme.font.sizes[curr],
  }),
  {} as { [key in keyof typeof theme.font.sizes]: (props: ExecutionContext) => string },
);
