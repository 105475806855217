import {
  GridType,
  OfflineCheckpointValue,
  OfflineGridState,
  OfflinePendingCheckPoint,
  OfflinePendingExistingNonCompliance,
  OfflinePendingGrid,
  OfflinePendingGridCheckDataDocument,
  OfflinePendingGridCheckFileDocument,
  OfflinePendingGridCheckFileDocumentFileOrReason,
  OfflinePendingGridConclusion,
  OfflinePendingInspection,
  OfflinePendingMeta,
  OfflinePendingMetaSection,
  OfflinePendingNewNonCompliance,
  OfflinePendingNonCompliance,
  OfflinePendingParcel,
  OfflinePendingParcelDataLine,
  OfflinePendingParcelDataLineState,
  OfflinePendingParcels,
  OfflinePendingSimpleState,
  OfflinePendingRiskAnalysis,
  OfflinePendingSection,
  OfflinePendingUnmatchedChecks,
  OfflineStandardPendingGrid,
  OfflineSurveyPendingGrid,
  PendingInspectionMap,
} from '../../../offline/store/model';
import {
  AttachedFile,
  DataDocumentAttachedFile,
  DataDocumentCompleted,
  DataDocumentCompletedValue,
  DocumentAttachedFile,
  GridCheckpoint,
  GridCheckpointValue,
  GridMetaDisplay,
  GridMetaType,
  InspectionCheckResult,
  NonComplianceActionType,
  ParcelDataLine,
  PendingSyncConclusion,
  PendingSyncExistingNonCompliance,
  PendingSyncExistingParcelLine,
  PendingSyncGrid,
  PendingSyncGridMeta,
  PendingSyncIgnoredGrid,
  PendingSyncInspection,
  PendingSyncNewNonCompliance,
  PendingSyncNewParcelLine,
  PendingSyncNonCompliance,
  PendingSyncParcel,
  PendingSyncParcelLine,
  PendingSyncParcelLineType,
  PendingSyncPayload,
  PendingSyncUnmatchedChecks,
} from '../../model';

import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as R from 'fp-ts/Record';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as E from 'fp-ts/Either';
import * as S from 'fp-ts/string';
import { sequenceS, sequenceT } from 'fp-ts/Apply';
import { flow, identity, pipe } from 'fp-ts/function';
import { filterEmptyStringToNullable, filterEmptyStringToOption } from '@shared/utils/string';
import { getFlattenCheckpoints } from '../../../offline/store/utils/checkpoints';
import {
  isOfflinePendingGridCheckDataDocument,
  isOfflinePendingGridCheckFileDocument,
} from '../../../offline/store/utils/grids';
import { isNewNonCompliance } from '../../../offline/store/utils/nonCompliances';
import { filterProduction } from '../../../offline/store/utils/production';
import { CoverPage } from '../../../offline/cover-page/model';
import { logSentryMessage } from '@shared/utils/sentry';
import { isRiskAnalysisComplete } from '../../../offline/risk-analysis/utils';
import { isCoverPageComplete, mapCoverPage } from '../../../offline/cover-page/utils';

export function mapToPendingSyncPayload(inspectionMap: PendingInspectionMap): PendingSyncPayload {
  const offlineInspections = Object.values<OfflinePendingInspection>(inspectionMap);

  return {
    inspections: offlineInspections.map(mapInspection),
    ignoredGrids: pipe(
      offlineInspections,
      A.chain(({ grids, riskAnalysis, coverPage }) =>
        pipe(
          grids,
          isRiskAnalysisComplete(riskAnalysis) && isCoverPageComplete(coverPage) // condition to add or not Finished grids
            ? A.filter(({ state }) => [OfflineGridState.Pending, OfflineGridState.ReadyToSign].includes(state))
            : A.filter(({ state }) => state !== OfflineGridState.NotStarted),
          A.map(({ id, state, ...payload }) => ({ id, state, payload })),
        ),
      ),
      NEA.fromArray,
      O.toNullable,
    ),
  };
}

function mapInspection(inspection: OfflinePendingInspection): PendingSyncInspection {
  return {
    id: inspection.id,
    operatorEmail: typeof inspection.operatorEmail === 'string' ? inspection.operatorEmail : null,
    cci: inspection.cci,
    grids: isRiskAnalysisComplete(inspection.riskAnalysis) ? mapGrids(inspection, inspection.nonCompliances) : [],
    unmatchedChecks: mapUnmatchedChecks(inspection.unmatchedChecks),
    nonCompliances: mapNonCompliances(inspection.nonCompliances),
    production: filterProduction(inspection.parcels.production),
    parcels: mapParcels(inspection.parcels),
    isUnannounced: inspection.isUnannounced,
    riskAnalysisGrids: mapRiskAnalysis(inspection.riskAnalysis),
  };
}

function mapRiskAnalysis(
  riskAnalysis: OfflinePendingRiskAnalysis | null | undefined,
): PendingSyncInspection['riskAnalysisGrids'] {
  return pipe(
    O.fromNullable(riskAnalysis),
    O.filter(({ state }) => {
      const isFinished = state === OfflinePendingSimpleState.Finished;
      if (!isFinished) logSentryMessage(`Risk analysis has been lost`, 'warning');
      return isFinished;
    }),
    O.map(({ riskAnalysisGrids }) => riskAnalysisGrids),
    O.toNullable,
  );
}

function mapUnmatchedChecks(unmatchedChecks: OfflinePendingUnmatchedChecks | null): PendingSyncUnmatchedChecks | null {
  return pipe(
    O.fromNullable(unmatchedChecks),
    O.filter(unmatchedChecks => unmatchedChecks.state === OfflineGridState.Finished),
    O.map<OfflinePendingUnmatchedChecks, PendingSyncUnmatchedChecks>(unmatchedChecks => ({
      checkIds: unmatchedChecks.checkIds,
    })),
    O.toNullable,
  );
}

function mapGrids(
  inspection: OfflinePendingInspection,
  nonCompliances: Array<OfflinePendingNonCompliance>,
): Array<PendingSyncGrid> {
  return pipe(
    inspection.grids,
    A.map(grid => mapGrid(grid, nonCompliances, inspection.coverPage)),
    A.compact,
  );
}

function mapGrid(
  grid: OfflinePendingGrid,
  nonCompliances: Array<OfflinePendingNonCompliance>,
  coverPage: CoverPage.Pending | null | undefined,
): O.Option<PendingSyncGrid> {
  const result = pipe(
    nonCompliances,
    A.filter(isNewNonCompliance),
    A.filter(nonCompliance => nonCompliance.gridId === grid.id),
    NEA.fromArray,
    O.fold(
      () => InspectionCheckResult.Compliant,
      () => InspectionCheckResult.NonCompliant,
    ),
  );

  return grid.gridType === GridType.Standard
    ? mapStandardGrid(grid, result, coverPage)
    : mapSurveyGrid(grid, result, coverPage);
}

function mapStandardGrid(
  grid: OfflineStandardPendingGrid,
  result: InspectionCheckResult,
  coverPage: CoverPage.Pending | null | undefined,
): O.Option<PendingSyncGrid> {
  const { id, checkIds, checkScopes, metaSections, sections, conclusion, gridType } = grid;
  return pipe(
    sequenceS(O.Apply)({
      conclusion: mapConclusion(conclusion, result),
      attachedFiles: mapAttachedFiles(grid),
      checkpoints: mapCheckpoints(sections),
      coverPage: mapCoverPage(coverPage),
    }),
    O.map(({ conclusion, attachedFiles, checkpoints, coverPage }) => ({
      id,
      checkIds,
      checkScopes,
      headerMetas: mapMetas(metaSections, GridMetaDisplay.Header),
      checkpoints,
      footerMetas: mapMetas(metaSections, GridMetaDisplay.Footer),
      conclusion,
      attachedFiles,
      coverPage,
      gridType,
      largeLabels: grid.largeLabels ?? false,
    })),
  );
}

function mapSurveyGrid(
  grid: OfflineSurveyPendingGrid,
  result: InspectionCheckResult,
  coverPage: CoverPage.Pending | null | undefined,
): O.Option<PendingSyncGrid> {
  const { id, checkIds, checkScopes, metaSections, sections, conclusion, gridType } = grid;
  return pipe(
    sequenceS(O.Apply)({
      conclusion: mapConclusion(conclusion, result),
      attachedFiles: mapAttachedFiles(grid),
      coverPage: mapCoverPage(coverPage),
    }),
    O.map(({ conclusion, attachedFiles, coverPage }) => ({
      id,
      checkIds,
      checkScopes,
      headerMetas: mapMetas(metaSections, GridMetaDisplay.Header),
      checkpoints: pipe(sections, getFlattenCheckpoints, A.filterMap(mapCheckpoint)),
      footerMetas: mapMetas(metaSections, GridMetaDisplay.Footer),
      conclusion,
      attachedFiles,
      coverPage,
      gridType,
      largeLabels: grid.largeLabels ?? false,
    })),
  );
}

function mapMetas(
  metaSections: Array<OfflinePendingMetaSection>,
  display: GridMetaDisplay,
): Array<PendingSyncGridMeta> {
  return pipe(
    metaSections,
    A.filter(section => section.display === display),
    A.chain(section =>
      pipe(
        section.metas,
        A.filterMap(meta => mapMeta(section.id, meta)),
      ),
    ),
  );
}

function mapMeta(sectionId: string, meta: OfflinePendingMeta): O.Option<PendingSyncGridMeta> {
  const label = O.some(meta.label);
  const comment = O.some(filterEmptyStringToNullable(meta.comment));
  const section = O.some(sectionId);

  switch (meta.type) {
    case GridMetaType.Text:
      return sequenceS(O.Apply)({
        type: O.some(meta.type),
        label,
        comment,
        section,
        value: filterEmptyStringToOption(meta.value),
      });
    case GridMetaType.Numeric:
      return sequenceS(O.Apply)({
        type: O.some(meta.type),
        label,
        comment,
        section,
        value: O.fromNullable(meta.value),
      });
    case GridMetaType.Date:
      return sequenceS(O.Apply)({
        type: O.some(meta.type),
        label,
        comment,
        section,
        value: filterEmptyStringToOption(meta.value),
      });
    case GridMetaType.Boolean:
      return sequenceS(O.Apply)({
        type: O.some(meta.type),
        label,
        comment,
        section,
        value: pipe(O.fromNullable(meta.value), O.filter(identity)),
      });
    default:
      return O.none;
  }
}

function mapCheckpoints(sections: Array<OfflinePendingSection>): O.Option<Array<GridCheckpoint>> {
  return pipe(getFlattenCheckpoints(sections), A.traverse(O.Applicative)(mapCheckpoint));
}

function mapCheckpoint(checkpoint: OfflinePendingCheckPoint): O.Option<GridCheckpoint> {
  return sequenceS(O.Apply)({
    id: O.some(checkpoint.id),
    value: mapCheckpointValue(checkpoint.value),
    comment: O.some(filterEmptyStringToNullable(checkpoint.comment)),
  });
}

function mapCheckpointValue(value: OfflineCheckpointValue | null): O.Option<GridCheckpointValue> {
  switch (value) {
    case OfflineCheckpointValue.Compliant:
      return O.some(GridCheckpointValue.Compliant);
    case OfflineCheckpointValue.Failure:
      return O.some(GridCheckpointValue.Failure);
    case OfflineCheckpointValue.Inapplicable:
    case OfflineCheckpointValue.Filtered:
      return O.some(GridCheckpointValue.Inapplicable);
    default:
      return O.none;
  }
}

function mapNonCompliances(nonCompliances: Array<OfflinePendingNonCompliance>): Array<PendingSyncNonCompliance> {
  return pipe(nonCompliances, A.filterMap(mapNonCompliance));
}

function mapNonCompliance(nonCompliance: OfflinePendingNonCompliance): O.Option<PendingSyncNonCompliance> {
  return nonCompliance.type === 'new'
    ? mapNewNonCompliance(nonCompliance)
    : O.some(mapExistingNonCompliance(nonCompliance));
}

function mapNewNonCompliance(nonCompliance: OfflinePendingNewNonCompliance): O.Option<PendingSyncNewNonCompliance> {
  return sequenceS(O.Apply)({
    type: O.some('new' as const),
    codeId: O.fromNullable(nonCompliance.codeId),
    recordType: O.fromNullable(nonCompliance.recordType),
    gravity: O.fromNullable(nonCompliance.gravity),
    description: filterEmptyStringToOption(nonCompliance.description),
    impact: O.some(filterEmptyStringToNullable(nonCompliance.impact)),
    correctiveAction: O.some(filterEmptyStringToNullable(nonCompliance.correctiveAction)),
    implementationDate: O.some(filterEmptyStringToNullable(nonCompliance.implementationDate)),
    comment: O.some(filterEmptyStringToNullable(nonCompliance.comment)),
    requirement: O.some(filterEmptyStringToNullable(nonCompliance.requirement)),
    checkIds: O.some(nonCompliance.checkIds),
  });
}

function mapExistingNonCompliance(
  nonCompliance: OfflinePendingExistingNonCompliance,
): PendingSyncExistingNonCompliance {
  const recordType = pipe(
    O.fromNullable(nonCompliance.action),
    O.getOrElse(() => NonComplianceActionType.Renewed),
  );

  return {
    type: 'existing',
    id: nonCompliance.id,
    recordType,
    note: filterEmptyStringToNullable(nonCompliance.note),
  };
}

function mapParcelNewLine(line: ParcelDataLine): PendingSyncNewParcelLine {
  return {
    type: PendingSyncParcelLineType.New,
    rows: pipe(
      line.rows,
      R.filter(row => row.value != null),
    ),
  };
}

function mapParcelExistingLine(line: OfflinePendingParcelDataLine): PendingSyncExistingParcelLine {
  return {
    id: line.id,
    type: PendingSyncParcelLineType.Existing,
    rows: pipe(
      line.rows,
      R.filter(row => row.value != null),
    ),
    updated: line.state === OfflinePendingParcelDataLineState.Updated,
    deleted: line.state === OfflinePendingParcelDataLineState.Deleted,
  };
}

function mapParcelLine(line: OfflinePendingParcelDataLine): PendingSyncParcelLine {
  if (line.state === OfflinePendingParcelDataLineState.New) {
    return mapParcelNewLine(line);
  } else {
    return mapParcelExistingLine(line);
  }
}

function mapParcel(parcel: OfflinePendingParcel): PendingSyncParcel {
  return {
    template: parcel.template.code,
    lines: pipe(
      parcel.lines,
      A.map(line => mapParcelLine(line)),
    ),
  };
}

function mapParcels(parcels: OfflinePendingParcels): Array<PendingSyncParcel> {
  return pipe(
    parcels.parcels,
    A.map(parcel => mapParcel(parcel)),
  );
}

function mapConclusion(
  conclusion: OfflinePendingGridConclusion,
  result: InspectionCheckResult,
): O.Option<PendingSyncConclusion> {
  return sequenceS(O.Apply)({
    startTime: O.fromNullable(conclusion.startTime),
    endTime: O.fromNullable(conclusion.endTime),
    result: O.some(result),
    comment: O.fromNullable(conclusion.comment),
    metPeople: O.some(filterEmptyStringToNullable(conclusion.metPeople)),
    accompanyingPeople: O.some(filterEmptyStringToNullable(conclusion.accompanyingPeople)),
    auditorSignature: O.some(conclusion.auditorSignature),
    operatorSignature: O.some(conclusion.operatorSignature),
    operatorEmail: O.some(conclusion.operatorEmail),
    cci: O.some(conclusion.cci),
  });
}

function mapOfflinePendingGridCheckFileDocument(
  document: OfflinePendingGridCheckFileDocument,
): O.Option<DocumentAttachedFile> {
  return pipe(
    O.fromNullable(document.fileOrReason),
    O.map<OfflinePendingGridCheckFileDocumentFileOrReason, Pick<DocumentAttachedFile, 'fileId' | 'reason'>>(
      fileOrReason => {
        if (fileOrReason.type === 'file') {
          return {
            fileId: fileOrReason.fileId,
            reason: null,
          };
        } else {
          return {
            fileId: null,
            reason: fileOrReason.reason,
          };
        }
      },
    ),
    O.map(fileOrReason => ({
      type: 'document',
      documentId: document.id,
      comment: filterEmptyStringToNullable(document.comment),
      fileId: fileOrReason.fileId,
      reason: fileOrReason.reason,
    })),
  );
}

function mapOfflinePendingGridCheckDataDocument(
  document: OfflinePendingGridCheckDataDocument,
): O.Option<DataDocumentAttachedFile> {
  const mapCompleted = (completed: DataDocumentCompleted): DataDocumentCompleted => {
    const filterValues = (
      values: Record<string, DataDocumentCompletedValue>,
    ): Record<string, DataDocumentCompletedValue> => {
      return pipe(
        values,
        R.filter(value => value.value != null && value.value !== ''),
      );
    };

    return {
      settings: completed.settings,
      values: completed.values.map((value, i) => ({ order: i + 1, values: filterValues(value.values) })),
    };
  };

  return pipe(
    O.fromNullable(document.completedOrReason),
    O.map(
      flow(
        E.fromPredicate(S.isString, completed => completed as DataDocumentCompleted),
        E.foldW(
          completed => ({ completed: mapCompleted(completed), reason: null }),
          reason => ({ reason, completed: null }),
        ),
      ),
    ),
    O.map(({ completed, reason }) => ({
      type: 'data-document',
      id: document.id,
      name: document.label,
      comment: document.comment,
      completed: completed,
      reason,
    })),
  );
}

function mapAttachedFiles(grid: OfflinePendingGrid): O.Option<Array<AttachedFile>> {
  const appendixAttachedFiles = grid.attachedFiles;

  const checkFilesDocuments = pipe(
    grid.documents,
    A.filter(isOfflinePendingGridCheckFileDocument),
    A.traverse(O.Applicative)(mapOfflinePendingGridCheckFileDocument),
  );

  const checkDataDocuments = pipe(
    grid.documents,
    A.filter(isOfflinePendingGridCheckDataDocument),
    A.traverse(O.Applicative)(mapOfflinePendingGridCheckDataDocument),
  );

  return pipe(
    sequenceT(O.Apply)(O.some(appendixAttachedFiles), checkFilesDocuments, checkDataDocuments),
    O.map<AttachedFile[][], AttachedFile[]>(A.flatten),
  );
}

export function mapIgnoredGridAttachedFiles(grid: PendingSyncIgnoredGrid['payload']): Array<AttachedFile> {
  return pipe(
    grid.documents,
    A.filter(isOfflinePendingGridCheckFileDocument),
    A.filterMap(mapOfflinePendingGridCheckFileDocument),
    checkFiles => [...grid.attachedFiles, ...checkFiles],
  );
}
