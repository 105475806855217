import { defaultOfflineIncomingState, OfflineIncomingState } from './incoming';
import { defaultOfflinePendingState, OfflinePendingState } from './pending';
import { defaultOfflineReferentialState, OfflineReferentialState } from './referential';

export interface OfflineState {
  incoming: OfflineIncomingState;
  pending: OfflinePendingState;
  referential: OfflineReferentialState;
}

export const defaultOfflineState: OfflineState = {
  incoming: defaultOfflineIncomingState,
  pending: defaultOfflinePendingState,
  referential: defaultOfflineReferentialState,
};

export * from './incoming';
export * from './pending';
export * from './referential';
