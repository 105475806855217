import { Color, ExecutionContext } from 'styled-components';
import theme from '../theme';

type ColorKey = Extract<keyof Color, number>;

function getColor(color: Color, defaultKey: ColorKey, key?: ColorKey): string {
  return color[key ?? defaultKey];
}

export function primaryColor(key?: ColorKey): (props: ExecutionContext) => string {
  return props => getColor(props.theme.colors.primary, 600, key);
}

export function secondaryColor(key?: ColorKey): (props: ExecutionContext) => string {
  return props => getColor(props.theme.colors.secondary, 500, key);
}

export function tertiaryColor(key?: ColorKey): (props: ExecutionContext) => string {
  return props => getColor(props.theme.colors.tertiary, 800, key);
}

export function whiteColor(props: ExecutionContext): string {
  return props.theme.colors.white;
}

export function blackColor(props: ExecutionContext): string {
  return props.theme.colors.black;
}

export function alertColor(props: ExecutionContext): string {
  return props.theme.colors.alert;
}

export function offlineColor(props: ExecutionContext): string {
  return props.theme.colors.offline;
}

export const backgroundColor = (Object.keys(
  theme.colors.background,
) as (keyof typeof theme.colors.background)[]).reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: (props: ExecutionContext) => props.theme.colors.background[curr],
  }),
  {} as { [key in keyof typeof theme.colors.background]: (props: ExecutionContext) => string },
);

export const textColor = (Object.keys(theme.colors.text) as (keyof typeof theme.colors.text)[]).reduce(
  (acc, curr) => ({
    ...acc,
    [curr]: (props: ExecutionContext) => props.theme.colors.text[curr],
  }),
  {} as { [key in keyof typeof theme.colors.text]: (props: ExecutionContext) => string },
);
