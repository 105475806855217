import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Link, LinkProps } from 'react-router-dom';
import { fontSize, fontWeight, primaryColor, textColor } from '../utils';

export type ButtonLevel = 'primary' | 'secondary' | 'tertiary';

export interface ButtonProps {
  $level?: ButtonLevel;
  disabled?: boolean;
  $isLoading?: boolean;
}

const spinnerAnimation = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

function getButtonStyle({ $level }: ButtonProps) {
  switch ($level) {
    case 'secondary':
      return css`
        padding: 13px 30px;
        font-size: ${fontSize.button};
        border: none;
        border-radius: 100px;
        box-shadow: 0 3px 6px 0 rgba(232, 91, 63, 0.2);
        background-color: ${primaryColor(100)};
        color: ${primaryColor()};
        font-weight: ${fontWeight.medium};
      `;
    case 'tertiary':
      return css`
        padding: 11px 22px;
        font-size: ${fontSize.small};
        border: 1px solid ${primaryColor()};
        border-radius: 100px;
        background: none;
        color: ${primaryColor()};
        font-weight: ${fontWeight.regular};
      `;
    default:
      return css`
        padding: 13px 30px;
        font-size: ${fontSize.button};
        border: none;
        border-radius: 100px;
        box-shadow: 0 3px 6px 0 rgba(232, 91, 63, 0.2);
        background-image: linear-gradient(to bottom, ${primaryColor()}, ${primaryColor(500)});
        color: ${textColor.inverted};
        font-weight: ${fontWeight.semiBold};
      `;
  }
}

const buttonStyle = css<ButtonProps>`
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-family: inherit;
  letter-spacing: normal;
  transition: all 0.2s linear;
  text-align: center;
  line-height: 1;
  text-decoration: none;
  -webkit-appearance: none;

  ${props => getButtonStyle(props)};

  ${props =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `};

  ${props =>
    props.$isLoading &&
    css`
      position: relative;
      pointer-events: none;
      color: transparent;
      user-select: none;

      &:before {
        border-radius: 50%;
        animation: ${spinnerAnimation} 1.2s linear infinite;
        content: '';
        position: absolute;
        height: 16px;
        width: 16px;
        top: calc(50% - (21px / 2));
        left: calc(50% - (19px / 2));
        border: 2px solid ${props.$level === 'primary' ? textColor.inverted : primaryColor()};
        border-top-color: transparent;
      }
    `};
`;

export const Button = styled.button.attrs<ButtonProps, Required<ButtonProps>>(props => ({
  $level: props.$level ?? 'primary',
  disabled: !!props.disabled || !!props.$isLoading,
  $isLoading: !!props.$isLoading,
}))`
  ${buttonStyle};
`;

export const LinkButton = styled(({ $level, disabled, $isLoading, ...rest }: LinkProps & ButtonProps) => (
  <Link {...rest} />
)).attrs<ButtonProps, Required<ButtonProps>>(props => ({
  $level: props.$level ?? 'primary',
  disabled: !!props.disabled,
  $isLoading: !!props.$isLoading,
}))`
  ${buttonStyle};
`;
