import axios, { AxiosHeaders } from 'axios';
import { v4 as uuid } from 'uuid';

import { TRANSACTION_ID_HEADER_KEY } from '.';
import { camelToSnake } from '@shared/utils/string';

import * as T from 'fp-ts/Task';
import * as TO from 'fp-ts/TaskOption';
import * as R from 'fp-ts/Record';
import * as S from 'fp-ts/string';
import { pipe } from 'fp-ts/function';
import { getAuthToken } from '@modules/auth/service';

import queryString from 'query-string';
import { sequenceS } from 'fp-ts/Apply';

const ACCEPT_HEADER = 'application/vnd.qualisud.v1+json';

function convertParamsToSnakeCase(params: Record<string, any>): Record<string, any> {
  return pipe(
    params,
    R.reduceWithIndex(S.Ord)({}, (key, acc, value) => ({
      ...acc,
      [camelToSnake(key)]: value,
    })),
  );
}

function getCommonHeaders(): T.Task<AxiosHeaders> {
  return T.of(
    AxiosHeaders.from({
      Accept: ACCEPT_HEADER,
      [TRANSACTION_ID_HEADER_KEY]: uuid(),
    }),
  );
}

function getBearerHeader(): T.Task<AxiosHeaders> {
  return pipe(
    getAuthToken(),
    TO.match(
      () => AxiosHeaders.from({}),
      ({ token }) =>
        AxiosHeaders.from({
          Bearer: token,
        }),
    ),
  );
}

function paramsSerializer(params: any): string {
  return queryString.stringify(params);
}

export const axiosInstance = axios.create({
  paramsSerializer,
  withCredentials: true,
});

axiosInstance.interceptors.request.use(config => {
  return pipe(
    sequenceS(T.ApplyPar)({
      bearer: getBearerHeader(),
      common: getCommonHeaders(),
    }),
    T.map(({ bearer, common }) => ({
      ...config,
      headers: AxiosHeaders.concat(bearer, config.headers, common),
      params: config.params ? convertParamsToSnakeCase(config.params) : undefined,
    })),
  )();
});
