import * as T from 'fp-ts/Task';
import { flow, identity, pipe } from 'fp-ts/function';
import { sequenceT } from 'fp-ts/Apply';
import * as E from 'fp-ts/Either';
import * as A from 'fp-ts/Array';
import * as Eq from 'fp-ts/Eq';

type PatternMap<E extends string, T> = { [K in E]: T extends { type: K } ? T : never };
export type Pattern<E extends string, T, R> = {
  [K in keyof PatternMap<E, T>]: (value: PatternMap<E, T>[K]) => R;
};

export function matcher<E extends string, T extends { type: E }, R>(pattern: Pattern<E, T, R>): (value: T) => R | null {
  return value => {
    const fn = pattern[value.type];

    return fn ? fn(value as any) : null;
  };
}

export function minDuration<A>(t: T.Task<A>, duration: number): T.Task<A> {
  return pipe(
    sequenceT(T.ApplyPar)(t, T.delay(duration)(T.of(true))),
    T.map(([value]) => value),
  );
}

export function isNotNull<T>(val: T | null): val is T {
  return val !== null;
}

export function isDefined<T>(val: T | null | undefined): val is T {
  return val != null;
}

export function toggleArray<T>(Eq: Eq.Eq<T>): (value: T) => (array: Array<T>) => Array<T> {
  return value =>
    flow(
      E.fromPredicate(A.elem<T>(Eq)(value), identity),
      E.fold(A.union<T>(Eq)([value]), activities => A.getDifferenceMagma<T>(Eq).concat(activities, [value])),
    );
}
