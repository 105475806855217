import { createGlobalStyle } from 'styled-components';
import { backgroundColor, fontFamily, fontSize, fontWeight, primaryColor, textColor } from './utils';

export const GlobalStyle = createGlobalStyle`
  /* RESET | meyerweb.com/eric/tools/css/reset | v2.0 | 20110126 */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  input, textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  button {
    -webkit-appearance: none;
  }
  /* END OF RESET */
  body, html {
    background: ${backgroundColor.default};
    font-family: ${fontFamily};
    font-weight: ${fontWeight.regular};
    font-size: ${fontSize.standard};
    line-height: 1.67;
    color: ${textColor.default};
  }

  body, html, #root {
    height: 100%;
    overflow: hidden;
  }
  
  *::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 10px;
    height: 10px;
  }
  
  ::selection {
    background: ${primaryColor(100)};  
  }
  
  *::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;
   }
   
  *::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0;
  }
  
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  .react-next-dates {
    --color-primary: ${primaryColor()};
    --font-size-large: ${fontSize.standard};

    &.popper {
      z-index: 11000;
    }
  }
`;
