import { httpService, HttpTask } from '@core/http';
import { PendingSyncPayload, SyncIncomingInspection } from './model';
import { OfflineFile } from '@shared/modules/files';

import * as TE from 'fp-ts/TaskEither';
import * as TO from 'fp-ts/TaskOption';
import * as T from 'fp-ts/Task';
import { pipe } from 'fp-ts/function';

import * as fileStorageService from './file-storage';
import { constVoid } from 'fp-ts/function';
import { formatDate } from '@shared/utils/date';

const URI = '/api/pwa/sync';

export function checkSyncIncomingAllowed(date: Date): T.Task<boolean> {
  return pipe(
    httpService.get<{ allowed: boolean }>(`${URI}/incoming/inspections/${formatDate(date, 'dd-MM-yyyy')}/allowed`),
    TE.fold(
      () => T.of(false),
      ({ allowed }) => T.of(allowed),
    ),
  );
}

export function syncIncomingInspections(date: Date): HttpTask<Array<SyncIncomingInspection>> {
  return httpService.post(`${URI}/incoming/inspections/${formatDate(date, 'dd-MM-yyyy')}`);
}

export function syncOutgoing(payload: PendingSyncPayload): HttpTask<{ id: string }, { code: string }> {
  return httpService.post(`${URI}/outgoing`, payload);
}

function sendOutgoingFile(file: OfflineFile): HttpTask<void> {
  const formData = new FormData();
  formData.append('file', file.content);
  formData.append('data', JSON.stringify({ id: file.id }));

  return httpService.post(`${URI}/outgoing/files`, formData);
}

export function sendOutgoingFileFromId(id: string): HttpTask<void> {
  return pipe(
    fileStorageService.getFile(id),
    TO.fold(() => TE.right(constVoid()), sendOutgoingFile),
  );
}

export function finishOutgoingSync(id: string, unscheduleInspections: boolean): HttpTask<void> {
  return httpService.post(`${URI}/outgoing/${id}/finish`, { unscheduleInspections });
}
