import { BioProductReferential, NonComplianceReferential } from '../../../referential/model';

export interface OfflineReferentialState {
  nonCompliances: Array<NonComplianceReferential>;
  bioProducts: Array<BioProductReferential>;
}

export const defaultOfflineReferentialState: OfflineReferentialState = {
  nonCompliances: [],
  bioProducts: [],
};
