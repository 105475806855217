import { OfflinePendingMeta, OfflinePendingMetaSection } from '../model';
import { SyncIncomingGridMeta, SyncIncomingGridMetaSection } from '../../../sync/model';

import { v4 as uuid } from 'uuid';

import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import * as NEA from 'fp-ts/NonEmptyArray';
import { pipe } from 'fp-ts/function';
import { getEqOrder } from './checkpoints';

export function mapMetaSections(
  metaSections: Array<SyncIncomingGridMetaSection>,
  metas: Array<SyncIncomingGridMeta>,
): Array<OfflinePendingMetaSection> {
  return pipe(
    metaSections,
    A.sort(getEqOrder()),
    A.filterMap(section => mapMetaSection(section, metas)),
  );
}

function mapMetaSection(
  section: SyncIncomingGridMetaSection,
  metas: Array<SyncIncomingGridMeta>,
): O.Option<OfflinePendingMetaSection> {
  const sectionMetas = pipe(
    metas,
    A.filter(meta => meta.sectionId === section.id),
    A.sort(getEqOrder()),
    A.map(mapMeta),
    NEA.fromArray,
  );

  return pipe(
    sectionMetas,
    O.map(metas => ({
      id: section.id,
      name: section.name,
      display: section.display,
      metas,
    })),
  );
}

function mapMeta(meta: SyncIncomingGridMeta): OfflinePendingMeta {
  return {
    id: uuid(),
    type: meta.type,
    label: meta.label,
    isCommentable: meta.isCommentable,
    value: null,
    comment: null,
  };
}
