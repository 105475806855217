import * as O from 'fp-ts/Option';
import { ReactNode } from 'react';
import { pipe } from 'fp-ts/function';
import { constNull } from 'fp-ts/function';

export function renderOptional<T, R extends ReactNode>(
  opt: O.Option<T>,
  onSome: (data: T) => R | null,
  onNone: () => R | null = constNull,
): R | null {
  return pipe(opt, O.fold(onNone, onSome));
}

export function renderNullable<T, R extends ReactNode>(
  value: T | null | undefined,
  onSome: (data: T) => R | null,
  onNone: () => R | null = constNull,
): R | null {
  return renderOptional(O.fromNullable(value), onSome, onNone);
}
