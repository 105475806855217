import { GridType } from '../../offline/store/model';
import { StringifiableRecord } from 'query-string';
import { SyncIncomingNonCompliance } from '../../sync/model';
import { MetricCode } from '@modules/online/metrics/model';

export interface UnscheduledInspection {
  id: string;
  code: string;
  siteName: string;
  expectedDuration: number;
  extendedName: string;
  maxDate: string;
  sitePostalCode: string;
  siteCity: string;
  siteDistrict: string;
  fulfillment: string;
  hasAdditionalCheck: boolean;
  isBooked: boolean;
  isPartial: boolean;
  isImperative: boolean;
  isDeleted: boolean;
  hasNonCompliance: boolean;
  urgency: UnscheduledInspectionUrgency | null;
}

export enum UnscheduledInspectionUrgency {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export interface UnscheduledInspectionRangeFilter extends StringifiableRecord {
  isPartial?: boolean;
  hasAdditionalCheck?: boolean;
  isBooked?: boolean;
  isImperative?: boolean;
  hasNonCompliance?: boolean;
  minDate?: string;
  district?: Array<string>;
  operatorType?: Array<string>;
  product?: Array<string>;
  sort?: UnscheduledInspectionRangeSort;
  search?: string;
  dashboard_metric?: MetricCode;
}

export enum UnscheduledInspectionRangeSort {
  District = 'district',
  City = 'city',
  PostalCode = 'postalCode',
}

export interface UnscheduledInspectionTotal {
  total: number;
  booked: number;
}

export interface UnscheduledInspectionTotalWithRange extends UnscheduledInspectionTotal {
  totalRange: number;
}

export interface OperatorType {
  id: string;
  code: string;
  label: string;
}

export interface ProductSpecification {
  code: string;
  label: string;
}

export interface InspectionDetailNonCompliances extends Omit<SyncIncomingNonCompliance, 'code'> {
  code: string;
}

export interface InspectionDetail {
  id: string;
  code: string;
  hasAdditionalCheck: boolean;
  extendedName: string;
  isBooked: boolean;
  isPartial: boolean;
  isImperative: boolean;
  maxDate: string;
  site: InspectionSite;
  operator: InspectionOperator | null;
  urgency: UnscheduledInspectionUrgency | null;
  expectedDuration: number;
  schedule: ScheduleParams | null;
  checks: Array<InspectionCheck>;
  nonCompliances: Array<InspectionDetailNonCompliances>;
  documents: Array<InspectionDocument>;
  comment: string | null;
  production: InspectionProduction;
  memo: string | null;
  isUnannounced: boolean;
}

export interface InspectionSite {
  id: string;
  name: string;
  address: string;
  city: string;
  code: string;
  comment: string | null;
  district: string | null;
  phoneNumber: string | null;
  postalCode: string;
  siret: string | null;
  secondaryPhoneNumber: string | null;
  secondaryAddress: string | null;
  secondaryCity: string | null;
  secondaryPostalCode: string | null;
  managerName: string | null;
  managerPhoneNumber: string | null;
  managerTitle: string | null;
}

export interface InspectionOperator {
  id: string;
  email: string;
}

export interface InspectionCheck {
  id: string;
  code: string;
  type: InspectionCheckType;
  reference: string;
  caseName: string;
  procedure: InspectionCheckProcedure;
  specification: InspectionCheckSpecification;
  operatorType: OperatorType;
  group: string | null;
  gridId: string | null;
  isAdditional: boolean;
  gridType: GridType;
}

export enum InspectionCheckType {
  Normal = 'N',
  Additional = 'S',
}

export interface InspectionCheckProcedure {
  id: string;
  code: string;
  label: string;
}

export interface InspectionCheckSpecification {
  id: string;
  code: string;
  label: string;
}

export interface InspectionProduction {
  notifiedNumber: string | null;
  pastureNumber: string | null;
  bioActivityCode: string | null;
  diversity: boolean | null;
  // Warning ! NonNegInt -> value >= 0
  risk: number | null;
}

export interface InspectionDocument {
  id: string;
  name: string;
  contentType: string;
  url: string | null;
}

export interface BookedTotal {
  total: number;
  max: number;
}

export interface ScheduleParams {
  date: string;
  startTime: string;
}

export interface ScheduleFormBody {
  date: string;
  startTime: string;
}

export interface ScheduledInspection {
  id: string;
  code: string;
  extendedName: string;
  startTime: string;
  siteName: string;
  expectedDuration: number;
  sitePostalCode: string;
  siteCity: string;
  siteDistrict: string | null;
  isPartial: boolean;
  hasAdditionalCheck: boolean;
  hasUnmatchedChecks: boolean;
  hasMatchedChecks: boolean;
  sharedDocumentCount: number;
  memo: string | null;
  isUnannounced: number;
  hasNonCompliance: boolean;
  hasSurveys: boolean;
}

export enum MapsPreviewSelectionType {
  All = 'all',
  Except = 'except',
  Only = 'only',
}

export interface GeocodeParams {
  type: MapsPreviewSelectionType;
  selection?: Array<string>;
}

export interface GeocodeLocation {
  lat: number;
  lng: number;
}

export interface GeocodeResult {
  id: string;
  name: string;
  location: GeocodeLocation | null;
}

export interface GeocodeRequiredResult extends GeocodeResult {
  location: GeocodeLocation;
}
