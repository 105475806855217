import styled, { css, ExecutionContext, FontSizes, FontWeights } from 'styled-components';
import { fontSize, fontWeight, primaryColor, secondaryColor, textColor } from '../utils';

export type ParagraphColor = 'dark' | 'inverted' | 'primary' | 'secondary';

export interface ParagraphProps {
  size?: Extract<keyof FontSizes, string>;
  $textColor?: ParagraphColor;
  $weight?: Extract<keyof FontWeights, string>;
}

function getRequiredProps(props: ParagraphProps): Required<ParagraphProps> {
  return {
    size: props.size ?? 'standard',
    $textColor: props.$textColor ?? 'dark',
    $weight: props.$weight ?? 'regular',
  };
}

function getParagraphColor(color: ParagraphColor): (props: ExecutionContext) => string {
  return props => {
    switch (color) {
      case 'dark':
        return textColor['default'](props);
      case 'inverted':
        return textColor.inverted(props);
      case 'primary':
        return primaryColor()(props);
      case 'secondary':
        return secondaryColor()(props);
    }
  };
}

function paragraphStyle(paragraphProps: Required<ParagraphProps>) {
  return css`
    color: ${getParagraphColor(paragraphProps.$textColor)};
    font-size: ${fontSize[paragraphProps.size]};
    font-weight: ${fontWeight[paragraphProps.$weight]};
  `;
}

export function getParagraphStyle(props: ParagraphProps) {
  return paragraphStyle(getRequiredProps(props));
}

export const Paragraph = styled.p<ParagraphProps>`
  ${props => paragraphStyle(getRequiredProps(props))};
`;
