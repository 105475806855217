import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as O from 'fp-ts/Option';
import { FileType, OfflineFile } from './model';
import { v4 as uuid } from 'uuid';

type FnTypeMatcher = (contentType: string) => boolean;

/**
 * https://developer.mozilla.org/fr/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
const fileTypeMatcher: { [key in Exclude<FileType, FileType.Other>]: FnTypeMatcher } = {
  [FileType.Pdf]: contentType => contentType === 'application/pdf',
  [FileType.Word]: contentType =>
    contentType === 'application/msword' ||
    contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  [FileType.Excel]: contentType =>
    contentType === 'application/vnd.ms-excel' ||
    contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  [FileType.Image]: contentType => contentType.includes('image/'),
};

export function getFileType(contentType: string): FileType {
  return pipe(
    Object.values(FileType),
    A.findFirst(type => FileType.Other !== type && fileTypeMatcher[type](contentType)),
    O.getOrElse<FileType>(() => FileType.Other),
  );
}

export function mapFileToOfflineFile(file: File): OfflineFile {
  return {
    id: uuid(),
    type: getFileType(file.type),
    content: file,
  };
}

export function dataURLtoBlob(dataURL: string): Blob {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataURL.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ab], { type: mimeString });
}

export function dataURLtoFile(dataUrl: string, name: string): File {
  const blob = dataURLtoBlob(dataUrl);

  return new File([blob], name, { type: blob.type });
}

export function dataUrlToOfflineFile(dataUrl: string, name: string): OfflineFile {
  return mapFileToOfflineFile(dataURLtoFile(dataUrl, name));
}
