import styled from 'styled-components';
import { fontSize, fontWeight, primaryColor, tertiaryColor } from '../../../styles/utils';

export const NavContainer = styled.nav`
  flex: 1 1 auto;
  padding: 0 18px;
`;

export const NavList = styled.ul`
  display: flex;
  height: 100%;
`;

export const NavItem = styled.li`
  flex: 0 0 auto;

  > a {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 18px;
    font-size: ${fontSize.navigation};
    font-weight: ${fontWeight.semiBold};
    text-transform: uppercase;
    text-decoration: none;
    color: ${tertiaryColor(500)};
    transition: all 0.1s linear;

    &:hover {
      color: ${primaryColor()};
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 18px;
      height: 5px;
      width: calc(100% - 36px);
      background-image: linear-gradient(to right, ${primaryColor()}, ${primaryColor(400)});
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 0.1s linear;
    }

    &.active {
      color: ${primaryColor()};

      &:before {
        transform: scaleX(1);
        transform-origin: left;
      }
    }
  }
`;
