import { ConfigState, defaultConfigState } from './model';
import { ConfigAction, ConfigActionsType } from './actions';
import { persistReducer } from 'redux-persist';
import { Reducer } from 'redux';
import { Storage } from 'redux-persist/es/types';

function reducer(state = defaultConfigState, action: ConfigAction): ConfigState {
  if (ConfigActionsType.UPDATE_CONFIG === action.type) {
    return action.payload;
  }

  return state;
}

function getConfigReducer(storage: Storage): Reducer<ConfigState, any> {
  return persistReducer(
    {
      key: 'config',
      version: 1,
      storage,
    },
    reducer,
  ) as any;
}

export default getConfigReducer;
