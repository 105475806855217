import { SyncIncomingInspection } from '../../../sync/model';
import { Action, ActionWithPayload } from '../../../../store';

export enum OfflineIncomingActionType {
  INIT_STATE = 'OFFLINE/INCOMING/INIT',
  RESET_STATE = 'OFFLINE/INCOMING/RESET',
}

export type OfflineIncomingAction =
  | ActionWithPayload<OfflineIncomingActionType.INIT_STATE, InitOfflineIncomingParams>
  | Action<OfflineIncomingActionType.RESET_STATE>;

export interface InitOfflineIncomingParams {
  date: Date;
  inspections: Array<SyncIncomingInspection>;
}

export function initOfflineIncomingState(
  date: Date,
  inspections: Array<SyncIncomingInspection>,
): OfflineIncomingAction {
  return {
    type: OfflineIncomingActionType.INIT_STATE,
    payload: {
      date,
      inspections,
    },
  };
}

export function resetOfflineIncomingState(): OfflineIncomingAction {
  return {
    type: OfflineIncomingActionType.RESET_STATE,
  };
}
