import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as R from 'fp-ts/Record';
import * as S from 'fp-ts/string';
import { parseDate } from './date';
import { StringifiableRecord } from 'query-string';

export function removeEmptyFilters(filters: StringifiableRecord): Record<string, string | string[]> {
  return pipe(
    filters,
    R.reduceWithIndex(S.Ord)({} as Record<string, string | string[]>, (key, acc, value) => {
      if (typeof value === 'string' && value !== '') {
        return {
          ...acc,
          [key]: value,
        };
      } else if (Array.isArray(value) && value.length > 0) {
        return {
          ...acc,
          [key]: value,
        };
      } else if (value === true) {
        return {
          ...acc,
          [key]: 'true',
        };
      }

      return acc;
    }),
  );
}

export function getArrayFilterValue(value?: string | Array<string>): Array<string> | undefined {
  return pipe(
    O.fromNullable(value),
    O.chain(v => (Array.isArray(v) ? O.some(v) : O.some([v]))),
    O.toUndefined,
  );
}

export function getStringFilterValue(value?: string | Array<string>): string | undefined {
  return pipe(
    O.fromNullable(value),
    O.chain(v => (Array.isArray(v) ? O.fromNullable(v[0]) : O.some(v))),
    O.toUndefined,
  );
}

export function getBooleanFilterValue(value?: string | Array<string>): boolean | undefined {
  return getStringFilterValue(value) === 'true' || undefined;
}

export function getDateFilterValue(value?: string | Array<string>): string | undefined {
  return pipe(
    O.fromNullable(getStringFilterValue(value)),
    O.filter(d => O.isSome(parseDate(d))),
    O.toUndefined,
  );
}

export function getEnumFilterValue<E extends string>(
  enumeration: Record<string, E>,
  value?: string | Array<string>,
): E | undefined {
  return pipe(
    O.fromNullable(getStringFilterValue(value) as E | undefined),
    O.filter(value => Object.values(enumeration).includes(value)),
    O.toUndefined,
  );
}
