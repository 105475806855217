import { ActionWithPayload } from '../../../store';
import { AppMode } from './model';

export enum AppActionType {
  CHANGE_MODE = 'APP/CHANGE_MODE',
  CHANGE_NETWORK_AVAILABILITY = 'APP/CHANGE_NETWORK_AVAILABILITY',
}

export type AppAction =
  | ActionWithPayload<AppActionType.CHANGE_MODE, AppMode>
  | ActionWithPayload<AppActionType.CHANGE_NETWORK_AVAILABILITY, boolean>;

export function changeAppMode(mode: AppMode): AppAction {
  return {
    type: AppActionType.CHANGE_MODE,
    payload: mode,
  };
}

export function changeNetworkAvailability(value: boolean): AppAction {
  return {
    type: AppActionType.CHANGE_NETWORK_AVAILABILITY,
    payload: value,
  };
}
