import { httpService, HttpTask } from '@core/http';
import { BioProductReferential, NonComplianceReferential } from './model';
import { OfflineReferentialState } from '../offline/store/model';

import * as TE from 'fp-ts/TaskEither';
import { sequenceS } from 'fp-ts/Apply';

const URI = '/api/pwa/referential';

export function getNonCompliances(): HttpTask<Array<NonComplianceReferential>> {
  return httpService.get(`${URI}/non-compliances`);
}

export function getBioProducts(): HttpTask<Array<BioProductReferential>> {
  return httpService.get(`${URI}/bio-products`);
}

export function getOfflineReferential(): HttpTask<OfflineReferentialState> {
  return sequenceS(TE.ApplyPar)({
    nonCompliances: getNonCompliances(),
    bioProducts: getBioProducts(),
  });
}
