import { EnumLabels } from '../../model/enum';

export enum FileType {
  Pdf = 'pdf',
  Word = 'word',
  Excel = 'excel',
  Image = 'image',
  Other = 'other',
}

export const fileTypesLabels: EnumLabels<FileType> = {
  [FileType.Pdf]: 'pdf',
  [FileType.Word]: 'word',
  [FileType.Excel]: 'excel',
  [FileType.Image]: 'image',
  [FileType.Other]: 'autres',
};

export interface OfflineFile {
  id: string;
  type: FileType;
  content: File;
}
