import { OfflinePendingSimpleState } from '../store/model';
import { CoverPage } from './model';
import { flow, pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import { mapPendingBioCoverPageToBioCoverPage } from './bio/utils';
import { logSentryMessage } from '@shared/utils/sentry';

export const isCoverPageComplete = (cover: CoverPage.Pending | null | undefined) =>
  cover == null || cover.state === OfflinePendingSimpleState.Finished;

export function mapCoverPage(cover: CoverPage.Pending | null | undefined): O.Option<CoverPage | null> {
  const coverPage = pipe(
    O.fromNullable(cover),
    O.foldW(
      () => O.some(null),
      flow(
        O.fromPredicate(({ state }) => state === OfflinePendingSimpleState.Finished),
        O.chain(cover => {
          switch (cover.code) {
            case CoverPage.Code.Bio:
              return mapPendingBioCoverPageToBioCoverPage(cover);
            default:
              return O.none;
          }
        }),
      ),
    ),
  );

  if (O.isNone(coverPage)) {
    logSentryMessage(`Cover page "${cover?.code}" in state "${cover?.state}" has been lost`, 'warning');
  }

  return coverPage;
}
