import { DefaultTheme, Color, BackgroundColors, TextColors } from 'styled-components';

const primary: Color = {
  100: '#ffe9d7',
  200: '#ffe85a',
  300: '#feca32',
  400: '#f8af38',
  500: '#ff8900',
  600: '#e85b3f',
  700: '#c4220d',
  800: '#a84700',
  900: '#6e2e00',
};

const secondary: Color = {
  100: '#eff4f1',
  200: '#cce4d5',
  300: '#88c6a0',
  400: '#1fc72d',
  500: '#018935',
  600: '#01782e',
  700: '#016827',
  800: '#00571f',
  900: '#004618',
};

const tertiary: Color = {
  100: '#ffffff',
  200: '#f2f2f2',
  300: '#e2e2e2',
  400: '#b5b5b5',
  500: '#8b8b8b',
  600: '#62605e',
  700: '#393532',
  800: '#2a2626',
  900: '#000000',
};

const background: BackgroundColors = {
  default: tertiary[100],
  light: '#fdf9f4',
  dark: tertiary[800],
  100: '#f7f7f7',
  200: '#f3f3f3',
  300: '#fdf9f4',
};

const text: TextColors = {
  default: '#000000',
  inverted: tertiary[100],
  highLight: primary[100],
};

const theme: DefaultTheme = {
  colors: {
    white: tertiary[100],
    black: tertiary[900],
    alert: primary[700],
    offline: '#292f4c',
    primary,
    secondary,
    tertiary,
    background,
    text,
  },
  font: {
    family: "'Montserrat', sans-serif",
    weights: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
    sizes: {
      extraLarge: '30px',
      h1: '24px',
      h2: '18px',
      large: '17px',
      button: '16px',
      standard: '15px',
      medium: '14px',
      navigation: '13px',
      small: '12px',
      extraSmall: '11px',
    },
  },
  sizes: {
    content: 1280,
  },
};

export default theme;
