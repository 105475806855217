import { pipe } from 'fp-ts/function';
import * as O from 'fp-ts/Option';
import * as A from 'fp-ts/Array';
import * as R from 'fp-ts/Record';
import * as NEA from 'fp-ts/NonEmptyArray';
import * as Ord from 'fp-ts/Ord';
import * as S from 'fp-ts/string';
import { ParcelDataLine, ParcelTemplate, ParcelTemplateTotal } from '../../../sync/model';
import { sequenceT } from 'fp-ts/Apply';

function calculateTotalByClass(lines: Array<ParcelDataLine>, total: ParcelTemplateTotal): Record<string, number> {
  const tuples: Array<[string, number]> = pipe(
    lines,
    A.filterMap(line => {
      const key = pipe(
        O.fromNullable(line.rows[total.groupBy]?.value),
        O.map(key => key.toString()),
      );

      const value = pipe(
        O.fromNullable(line.rows[total.unit]?.value),
        O.filterMap(value => (typeof value === 'number' ? O.some(value) : O.none)),
      );

      return sequenceT(O.Apply)(key, value);
    }),
  );

  const ordTuple = Ord.contramap(([key]: [string, number]) => key)(S.Ord);

  const group = pipe(tuples, A.sort(ordTuple), NEA.group(ordTuple));

  return pipe(
    group,
    A.reduce({}, (acc, group) => {
      const [key] = NEA.head(group);
      const value = pipe(
        group,
        NEA.reduce(0, (acc, [, value]) => acc + value),
      );
      return {
        ...acc,
        [key]: value,
      };
    }),
  );
}

export function parcelTotalByClass(
  lines: Array<ParcelDataLine>,
  template: ParcelTemplate,
): Record<string, number> | null {
  return pipe(
    O.fromNullable(template.total),
    O.map(total => calculateTotalByClass(lines, total)),
    O.toNullable,
  );
}

export function parcelTotal(lines: Array<ParcelDataLine>, template: ParcelTemplate): number {
  const totalByClass = parcelTotalByClass(lines, template);
  if (totalByClass) {
    return pipe(
      totalByClass,
      R.reduce(S.Ord)(0, (acc, value) => acc + value),
    );
  } else return 0;
}
