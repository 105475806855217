export enum MetricCode {
  ProjectedControlDays = 'projected_control_days',
  RefCurrentYearPlannedVisits = 'ref_current_year_planned_visits',
  RefCurrentYearExpectedDays = 'ref_current_year_expected_days',
  RefPastYearPlannedVisits = 'ref_past_year_planned_visits',
  RefPastYearExpectedDays = 'ref_past_year_expected_days',
  CompletedVisits = 'completed_visits',
  CompletedDays = 'completed_days',
  RemainingVisits = 'remaining_visits',
  RemainingDays = 'remaining_days',
  ExpectedProgress = 'expected_progress',
  ExpectedCompletedDelta = 'expected_completed_delta',
  VisitsDurationRatio = 'visits_duration_ratio',
  AssignedVisits = 'assigned_visits',
  DeletedVisits = 'deleted_visits',
  UpdatedComments = 'updated_comments',
}

export const metricCodeLabel: Record<MetricCode, string> = {
  [MetricCode.AssignedVisits]: 'Nouvelles visites',
  [MetricCode.DeletedVisits]: 'Visites supprimées',
  [MetricCode.UpdatedComments]: 'Nouveaux commentaires',
  [MetricCode.ProjectedControlDays]: '',
  [MetricCode.RefCurrentYearPlannedVisits]: '',
  [MetricCode.RefCurrentYearExpectedDays]: '',
  [MetricCode.RefPastYearPlannedVisits]: '',
  [MetricCode.RefPastYearExpectedDays]: '',
  [MetricCode.CompletedVisits]: '',
  [MetricCode.CompletedDays]: '',
  [MetricCode.RemainingVisits]: '',
  [MetricCode.RemainingDays]: '',
  [MetricCode.ExpectedProgress]: '',
  [MetricCode.ExpectedCompletedDelta]: '',
  [MetricCode.VisitsDurationRatio]: '',
};

export interface Metric {
  code: MetricCode;
  value: number;
}
