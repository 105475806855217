import { OfflineReferentialAction, OfflineReferentialActionType } from '../actions/referential';
import { defaultOfflineReferentialState, OfflineReferentialState } from '../model';
import { Reducer } from 'redux';
import { persistReducer } from 'redux-persist';
import { Storage } from 'redux-persist/es/types';

function reducer(state = defaultOfflineReferentialState, action: OfflineReferentialAction): OfflineReferentialState {
  switch (action.type) {
    case OfflineReferentialActionType.INIT_STATE:
      return action.payload;

    case OfflineReferentialActionType.RESET_STATE:
      return defaultOfflineReferentialState;

    default:
      return state;
  }
}

function getOfflineReferentialReducer(storage: Storage): Reducer<OfflineReferentialState, any> {
  return persistReducer(
    {
      key: 'offline-referential',
      version: 1,
      storage,
    },
    reducer,
  ) as any;
}

export default getOfflineReferentialReducer;
