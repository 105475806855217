import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { primaryColor } from '../../../../styles';

const loadingAnimation = keyframes`
  from {left: -200px; width: 30%;}
  50% {width: 30%;}
  70% {width: 70%;}
  80% { left: 50%;}
  95% {left: 120%;}
  to {left: 100%;}
`;

const LineLoaderStyle = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 100%;
  overflow: hidden;

  &:before {
    display: block;
    position: absolute;
    content: '';
    left: -200px;
    width: 200px;
    height: 2px;
    background-color: ${primaryColor()};
    animation: ${loadingAnimation} 2s linear infinite;
  }
`;

export const LineLoader: FC = () => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const timeout = setTimeout(() => setLoading(true), 800);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return loading ? <LineLoaderStyle /> : null;
};

export default LineLoader;
