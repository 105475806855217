import { RiskAnalysisGridTemplate } from './model';
import { pipe } from 'fp-ts/function';
import * as A from 'fp-ts/Array';
import * as N from 'fp-ts/number';
import * as R from 'fp-ts/Record';
import * as Ord from 'fp-ts/Ord';
import { OfflinePendingRiskAnalysis, OfflinePendingSimpleState } from '../store/model';

export const countIncomingRiskAnalysisCriterion = (riskAnalysisGrids: Array<RiskAnalysisGridTemplate>) =>
  pipe(
    riskAnalysisGrids,
    A.reduce(N.MonoidSum.empty, (total, { sections }) =>
      N.MonoidSum.concat(
        total,
        pipe(
          sections,
          A.reduce(N.MonoidSum.empty, (subTotal, { subSections }) =>
            N.MonoidSum.concat(
              subTotal,
              pipe(
                subSections,
                A.reduce(N.MonoidSum.empty, (subSubTotal, { criteria }) =>
                  N.MonoidSum.concat(subSubTotal, criteria.length),
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  );

export const countPendingRiskAnalysisCriterion = (
  riskAnalysisGrids: Record<RiskAnalysisGridTemplate.Code, Array<RiskAnalysisGridTemplate.Criterion.OfflinePending>>,
) =>
  pipe(
    riskAnalysisGrids,
    R.reduce(Ord.trivial)(N.MonoidSum.empty, (total, { length }) => N.MonoidSum.concat(total, length)),
  );

export const countPendingRiskAnalysisTotal = (
  riskAnalysisGrids: Record<RiskAnalysisGridTemplate.Code, Array<RiskAnalysisGridTemplate.Criterion.OfflinePending>>,
) =>
  pipe(
    riskAnalysisGrids,
    R.reduce(Ord.trivial)(N.MonoidSum.empty, (total, criteria) =>
      N.MonoidSum.concat(
        total,
        pipe(
          criteria,
          A.reduce(N.MonoidSum.empty, (subTotal, { coefficient, value }) =>
            N.MonoidSum.concat(
              subTotal,
              value === RiskAnalysisGridTemplate.Criterion.OfflineValue.Yes ? coefficient : N.MonoidSum.empty,
            ),
          ),
        ),
      ),
    ),
  );

export const isRiskAnalysisComplete = (riskAnalysis: OfflinePendingRiskAnalysis | null | undefined) =>
  riskAnalysis == null || riskAnalysis.state === OfflinePendingSimpleState.Finished;
