import { EnumLabels } from '@shared/model/enum';
import * as NEA from 'fp-ts/NonEmptyArray';
import {
  AppendixAttachedFile,
  CheckpointLinkType,
  CheckScope,
  DataDocumentCompleted,
  GridCheckDataDocumentSettings,
  GridMetaDisplay,
  InspectionMethod,
  NonComplianceActionType,
  NonComplianceGravity,
  NonComplianceType,
  ParcelDataLine,
  ParcelTemplate,
  PendingSyncBooleanMeta,
  PendingSyncDateMeta,
  PendingSyncNumericMeta,
  PendingSyncTextMeta,
  Signature,
  SyncIncomingNonCompliance,
} from '../../../sync/model';
import {
  InspectionCheckProcedure,
  InspectionCheckSpecification,
  InspectionProduction,
} from '../../../online/inspections/model';
import { RiskAnalysisGridTemplate } from '../../risk-analysis/model';
import { CoverPage } from '../../cover-page/model';
import { NonComplianceReferential } from '../../../referential/model';

export type PendingInspectionMap = Record<string, OfflinePendingInspection>;

export interface OfflinePendingState {
  inspections: PendingInspectionMap;
}

export const defaultOfflinePendingState: OfflinePendingState = {
  inspections: {},
};

export interface OfflinePendingInspection {
  id: string;
  operatorEmail: string | null | false;
  grids: Array<OfflinePendingGrid>;
  unmatchedChecks: OfflinePendingUnmatchedChecks | null;
  nonCompliances: Array<OfflinePendingNonCompliance>;
  parcels: OfflinePendingParcels;
  isUnannounced: boolean;
  riskAnalysis: OfflinePendingRiskAnalysis | null;
  coverPage: CoverPage.Pending | null;
  cci: NEA.NonEmptyArray<string> | null;
}

export enum GridType {
  Standard = 'standard',
  Survey = 'survey',
}

export interface OfflinePendingCommonGrid {
  id: string;
  state: OfflineGridState;
  checkIds: Array<string>;
  name: string;
  title: string;
  reference: string | null;
  checkScopes: Array<string>;
  checkScopesComment: string | null;
  sections: Array<OfflinePendingSection>;
  metaSections: Array<OfflinePendingMetaSection>;
  attachedFiles: Array<AppendixAttachedFile>;
  conclusion: OfflinePendingGridConclusion;
  documents: Array<OfflinePendingGridCheckDocument>;
  gridType?: GridType;
  largeLabels?: boolean;
}

export interface OfflineStandardPendingGrid extends OfflinePendingCommonGrid {
  gridType: GridType.Standard;
}

export interface OfflineSurveyPendingGrid extends OfflinePendingCommonGrid {
  gridType: GridType.Survey;
}

export type OfflinePendingGrid = OfflineStandardPendingGrid | OfflineSurveyPendingGrid;

export interface OfflinePendingSection {
  id: string;
  name: string;
  rows: Array<OfflinePendingSectionRow>;
}

export interface OfflinePendingSectionSimpleRow {
  id: string;
  type: 'SIMPLE';
  checkpoint: OfflinePendingCheckPoint;
}

export enum OfflinePendingSectionMergedType {
  Checkpoint = 'checkpoint',
  Aggregate = 'aggregate',
}

export interface OfflinePendingSectionMergedAggregate {
  type: OfflinePendingSectionMergedType.Aggregate;
  aggregates: Array<Omit<OfflinePendingSectionAggregatedRow, 'name'>>;
}

export interface OfflinePendingSectionMergedCheckpoint {
  type: OfflinePendingSectionMergedType.Checkpoint;
  checkpoints: Array<OfflinePendingCheckPoint>;
}

export interface OfflinePendingSectionMergedRow {
  id: string;
  type: 'MERGED';
  name: string;
  items: OfflinePendingSectionMergedAggregate | OfflinePendingSectionMergedCheckpoint;
  // for v1 to v2 migrations
  checkpoints?: Array<OfflinePendingCheckPoint>;
}

export interface OfflinePendingSectionAggregatedRow {
  id: string;
  type: 'AGGREGATED';
  name: string;
  checkpoints: Array<OfflinePendingCheckPoint>;
}

export type OfflinePendingSectionRow =
  | OfflinePendingSectionSimpleRow
  | OfflinePendingSectionMergedRow
  | OfflinePendingSectionAggregatedRow;

export interface OfflinePendingCheckPoint {
  id: string;
  code: string;
  name: string;
  procedure: InspectionCheckProcedure;
  specification: InspectionCheckSpecification;
  checkScopes: Array<CheckScope>;
  inspectionMethods: Array<InspectionMethod>;
  description: string | null;
  targetValue: string;
  link: OfflinePendingLink | null;
  value: OfflineCheckpointValue | null;
  comment: string | null;
  nonCompliances?: Array<NonComplianceReferential>;
  inspectionReferenceText?: string | null;
}

export enum OfflineCheckpointValue {
  Inapplicable = 'NA',
  Compliant = 'CF',
  Failure = 'MQT',
  Filtered = 'F',
}

export interface OfflinePendingLink {
  type: CheckpointLinkType;
  description: string | null;
  targetValue: string;
}

export interface OfflinePendingMetaSection {
  id: string;
  name: string;
  display: GridMetaDisplay;
  metas: Array<OfflinePendingMeta>;
}

export interface OfflinePendingNumericMeta extends Omit<PendingSyncNumericMeta, 'value' | 'section'> {
  id: string;
  value: number | null;
  isCommentable: boolean;
}

export interface OfflinePendingTextMeta extends Omit<PendingSyncTextMeta, 'value' | 'section'> {
  id: string;
  value: string | null;
  isCommentable: boolean;
}

export interface OfflinePendingBooleanMeta extends Omit<PendingSyncBooleanMeta, 'value' | 'section'> {
  id: string;
  value: true | null;
  isCommentable: boolean;
}

export interface OfflinePendingDateMeta extends Omit<PendingSyncDateMeta, 'value' | 'section'> {
  id: string;
  value: string | null;
  isCommentable: boolean;
}

export type OfflinePendingMeta =
  | OfflinePendingNumericMeta
  | OfflinePendingTextMeta
  | OfflinePendingBooleanMeta
  | OfflinePendingDateMeta;

export type OfflineUnmatchedChecksState = Exclude<
  OfflineGridState,
  OfflineGridState.Pending | OfflineGridState.ReadyToSign
>;

export interface OfflinePendingUnmatchedChecks {
  state: OfflineUnmatchedChecksState;
  checkIds: Array<string>;
}

export enum OfflineGridState {
  NotStarted = 'no-started',
  Pending = 'pending',
  ReadyToSign = 'ready-to-sign',
  Finished = 'finished',
}

export const offlineGridStateLabels: EnumLabels<OfflineGridState> = {
  [OfflineGridState.NotStarted]: 'Non démarrée',
  [OfflineGridState.Pending]: 'En cours',
  [OfflineGridState.ReadyToSign]: 'Prêt à signer',
  [OfflineGridState.Finished]: 'Terminée',
};

export interface OfflinePendingNewNonCompliance {
  type: 'new';
  id: string;
  gridId: string;
  checkIds: Array<string>;
  codeId: string | null;
  recordType: NonComplianceType | null;
  gravity: NonComplianceGravity | null;
  description: string | null;
  implementationDate: string | null;
  impact: string | null;
  correctiveAction: string | null;
  comment: string | null;
  requirement: string | null;
}

export interface OfflinePendingExistingNonCompliance extends Omit<SyncIncomingNonCompliance, 'type'> {
  code: string;
  type: 'existing';
  id: string;
  action: NonComplianceActionType | null;
  complianceType: NonComplianceType;
  note: string | null;
}

export type OfflinePendingNonCompliance = OfflinePendingNewNonCompliance | OfflinePendingExistingNonCompliance;

export interface OfflinePendingGridConclusion {
  startTime: string | null;
  endTime: string | null;
  comment: string | null;
  metPeople: string | null;
  accompanyingPeople: string | null;
  auditorSignature: Signature;
  operatorSignature: Signature;
  operatorEmail: string | null;
  cci: NEA.NonEmptyArray<string> | null;
}

export enum OfflinePendingSimpleState {
  NotStarted = 'no-started',
  Pending = 'pending',
  Finished = 'finished',
}

export const offlineSimpleStateLabel: EnumLabels<OfflinePendingSimpleState> = {
  [OfflinePendingSimpleState.NotStarted]: 'Non démarrée',
  [OfflinePendingSimpleState.Pending]: 'En cours',
  [OfflinePendingSimpleState.Finished]: 'Terminée',
};

export interface OfflinePendingParcels {
  state: OfflinePendingSimpleState;
  production: InspectionProduction;
  parcels: Array<OfflinePendingParcel>;
}

export interface OfflinePendingParcel {
  id: string;
  template: ParcelTemplate;
  lines: Array<OfflinePendingParcelDataLine>;
  before: Record<string, number> | null;
  beforeTot: number;
  isAnswered: boolean | null;
}

export enum OfflinePendingParcelDataLineState {
  New = 'new',
  Deleted = 'deleted',
  Updated = 'updated',
}

export interface OfflinePendingParcelDataLine extends ParcelDataLine {
  state: OfflinePendingParcelDataLineState | null;
}

export interface OfflinePendingGridCheckFileDocumentFile {
  type: 'file';
  fileId: string;
}

export interface OfflinePendingGridCheckFileDocumentReason {
  type: 'reason';
  reason: string;
}

export type OfflinePendingGridCheckFileDocumentFileOrReason =
  | OfflinePendingGridCheckFileDocumentFile
  | OfflinePendingGridCheckFileDocumentReason;

export interface OfflinePendingGridCheckFileDocument {
  type: 'file';
  id: string;
  label: string;
  fileUrl: string;
  fileName: string;
  fileContentType: string;
  comment: string | null;
  fileOrReason: OfflinePendingGridCheckFileDocumentFileOrReason | null;
}

export interface OfflinePendingGridCheckDataDocument {
  type: 'data';
  id: string;
  label: string;
  settings: GridCheckDataDocumentSettings;
  comment: string | null;
  completedOrReason: DataDocumentCompleted | string | null;
}

export type OfflinePendingGridCheckDocument = OfflinePendingGridCheckFileDocument | OfflinePendingGridCheckDataDocument;

export interface OfflinePendingRiskAnalysis {
  state: OfflinePendingSimpleState;
  riskAnalysisGrids: Record<RiskAnalysisGridTemplate.Code, Array<RiskAnalysisGridTemplate.Criterion.OfflinePending>>;
}

export interface OfflineLocationPathname {
  pathname: string;
  scroll: number;
}
