import { CSSProperties, ReactNode } from 'react';
import isEqual from 'lodash.isequal';
import { StringifiableRecord } from 'query-string';
import * as O from 'fp-ts/Option';

export class Range<T, F extends StringifiableRecord = {}> {
  constructor(
    readonly items: ReadonlyMap<number, T>,
    readonly total: number,
    readonly loading: boolean,
    readonly filter?: F,
    readonly sort?: string | null,
  ) {}

  merge(newRange: Range<T, F>): Range<T, F> {
    if (isEqual(this.filter, newRange.filter)) {
      return new Range<T, F>(
        new Map<number, T>([...Array.from(this.items.entries()), ...Array.from(newRange.items.entries())]),
        newRange.total,
        newRange.loading,
        newRange.filter,
        newRange.sort,
      );
    }

    return newRange;
  }

  has(index: number): boolean {
    return this.items.has(index);
  }

  get(index: number): O.Option<T> {
    return O.fromNullable(this.items.get(index));
  }

  setLoading(loading: boolean = true): Range<T, F> {
    return new Range(this.items, this.total, loading, this.filter);
  }

  toList(): Array<T> {
    return Array.from(this.items.values());
  }

  map<B>(fa: (a: T) => B): Range<B, F> {
    return new Range(
      new Map<number, B>(Array.from(this.items, ([key, value]) => [key, fa(value)])),
      this.total,
      this.loading,
      this.filter,
      this.sort,
    );
  }

  setFilter(newFilter: F): Range<T, F> {
    return new Range(this.items, this.total, this.loading, newFilter, this.sort);
  }

  static fromRangeResult<T, F extends StringifiableRecord = {}>(result: Range.Result<T, F>) {
    return new Range(
      new Map<number, T>(result.items.map((item, i) => [i + result.startIndex, item])),
      result.total,
      false,
      result.filter,
      result.sort,
    );
  }

  static fromArray<T, F extends StringifiableRecord = {}>(
    list: Array<T>,
    filter?: F,
    sort?: string | null,
  ): Range<T, F> {
    return new Range<T, F>(
      new Map<number, T>(list.map((item, i) => [i, item])),
      list.length,
      false,
      filter,
      sort ?? null,
    );
  }

  static default<T, F extends StringifiableRecord = {}>() {
    return new Range<T, F>(new Map<number, T>(), 0, true);
  }
}

export namespace Range {
  export class Cursor {
    static DEFAULT_SIZE = 50;

    constructor(public startIndex: number, public endIndex: number) {}

    static fromPage(page: number) {
      return new Cursor(page * Cursor.DEFAULT_SIZE, (page + 1) * Cursor.DEFAULT_SIZE - 1);
    }

    static initial() {
      return Cursor.fromPage(0);
    }

    static fromIndex(index: number) {
      const startIndex = Math.max(0, index - Cursor.DEFAULT_SIZE / 2);

      return new Cursor(startIndex, startIndex + Cursor.DEFAULT_SIZE - 1);
    }

    toPage() {
      return Math.floor(this.startIndex / Cursor.DEFAULT_SIZE) + 1;
    }
  }

  export interface Result<T, F extends StringifiableRecord = {}> extends Required<Cursor> {
    total: number;
    items: Array<T>;
    filter: F;
    sort: string | null;
  }

  export namespace VirtualizedList {
    export interface ChildrenProps<T> {
      item: T;
      index: number;
      style: CSSProperties;

      ref: (element: Element | null) => void;
    }

    export interface Props<T> {
      range: Range<T>;
      children: (props: ChildrenProps<T>) => ReactNode;
      rowHeight?: number;
      loadMore?: (cursor: Range.Cursor) => Promise<unknown>;
      getItemKey?: (item: T, index: number) => string | number;
      loadingRow?: (style: CSSProperties) => ReactNode;
      scrollAreaId?: string;
      style?: CSSProperties;
    }
  }
}
